import * as React from "react";
import { useParams } from "react-router-dom";
import PageHeader from "../theme/components/common/PageHeader";
import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";
import { gql, useQuery } from "@apollo/client";
import {
  GetMeasurementStationFilesDetails,
  GetMeasurementStationFilesDetailsVariables,
} from "./__generated__/GetMeasurementStationFilesDetails";
import { useAuth } from "../state/auth";
import apiClient from "../api";
import { Button, Spinner } from "react-bootstrap";
import BackToLink from "../components/common/BackToLink";
import { gettext } from "../i18n";

const MAX_UPLOAD_SIZE_MB = 20;
const ONE_KB_IN_BYTES = 1024;
const ONE_MB_IN_BYTES = 1024 * 1024;

// TODO: there's a lot of refactoring to be done -- multiple pages have a query like this and there's a lot of
// boilerplate code in common.
const GET_FILES_DETAILS = gql`
  query GetMeasurementStationFilesDetails($identifier: String) {
    station(where: { identifier: { _eq: $identifier } }) {
      id
      identifier
      name
      organization_id
    }
  }
`;

const formatFileSize = (bytes: number | undefined) => {
  if (!bytes) {
    return "";
  } else if (bytes < ONE_KB_IN_BYTES) {
    return `${bytes} bytes`;
  } else if (bytes < ONE_MB_IN_BYTES) {
    return `${(bytes / ONE_KB_IN_BYTES).toFixed(1)} KB`;
  } else {
    return `${(bytes / ONE_MB_IN_BYTES).toFixed(1)} MB`;
  }
};

interface StationFileProps {
  id: number;
  name: string;
  size: number;
  createdAt: Date;
  uploader: string;
}

const MeasurementStationFilesPage = () => {
  const { identifier } = useParams();
  const { data, error } = useQuery<
    GetMeasurementStationFilesDetails,
    GetMeasurementStationFilesDetailsVariables
  >(GET_FILES_DETAILS, {
    variables: {
      identifier,
    },
  });
  const { user } = useAuth();
  const [files, setFiles] = React.useState<StationFileProps[] | [] | null>(
    null
  );
  const [fileInfo, setFileInfo] = React.useState<File | null>(null);
  const [numOperations, setNumOperations] = React.useState(0);
  const uploadRef = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    apiClient
      .requestRaw(`/files/station/${identifier}`)
      .then((r) => r.json())
      .then((data) => {
        setFiles(data);
      })
      .catch((e) => {
        console.error("Error fetching station files:", e);
      });
  }, [setFiles, identifier, numOperations]);

  if (!data) {
    return <div />;
  }
  if (error) {
    return <pre>{JSON.stringify(error, null, 2)}</pre>;
  }
  if (data?.station.length === 0) {
    return <div>Not found.</div>;
  }

  const uploadFile = () => {
    const input = uploadRef.current;
    if (!input || !input.files || !input.files[0]) {
      alert("Select a file first");
      return;
    }
    const file = input.files[0];
    apiClient
      .request(`/files/station/${identifier}`, {
        method: "POST",
        multipart: true,
        data: { file },
      })
      .then((response) => {
        setFileInfo(null);
        setNumOperations(numOperations + 1);
        input.value = "";
      })
      .catch((e) => {
        console.error("Error uploading file:", e);
        alert("Error uploading file: " + e.message);
      });
  };

  const downloadFile = (file: string) => {
    apiClient
      .requestRaw(`/files/station/${identifier}/${file}`, {
        method: "GET",
      })
      .then((r) => r.blob())
      .then((data) => {
        const url = URL.createObjectURL(data);
        const a = document.createElement("a");
        a.href = url;
        a.download = file;
        a.target = "_blank";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      })
      .catch((e) => {
        console.error("Error downloading file:", e);
        alert("Error downloading file: " + e.message);
      });
  };
  const deleteFile = (filename: string) => {
    apiClient
      .requestRaw(`/files/station/${identifier}/${filename}`, {
        method: "DELETE",
      })
      .then((r) => r.json())
      .then((data) => {
        setNumOperations(numOperations + 1);
      })
      .catch((e) => {
        console.error("Error uploading file:", e);
        alert("Error deleting file: " + e.message);
      });
  };

  const station = data.station[0];
  const hasOrganizationRole = user?.organizationRoles.find(
    (r) => r.organizationId === station.organization_id
  );
  return (
    <div className="MeasurementStationFilesPage">
      <BackToLink to={`/stations/${identifier}`}>
        Back to measurement station.
      </BackToLink>
      <PageHeader title={`${station.name} Files`} className="mb-3" />
      <Card className="mb-3">
        <Card.Body>
          {!files ? (
            <Spinner animation="border" />
          ) : files.length === 0 ? (
            <div>No files uploaded for station.</div>
          ) : (
            <Table>
              <thead>
                <tr>
                  <th>File name</th>
                  <th>Uploader</th>
                  <th>Size</th>
                  <th>Uploaded at</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {files.map((file) => (
                  <tr key={file.name}>
                    <td>
                      <a
                        //href={apiClient.getApiUrl(`/files/station/${identifier}/${filename}`)}
                        href={`#${file.name}`}
                        onClick={(e) => {
                          e.preventDefault();
                          downloadFile(file.name);
                        }}
                      >
                        {file.name}
                      </a>
                    </td>
                    <td>{file.uploader}</td>
                    <td>{formatFileSize(file.size)}</td>
                    <td>{file.createdAt}</td>
                    <td>
                      {(user?.admin || hasOrganizationRole) && (
                        <Button
                          size="sm"
                          variant="danger"
                          onClick={() => {
                            if (
                              window.confirm(
                                `${gettext("Really delete")} ${file.name}?`
                              )
                            ) {
                              // eslint-disable-line
                              deleteFile(file.name);
                            }
                          }}
                        >
                          Delete
                        </Button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </Card.Body>
      </Card>
      {(user?.admin || hasOrganizationRole) && (
        <Card className="mb-3">
          <Card.Body>
            <h4>Upload a file</h4>
            <p>Maximum file size: {MAX_UPLOAD_SIZE_MB} MB</p>
            <div className="my-3">
              <input
                type="file"
                name="file"
                ref={uploadRef}
                onChange={(event) => {
                  if (event.target?.files) {
                    setFileInfo(event.target.files[0]);
                  }
                }}
              />
              {fileInfo &&
                (fileInfo?.size < MAX_UPLOAD_SIZE_MB * ONE_MB_IN_BYTES ? (
                  <p>File size: {formatFileSize(fileInfo?.size)}</p>
                ) : (
                  <p style={{ color: "red" }}>
                    File size: {formatFileSize(fileInfo?.size)}
                  </p>
                ))}
            </div>
            <div>
              <Button
                disabled={
                  !fileInfo ||
                  fileInfo.size > MAX_UPLOAD_SIZE_MB * ONE_MB_IN_BYTES
                }
                onClick={uploadFile}
              >
                Upload
              </Button>
            </div>
          </Card.Body>
        </Card>
      )}
    </div>
  );
};
export default MeasurementStationFilesPage;
