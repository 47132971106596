import messagesEn from "./locales/en.json";
import messagesFi from "./locales/fi.json";
import messagesSv from "./locales/sv.json";
import messagesXx from "./locales/xx.json";
import messagesRu from "./locales/ru.json";
import { refreshReactComponents } from "./utils/refresh";

const gettextJs = require("gettext.js").default;

export type Locale = "en" | "fi" | "sv" | "xx" | "ru";

const LOCALE_STORAGE_KEY = "mg-locale";
export const VALID_LOCALES: Locale[] = [
  "fi",
  "en",
  "sv",
  "ru",
  // "xx", // Uncomment for debugging
];

const MESSAGES_BY_LOCALE = {
  en: messagesEn,
  fi: messagesFi,
  sv: messagesSv,
  xx: messagesXx,
  ru: messagesRu,
};

// Map supported locales to language files
export const LOCALE_LANGUAGE_FILES: Record<string, Locale> = {
  en_GB: "en",
  en_US: "en",
  fi_FI: "fi",
  sv_SE: "sv",
  ru: "ru",
};

export const LOCALES_BY_FILE: Record<string, string> = {
  en: "en_GB",
  fi: "fi_FI",
  sv: "sv_SE",
  ru: "ru",
};

// It has to be sessionStorage so that it only affects the current tab
let initialLocale: Locale = window.sessionStorage.getItem(
  LOCALE_STORAGE_KEY
) as Locale;
if (VALID_LOCALES.indexOf(initialLocale) === -1) {
  initialLocale = "en";
}

const i18n = gettextJs({
  locale: initialLocale,
});

for (const locale of VALID_LOCALES) {
  const domain = "messages";
  const pluralForms = "nplurals=2; plural=n>1;";
  i18n.setMessages(domain, locale, MESSAGES_BY_LOCALE[locale], pluralForms);
}

export function gettext(msgid: string, ...args: any): string {
  return i18n.gettext(msgid, ...args);
}

export function pgettext(context: string, msgid: string): string {
  return i18n.pgettext(context, msgid);
}
// Other gettext functions from gettext.js could be exposed too

export const getLocaleNames = (): Record<string, string> => {
  return {
    en: pgettext("language", "English"),
    fi: pgettext("language", "Finnish"),
    sv: pgettext("language", "Swedish"),
    ru: pgettext("language", "Russian"),
  };
};

export const getLocaleName = (locale: string): string => {
  const localeNames = getLocaleNames();
  const fileName = LOCALE_LANGUAGE_FILES[locale] ?? "en";
  return localeNames[fileName];
};

export const getLocaleDropdownOptions = (): Record<string, string> => {
  const localeNames = getLocaleNames();
  return Object.entries(LOCALES_BY_FILE).reduce(
    (acc: Record<string, string>, locale: [string, string]) => {
      acc[locale[1]] = localeNames[locale[0]];
      return acc;
    },
    {}
  );
};

export function normalizeLocale(locale: string): Locale {
  let normalizedLocale = locale.toLowerCase();
  let matchedLocale = LOCALE_LANGUAGE_FILES[locale];
  if (matchedLocale === undefined) {
    normalizedLocale = normalizedLocale.split("_")[0];
    if (LOCALES_BY_FILE[normalizedLocale] !== undefined) {
      matchedLocale = LOCALES_BY_FILE[normalizedLocale] as Locale;
    }
  }
  return matchedLocale ?? "en";
}

export function setLocale(
  locale: Locale | string,
  refreshOnLocaleChange = true
) {
  const previousLocale = getLocale();
  locale = normalizeLocale(locale);
  window.sessionStorage.setItem(LOCALE_STORAGE_KEY, locale);
  i18n.setLocale(locale);

  // Just refresh page on locale changes
  if (refreshOnLocaleChange && previousLocale !== locale) {
    //window.location.reload();
    refreshReactComponents();
  }
}

// TODO: helper function for debugging
(window as any).__setLocale = setLocale;

export function getLocale(): Locale {
  return i18n.getLocale() as Locale;
}

// babel-plugin-transform-jsx-i18n is configured to expect the "gettext" function in scope, so we
// add it to the global scope here.
export const init = () => {
  (window as any).gettext = gettext;
};
