import React from "react";
import { toast } from "react-toastify";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import { gettext } from "i18n";
import { copyToClipBoard } from "theme/helpers/utils";
import { useModalContext } from "state/modal";

import "./AddAuthTokenForm.scss";

interface AddAuthTokenFormProps {
  show: boolean;
  newToken: string;
  setNewToken: React.Dispatch<React.SetStateAction<string>>;
  onAddToken: (description: string) => void;
}

const AddAuthTokenForm: React.FC<AddAuthTokenFormProps> = ({
  show,
  newToken,
  setNewToken,
  onAddToken,
}: AddAuthTokenFormProps) => {
  const { setActiveModal } = useModalContext();
  const onHide = () => {
    setActiveModal(null);
    setNewToken("");
  };
  const tokenInputRef = React.useRef(null);
  const [validated, setValidated] = React.useState<boolean>(false);
  const [description, setDescription] = React.useState<string>("");

  const handleSubmit = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    // Show any potential input errors
    setValidated(true);

    // If the form is valid, submit
    const form = event.currentTarget;

    if (form.checkValidity()) {
      onAddToken(description);
      setValidated(false);
    }
  };

  const handleCopyText = () => {
    copyToClipBoard(tokenInputRef);
    toast.success(gettext("Value copied to clipboard"), {
      theme: "colored",
    });
  };

  return (
    <Modal
      animation
      show={show}
      onHide={() => {
        setValidated(false);
        onHide();
      }}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Add new authentication token
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form validated={validated} onSubmit={handleSubmit}>
          <Table>
            <tbody>
              {!!newToken && (
                <tr>
                  <td>
                    <p>
                      <strong>WARNING:</strong> This token is only visible once.
                      If you lose it, you will need to generate a new one.
                    </p>
                    <Form.Group>
                      <Form.Label>Token</Form.Label>
                      <div className="tokenRow">
                        <Form.Control
                          ref={tokenInputRef}
                          type="text"
                          placeholder="Token"
                          value={newToken}
                          readOnly
                        />
                        <Button size="sm" onClick={handleCopyText}>
                          Copy
                        </Button>
                      </div>
                    </Form.Group>
                  </td>
                </tr>
              )}
              {!newToken && (
                <tr>
                  <td>
                    <Form.Group>
                      <Form.Label>Description</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Description"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                      />
                    </Form.Group>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>

          <div className="actionButtons">
            <Button variant="light" onClick={onHide}>
              Close
            </Button>
            {!newToken && <Button type="submit">Save</Button>}
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default AddAuthTokenForm;
