import { useEffect, useState } from 'react';

const useToggleStylesheet = (isRTL, isDark, configDispatch) => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    setIsLoaded(false);
    // This doesn't really work when we are using creat-react-app for styles,
    // so let's just overwrite it
    //Array.from(document.getElementsByClassName('theme-stylesheet')).forEach(
    //  link => link.remove()
    //);
    //const link = document.createElement('link');
    //link.href = `${process.env.PUBLIC_URL}/css/theme${
    //  isRTL ? '-rtl' : ''
    //}.min.css`;
    //link.type = 'text/css';
    //link.rel = 'stylesheet';
    //link.className = 'theme-stylesheet';
    //link.onload = () => {
    //  setIsLoaded(true);
    //};
    //document.getElementsByTagName('head')[0].appendChild(link);

    setTimeout(() => {
      setIsLoaded(true);
    })

    document
      .getElementsByTagName('html')[0]
      .setAttribute('dir', isRTL ? 'rtl' : 'ltr');
  }, [isRTL]);

  useEffect(() => {
    document.documentElement.classList[isDark ? 'add' : 'remove']('dark');
    configDispatch({
      type: 'RESET'
    });
  }, [isDark, configDispatch]);

  return { isLoaded };
};

export default useToggleStylesheet;
