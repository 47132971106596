import * as React from "react";
import { AuthProvider } from "./auth";
import { ModalProvider } from "./modal";

const AppStateProvider: React.FC = ({ children }) => {
  return (
    <AuthProvider>
      <ModalProvider>{children}</ModalProvider>
    </AuthProvider>
  );
};
export default AppStateProvider;
