import * as React from "react";
import dayjs from "dayjs";
import { Link, useParams } from "react-router-dom";
import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import { gql, useQuery } from "@apollo/client";
import { Spinner } from "react-bootstrap";
import PageHeader from "theme/components/common/PageHeader";
import {
  GetStationAuditLog,
  GetStationAuditLogVariables,
  GetStationAuditLog_station_audit_log,
} from "./__generated__/GetStationAuditLog";
import {
  GetStationAuditLogCount,
  GetStationAuditLogCountVariables,
} from "./__generated__/GetStationAuditLogCount";
import { getActionName } from "../utils/audit";
import { gettext } from "../i18n";
import "./StationAuditPage.scss";
import classNames from "classnames";

const PAGINATION_RECORDS_PER_PAGE_DEFAULT = 5;
const PAGINATION_RECORDS_PER_PAGE_EXPANDED = 10;
const PAGINATION_VISIBLE_PAGES = 3;

const GET_STATION_AUDIT_LOG = gql`
  query GetStationAuditLog($stationId: String, $limit: Int, $offset: Int) {
    station_audit_log(
      where: { station: { identifier: { _eq: $stationId } } }
      limit: $limit
      offset: $offset
    ) {
      id
      transaction_id
      table_name
      action_tstamp_stm
      action_tstamp_clk
      action
      row_data
      changed_fields
      station {
        identifier
        name
      }
    }
  }
`;

const GET_STATION_AUDIT_LOG_COUNT = gql`
  query GetStationAuditLogCount($stationId: String) {
    station_audit_log_aggregate(
      where: { station: { identifier: { _eq: $stationId } } }
    ) {
      aggregate {
        count
      }
    }
  }
`;

const formatLogValue = (value: string | number | boolean) => {
  let formattedValue = value || <b>ø</b>;
  if (value === true) {
    formattedValue = "true";
  }
  if (value === false) {
    formattedValue = "false";
  }
  if (typeof value === "object") {
    formattedValue = JSON.stringify(value);
  }
  return formattedValue;
};

interface ChangeTableProps {
  logRecord: GetStationAuditLog_station_audit_log;
}
const ChangeTable: React.FC<ChangeTableProps> = ({
  logRecord,
}: ChangeTableProps) => {
  const { row_data: previousRecord, changed_fields: changedRecord } = logRecord;

  const hasChangedFields =
    changedRecord && Object.keys(changedRecord).length > 0;
  return (
    <table className={classNames("table", "auditChangesTable")}>
      <thead>
        <tr>
          <th>Field</th>
          {hasChangedFields && <th>Old Value</th>}
          <th>{hasChangedFields ? "New Value" : "Value"}</th>
        </tr>
      </thead>
      <tbody>
        {Object.keys(previousRecord)
          .filter((key) => !["id"].includes(key))
          .sort((a, b) => a.localeCompare(b))
          .map((key) => {
            let oldValue = previousRecord[key];
            let value = oldValue;
            if (changedRecord?.[key] !== undefined) {
              value = changedRecord[key];
            }
            return (
              <tr key={`recordKey_${logRecord.id}_${key}`}>
                <td className="changedValue">{key}</td>
                {hasChangedFields && (
                  <td
                    className={classNames("changedValue", {
                      previous: value !== undefined && oldValue !== value,
                    })}
                  >
                    {formatLogValue(oldValue)}
                  </td>
                )}
                <td
                  className={classNames("changedValue", {
                    next: value !== undefined && oldValue !== value,
                  })}
                >
                  {formatLogValue(value)}
                </td>
              </tr>
            );
          })}
      </tbody>
    </table>
  );
};

interface PaginationBlockProps {
  totalPages: number;
  currentPage: number;
  setCurrentPage: (page: number) => void;
  ignoreActive?: boolean;
  visiblePages?: number;
}
const PaginationBlock: React.FC<PaginationBlockProps> = ({
  setCurrentPage,
  currentPage,
  totalPages,
  ignoreActive = false,
  visiblePages = PAGINATION_VISIBLE_PAGES,
}: PaginationBlockProps) => {
  let currentPagination: number[] = [];
  const visiblePagesHalf = Math.floor(visiblePages / 2);
  for (let i = -visiblePagesHalf; i <= visiblePagesHalf; i++) {
    const page = currentPage + i;
    if (page < 1 || page > totalPages) {
      continue;
    }
    currentPagination.push(page);
  }

  return (
    <>
      {currentPagination.map((number) => (
        <li key={`paginator_page_${number}`} className="page-item">
          <div
            onClick={() => setCurrentPage(number)}
            className={classNames("page-link", {
              "active-page-button": currentPage === number && !ignoreActive,
            })}
          >
            {number}
          </div>
        </li>
      ))}
    </>
  );
};

interface PaginationProps {
  recordsPerPage: number;
  setCurrentPage: (page: number) => void;
  currentPage: number;
  setRecordsPerPage: (v: number) => void;
  logRecords: GetStationAuditLog_station_audit_log[];
  totalPages: number;
}
const Pagination: React.FC<PaginationProps> = ({
  recordsPerPage,
  setCurrentPage,
  currentPage,
  setRecordsPerPage,
  logRecords,
  totalPages,
}: PaginationProps) => {
  if (
    (currentPage === 1 &&
      recordsPerPage !== PAGINATION_RECORDS_PER_PAGE_EXPANDED) ||
    !logRecords
  ) {
    return (
      <Button
        onClick={() => {
          setRecordsPerPage(PAGINATION_RECORDS_PER_PAGE_EXPANDED);
        }}
        className="show-more"
      >
        Show more
      </Button>
    );
  }

  return (
    <nav>
      <ul className="pagination">
        {currentPage > 2 && (
          <>
            <PaginationBlock
              ignoreActive
              setCurrentPage={setCurrentPage}
              currentPage={1}
              totalPages={totalPages}
              visiblePages={1}
            />
            {currentPage > 3 && (
              <span className="pagination-skipped-pages">...</span>
            )}
          </>
        )}
        <PaginationBlock
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          totalPages={totalPages}
        />
        {totalPages >= PAGINATION_VISIBLE_PAGES &&
          currentPage <
            totalPages - Math.floor(PAGINATION_VISIBLE_PAGES / 2) && (
            <>
              {currentPage + 1 < totalPages - 1 && (
                <span className="pagination-skipped-pages">...</span>
              )}
              <PaginationBlock
                ignoreActive
                setCurrentPage={setCurrentPage}
                currentPage={totalPages}
                totalPages={totalPages}
                visiblePages={1}
              />
            </>
          )}
      </ul>
    </nav>
  );
};

interface ExpandedLogRecordProps {
  logRecord: GetStationAuditLog_station_audit_log;
  visible: boolean;
}
const ExpandedLogRecord = ({ logRecord, visible }: ExpandedLogRecordProps) => {
  // Not needed for preloaded records, but will be if logRecord details
  // are loaded asynchronously one by one in the future
  if (!logRecord) {
    return (
      <tr>
        <td colSpan={4}>
          <Spinner
            style={{
              position: "relative",
              left: "50%",
            }}
            animation="border"
            size="sm"
          />
        </td>
      </tr>
    );
  }

  return (
    <tr>
      <td colSpan={4} className={classNames("expandedLogRecord")}>
        <div
          className={classNames("expandedLogContent", {
            visible,
          })}
        >
          <Table>
            <tbody>
              <tr>
                <td colSpan={4} className="wrapperCell">
                  <ChangeTable logRecord={logRecord} />
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </td>
    </tr>
  );
};

const StationAuditPage = () => {
  const { identifier: stationId } = useParams();
  const [currentPage, setCurrentPage] = React.useState(1);
  const [stationName, setStationName] = React.useState<string | null>(null);
  const [recordsPerPage, setRecordsPerPage] = React.useState<number>(
    PAGINATION_RECORDS_PER_PAGE_DEFAULT
  );

  const indexOfLastRecord = currentPage * recordsPerPage;
  const queryOffset = indexOfLastRecord - recordsPerPage;
  const { data: stationLogData, refetch: stationLogDataRefetch } = useQuery<
    GetStationAuditLog,
    GetStationAuditLogVariables
  >(GET_STATION_AUDIT_LOG, {
    variables: { stationId, limit: recordsPerPage, offset: queryOffset },
  });
  const { data: stationLogCount, refetch: stationLogCountRefetch } = useQuery<
    GetStationAuditLogCount,
    GetStationAuditLogCountVariables
  >(GET_STATION_AUDIT_LOG_COUNT, {
    variables: { stationId },
  });

  // Refetch upon every mount as any component could have created new audit records
  React.useEffect(() => {
    setStationName(null);
    stationLogDataRefetch();
    stationLogCountRefetch();
  }, [stationLogDataRefetch, stationLogCountRefetch]);

  // Refetch when the page size changes
  React.useEffect(() => {
    stationLogDataRefetch();
  }, [recordsPerPage, stationLogDataRefetch]);

  const logRecords: GetStationAuditLog_station_audit_log[] =
    stationLogData?.station_audit_log || [];
  const totalRecords =
    stationLogCount?.station_audit_log_aggregate?.aggregate?.count || 0;

  const [expandedRowId, setExpandedRowId] = React.useState<number>(0);
  const collapseExpandedRow = () => {
    setExpandedRowId(0);
  };

  React.useEffect(() => {
    const name = stationLogData?.station_audit_log?.[0]?.station?.name;
    if (!stationName && name) {
      setStationName(name);
    }
  }, [stationLogData, stationName]);

  const totalPages = Math.ceil(totalRecords / recordsPerPage);
  if (stationLogData === undefined) {
    return (
      <Spinner
        style={{
          position: "relative",
          left: "50%",
        }}
        animation="border"
        size="sm"
      />
    );
  }

  if (!stationName) {
    return (
      <div className="StationAuditPage">
        <PageHeader
          title={gettext("Audit Log")}
          description={gettext(
            "Log of database changes performed by all users for the selected station"
          )}
          className="mb-3"
        ></PageHeader>
        <Card className="mb-3">
          <Card.Body>No records available</Card.Body>
        </Card>
      </div>
    );
  }

  return (
    <div className="StationAuditPage">
      <PageHeader
        title={
          <React.Fragment>
            {gettext("Audit Log")}: {stationName}
          </React.Fragment>
        }
        description={gettext(
          "Log of database changes performed by all users for the selected station"
        )}
        className="mb-3"
      >
        <Button
          as={Link as any}
          to={`/stations/${stationId}`}
          variant="light"
          size="sm"
          className="me-2"
        >
          Back
        </Button>
      </PageHeader>
      <Card className="mb-3">
        <Card.Body>
          <Table>
            <thead>
              <tr>
                <th>User</th>
                <th>Action</th>
                <th>Table</th>
                <th>Time</th>
              </tr>
            </thead>
            <tbody>
              {!logRecords.length && (
                <tr>
                  <td colSpan={4}>
                    <Spinner
                      style={{
                        position: "relative",
                        left: "50%",
                      }}
                      animation="border"
                      size="sm"
                    />
                  </td>
                </tr>
              )}
              {logRecords?.map(
                (logRow: GetStationAuditLog_station_audit_log) => (
                  <React.Fragment key={`transactionId_row_${logRow.id}`}>
                    <tr
                      className="activeRow"
                      onClick={() => {
                        expandedRowId === logRow.id
                          ? collapseExpandedRow()
                          : setExpandedRowId(logRow.id);
                      }}
                    >
                      <td>N/A</td>
                      <td className="capitalize">
                        {getActionName(logRow?.action || "")}
                      </td>
                      <td>{logRow.table_name}</td>
                      <td>
                        {dayjs(logRow.action_tstamp_clk).format(
                          "DD.MM.YYYY HH:mm"
                        )}
                      </td>
                    </tr>
                    <ExpandedLogRecord
                      logRecord={logRow}
                      visible={expandedRowId === logRow.id}
                    />
                  </React.Fragment>
                )
              )}

              <tr>
                <td colSpan={4} className="pagination-row">
                  <div className="mt-4">
                    <Pagination
                      recordsPerPage={recordsPerPage}
                      setCurrentPage={setCurrentPage}
                      currentPage={currentPage}
                      setRecordsPerPage={setRecordsPerPage}
                      logRecords={logRecords}
                      totalPages={totalPages}
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </div>
  );
};

export default StationAuditPage;
