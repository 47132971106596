import * as React from 'react';
import {useSubscription, useQuery} from '@apollo/client';
import {DocumentNode} from 'graphql';
import {TypedDocumentNode} from '@graphql-typed-document-node/core';
import {SubscriptionHookOptions, SubscriptionResult, QueryHookOptions, QueryResult} from '@apollo/client/react/types/types';
import {OperationVariables} from '@apollo/client/core';

/**
 * A subscription that returns old data when new data is loading (for example, if variables change)
 * @param subscription: The subscription object, as in useSubscription from @apollo/client
 * @param options Optional options, as in useSubscription from @apollo/client
 */
export function useCachedSubscription<TData = any, TVariables = OperationVariables>(
  subscription: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: SubscriptionHookOptions<TData, TVariables>
): SubscriptionResult<TData, any> {
  const { data: loadedData, ...rest } = useSubscription(subscription, options);

  const [data, setData] = React.useState<TData|undefined>(undefined);

  React.useEffect(() => {
    if (loadedData) {
      setData(loadedData);
    }
  }, [loadedData, setData]);

  return {
    data,
    ...rest,
  }
}

/**
 * A query that polls for new data with a given interval (or default), but only if the tab is active
 * @param query: The query object, as in useQuery from @apollo/client
 * @param options Optional options, as in useQuery from @apollo/client
 */
export function usePolledQuery<TData = any, TVariables = OperationVariables>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: QueryHookOptions<TData, TVariables>
): QueryResult<TData, any> {
  const pollInterval = options?.pollInterval ?? 60 * 1000;
  const { data, startPolling, stopPolling, ...rest} = useQuery(query, {
    ...options,
    pollInterval,
  });

  React.useEffect(() => {
    const listener = () => {
      if (document.hidden) {
        stopPolling();
      } else {
        startPolling(pollInterval);
      }
    }

    document.addEventListener('visibilitychange', listener)

    return () => {
      document.removeEventListener('visibilitychange', listener)
    }
  }, [startPolling, stopPolling, pollInterval]);

  return {
    data,
    startPolling,
    stopPolling,
    ...rest,
  }
}
