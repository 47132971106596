/**
 * Constants and utilities related to audit records
 */

import { gettext } from "../i18n";

const ACTION_NAMES: Record<string, string> = {
  U: "Updated",
  I: "Inserted",
  D: "Deleted",
};

export const getActionName = (action: string) =>
  gettext(ACTION_NAMES[action] || "Unknown");
