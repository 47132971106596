/**
 * Array related queries and utilities
 */

export const insertIntoList = (
  list: any,
  index: any,
  insertItem: any,
  replace: boolean = false
) => [
  ...list.slice(0, index),
  insertItem,
  ...list.slice(index + (replace ? 1 : 0)),
];
