export const MATH_FUNCTIONS = [
  [
    "exp",
    "<dl><dt><a name=\"-exp\"><strong>exp</strong></a>(x, /)</dt><dd><tt>Return&nbsp;e&nbsp;raised&nbsp;to&nbsp;the&nbsp;power&nbsp;of&nbsp;x.</tt></dd></dl>"
  ],
  [
    "log",
    "<dl><dt><a name=\"-log\"><strong>log</strong></a>(...)</dt><dd><tt>log(x,&nbsp;[base=math.e])<br>\nReturn&nbsp;the&nbsp;logarithm&nbsp;of&nbsp;x&nbsp;to&nbsp;the&nbsp;given&nbsp;base.<br>\n&nbsp;<br>\nIf&nbsp;the&nbsp;base&nbsp;not&nbsp;specified,&nbsp;returns&nbsp;the&nbsp;natural&nbsp;logarithm&nbsp;(base&nbsp;e)&nbsp;of&nbsp;x.</tt></dd></dl>"
  ],
  [
    "log10",
    "<dl><dt><a name=\"-log10\"><strong>log10</strong></a>(x, /)</dt><dd><tt>Return&nbsp;the&nbsp;base&nbsp;10&nbsp;logarithm&nbsp;of&nbsp;x.</tt></dd></dl>"
  ],
  [
    "degrees",
    "<dl><dt><a name=\"-degrees\"><strong>degrees</strong></a>(x, /)</dt><dd><tt>Convert&nbsp;angle&nbsp;x&nbsp;from&nbsp;radians&nbsp;to&nbsp;degrees.</tt></dd></dl>"
  ],
  [
    "radians",
    "<dl><dt><a name=\"-radians\"><strong>radians</strong></a>(x, /)</dt><dd><tt>Convert&nbsp;angle&nbsp;x&nbsp;from&nbsp;degrees&nbsp;to&nbsp;radians.</tt></dd></dl>"
  ],
  [
    "sqrt",
    "<dl><dt><a name=\"-sqrt\"><strong>sqrt</strong></a>(x, /)</dt><dd><tt>Return&nbsp;the&nbsp;square&nbsp;root&nbsp;of&nbsp;x.</tt></dd></dl>"
  ],
  [
    "pow",
    "<dl><dt><a name=\"-pow\"><strong>pow</strong></a>(x, y, /)</dt><dd><tt>Return&nbsp;x**y&nbsp;(x&nbsp;to&nbsp;the&nbsp;power&nbsp;of&nbsp;y).</tt></dd></dl>"
  ],
  [
    "acos",
    "<dl><dt><a name=\"-acos\"><strong>acos</strong></a>(x, /)</dt><dd><tt>Return&nbsp;the&nbsp;arc&nbsp;cosine&nbsp;(measured&nbsp;in&nbsp;radians)&nbsp;of&nbsp;x.<br>\n&nbsp;<br>\nThe&nbsp;result&nbsp;is&nbsp;between&nbsp;0&nbsp;and&nbsp;pi.</tt></dd></dl>"
  ],
  [
    "asin",
    "<dl><dt><a name=\"-asin\"><strong>asin</strong></a>(x, /)</dt><dd><tt>Return&nbsp;the&nbsp;arc&nbsp;sine&nbsp;(measured&nbsp;in&nbsp;radians)&nbsp;of&nbsp;x.<br>\n&nbsp;<br>\nThe&nbsp;result&nbsp;is&nbsp;between&nbsp;-pi/2&nbsp;and&nbsp;pi/2.</tt></dd></dl>"
  ],
  [
    "atan",
    "<dl><dt><a name=\"-atan\"><strong>atan</strong></a>(x, /)</dt><dd><tt>Return&nbsp;the&nbsp;arc&nbsp;tangent&nbsp;(measured&nbsp;in&nbsp;radians)&nbsp;of&nbsp;x.<br>\n&nbsp;<br>\nThe&nbsp;result&nbsp;is&nbsp;between&nbsp;-pi/2&nbsp;and&nbsp;pi/2.</tt></dd></dl>"
  ],
  [
    "atan2",
    "<dl><dt><a name=\"-atan2\"><strong>atan2</strong></a>(y, x, /)</dt><dd><tt>Return&nbsp;the&nbsp;arc&nbsp;tangent&nbsp;(measured&nbsp;in&nbsp;radians)&nbsp;of&nbsp;y/x.<br>\n&nbsp;<br>\nUnlike&nbsp;atan(y/x),&nbsp;the&nbsp;signs&nbsp;of&nbsp;both&nbsp;x&nbsp;and&nbsp;y&nbsp;are&nbsp;considered.</tt></dd></dl>"
  ],
  [
    "cos",
    "<dl><dt><a name=\"-cos\"><strong>cos</strong></a>(x, /)</dt><dd><tt>Return&nbsp;the&nbsp;cosine&nbsp;of&nbsp;x&nbsp;(measured&nbsp;in&nbsp;radians).</tt></dd></dl>"
  ],
  [
    "dist",
    "<dl><dt><a name=\"-dist\"><strong>dist</strong></a>(p, q, /)</dt><dd><tt>Return&nbsp;the&nbsp;Euclidean&nbsp;distance&nbsp;between&nbsp;two&nbsp;points&nbsp;p&nbsp;and&nbsp;q.<br>\n&nbsp;<br>\nThe&nbsp;points&nbsp;should&nbsp;be&nbsp;specified&nbsp;as&nbsp;sequences&nbsp;(or&nbsp;iterables)&nbsp;of<br>\ncoordinates.&nbsp;&nbsp;Both&nbsp;inputs&nbsp;must&nbsp;have&nbsp;the&nbsp;same&nbsp;dimension.<br>\n&nbsp;<br>\nRoughly&nbsp;equivalent&nbsp;to:<br>\n&nbsp;&nbsp;&nbsp;&nbsp;sqrt(sum((px&nbsp;-&nbsp;qx)&nbsp;**&nbsp;2.0&nbsp;for&nbsp;px,&nbsp;qx&nbsp;in&nbsp;zip(p,&nbsp;q)))</tt></dd></dl>"
  ],
  [
    "hypot",
    "<dl><dt><a name=\"-hypot\"><strong>hypot</strong></a>(...)</dt><dd><tt>hypot(*coordinates)&nbsp;-&gt;&nbsp;value<br>\n&nbsp;<br>\nMultidimensional&nbsp;Euclidean&nbsp;distance&nbsp;from&nbsp;the&nbsp;origin&nbsp;to&nbsp;a&nbsp;point.<br>\n&nbsp;<br>\nRoughly&nbsp;equivalent&nbsp;to:<br>\n&nbsp;&nbsp;&nbsp;&nbsp;sqrt(sum(x**2&nbsp;for&nbsp;x&nbsp;in&nbsp;coordinates))<br>\n&nbsp;<br>\nFor&nbsp;a&nbsp;two&nbsp;dimensional&nbsp;point&nbsp;(x,&nbsp;y),&nbsp;gives&nbsp;the&nbsp;hypotenuse<br>\nusing&nbsp;the&nbsp;Pythagorean&nbsp;theorem:&nbsp;&nbsp;sqrt(x*x&nbsp;+&nbsp;y*y).<br>\n&nbsp;<br>\nFor&nbsp;example,&nbsp;the&nbsp;hypotenuse&nbsp;of&nbsp;a&nbsp;3/4/5&nbsp;right&nbsp;triangle&nbsp;is:<br>\n&nbsp;<br>\n&nbsp;&nbsp;&nbsp;&nbsp;&gt;&gt;&gt;&nbsp;hypot(3.0,&nbsp;4.0)<br>\n&nbsp;&nbsp;&nbsp;&nbsp;5.0</tt></dd></dl>"
  ],
  [
    "sin",
    "<dl><dt><a name=\"-sin\"><strong>sin</strong></a>(x, /)</dt><dd><tt>Return&nbsp;the&nbsp;sine&nbsp;of&nbsp;x&nbsp;(measured&nbsp;in&nbsp;radians).</tt></dd></dl>"
  ],
  [
    "tan",
    "<dl><dt><a name=\"-tan\"><strong>tan</strong></a>(x, /)</dt><dd><tt>Return&nbsp;the&nbsp;tangent&nbsp;of&nbsp;x&nbsp;(measured&nbsp;in&nbsp;radians).</tt></dd></dl>"
  ],
  [
    "acosh",
    "<dl><dt><a name=\"-acosh\"><strong>acosh</strong></a>(x, /)</dt><dd><tt>Return&nbsp;the&nbsp;inverse&nbsp;hyperbolic&nbsp;cosine&nbsp;of&nbsp;x.</tt></dd></dl>"
  ],
  [
    "asinh",
    "<dl><dt><a name=\"-asinh\"><strong>asinh</strong></a>(x, /)</dt><dd><tt>Return&nbsp;the&nbsp;inverse&nbsp;hyperbolic&nbsp;sine&nbsp;of&nbsp;x.</tt></dd></dl>"
  ],
  [
    "atanh",
    "<dl><dt><a name=\"-atanh\"><strong>atanh</strong></a>(x, /)</dt><dd><tt>Return&nbsp;the&nbsp;inverse&nbsp;hyperbolic&nbsp;tangent&nbsp;of&nbsp;x.</tt></dd></dl>"
  ],
  [
    "cosh",
    "<dl><dt><a name=\"-cosh\"><strong>cosh</strong></a>(x, /)</dt><dd><tt>Return&nbsp;the&nbsp;hyperbolic&nbsp;cosine&nbsp;of&nbsp;x.</tt></dd></dl>"
  ],
  [
    "sinh",
    "<dl><dt><a name=\"-sinh\"><strong>sinh</strong></a>(x, /)</dt><dd><tt>Return&nbsp;the&nbsp;hyperbolic&nbsp;sine&nbsp;of&nbsp;x.</tt></dd></dl>"
  ],
  [
    "tanh",
    "<dl><dt><a name=\"-tanh\"><strong>tanh</strong></a>(x, /)</dt><dd><tt>Return&nbsp;the&nbsp;hyperbolic&nbsp;tangent&nbsp;of&nbsp;x.</tt></dd></dl>"
  ]
];
