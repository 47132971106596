import React from 'react';
import {Card, Col, Row} from 'react-bootstrap';
import Logo from 'theme/components/common/Logo';
import Section from 'theme/components/common/Section';
import {Navigate, Outlet, useLocation} from 'react-router-dom';
import {useAuth} from "../../state/auth";

const AuthSimpleLayout = () => {
  const location = useLocation();
  const {loggedIn} = useAuth();
  return loggedIn ? (
    <Navigate to="/" state={{from: location}} replace/>
  ) : (
    <Section className="py-0">
      <Row className="flex-center min-vh-100 py-6">
        <Col sm={10} md={8} lg={6} xl={5} className="col-xxl-4">
          <Logo/>
          <Card>
            <Card.Body className="p-4 p-sm-5">
              <Outlet/>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Section>
  )
};

export default AuthSimpleLayout;
