import { gql, useApolloClient, useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import PageHeader from "../theme/components/common/PageHeader";
import * as React from "react";
import Table from "react-bootstrap/Table";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  GetMeasurementStationConversions,
  GetMeasurementStationConversionsVariables,
  GetMeasurementStationConversions_channel as Channel,
} from "./__generated__/GetMeasurementStationConversions";
import { GetUnits_unit } from "../utils/__generated__/GetUnits";
import apiClient from "../api";
import { toast } from "react-toastify";
import Button from "react-bootstrap/Button";
import BackToLink from "../components/common/BackToLink";
import FormattedFormControl from "../components/form/FormattedFormControl";
import { useSortedUnits } from "../hooks/units";
import { gettext } from "../i18n";
import "./ConversionTablePage.scss";
import classNames from "classnames";

const GET_CONVERSIONS = gql`
  query GetMeasurementStationConversions($identifier: String) {
    channel(where: { station: { identifier: { _eq: $identifier } } }) {
      id
      identifier
      name
      system_name
      unit {
        id
        name
        symbol
      }
      conversion {
        id
        type
        raw_max
        raw_min
        engineering_max
        engineering_min
        offset
      }
    }
  }
`;

const ConversionTablePage = () => {
  const { identifier } = useParams();
  const { data, error } = useQuery<
    GetMeasurementStationConversions,
    GetMeasurementStationConversionsVariables
  >(GET_CONVERSIONS, {
    variables: {
      identifier,
    },
  });
  const { data: sortedUnits, refetch: refetchUnits } = useSortedUnits();
  const [edits, setEdits] = React.useState<Record<number, Record<string, any>>>(
    {}
  );
  const apolloClient = useApolloClient();

  if (!data) {
    return <div />;
  }
  if (error) {
    return <pre>{JSON.stringify(error, null, 2)}</pre>;
  }
  if (data?.channel.length === 0) {
    return <div>Not found.</div>;
  }

  const channels = data.channel;
  const editValue = (channelId: number, key: string, value: any) => {
    setEdits((prev) => {
      const newEdits = { ...prev };
      if (!newEdits[channelId]) {
        newEdits[channelId] = {};
      }
      newEdits[channelId][key] = value;
      return newEdits;
    });
  };
  const channelsWithEdits = channels.map((c) => {
    const edit = edits[c.id];
    if (!edit) {
      return c;
    }
    const editedChannel: any = {
      ...c,
      conversion: { ...c.conversion },
    };
    for (let [key, value] of Object.entries(edit)) {
      let [namespace, realKey] = key.split(".");
      if (realKey) {
        (editedChannel as any)[namespace][realKey] = value;
      } else {
        (editedChannel as any)[namespace] = value;
      }
    }
    return editedChannel;
  });

  const onSave = (e: any) => {
    e.preventDefault();
    if (!edits) {
      return;
    }

    const data = edits;
    apiClient
      .request("/admin/edit-channels", {
        method: "POST",
        data,
      })
      .then(() => {
        apolloClient.resetStore().catch((e) => {
          console.error("reseting apollo store failed, refreshing", e);
          window.location.reload();
        });
        toast.success("Data saved successfully", { theme: "colored" });
        refetchUnits();
      })
      .catch((e) => {
        console.error(e);
        toast.error(`Error saving data: ${e.message}`, { theme: "colored" });
      });
  };

  return (
    <div className="ConversionTablePage">
      <BackToLink to={`/stations/${identifier}`}>
        Back to measurement station.
      </BackToLink>
      <PageHeader
        title={gettext(`Conversion table`)}
        className="mb-3"
      ></PageHeader>
      <Card className="mb-3">
        <Card.Body>
          <ConversionTable
            channels={channelsWithEdits}
            editValue={editValue}
            units={sortedUnits}
          />
          <Button type="button" onClick={onSave}>
            Save
          </Button>
        </Card.Body>
      </Card>
    </div>
  );
};

interface ConversionTableProps {
  channels: Channel[];
  editValue: (channelId: number, key: string, value: any) => void;
  units: GetUnits_unit[];
}

const ConversionTable = ({
  channels,
  editValue,
  units,
}: ConversionTableProps) => {
  const [visibleChannels, setVisibleChannels] = React.useState<number[]>([]);
  const sortedChannels = [...channels];
  sortedChannels.sort((a, b) => (a.id < b.id ? -1 : 1));
  return (
    <Table>
      <thead>
        <tr>
          <th>Channel</th>
          <th>Name</th>
          <th>System Name</th>
          <th>Unit</th>
          <th>Conversion</th>
          <th>Raw Min.</th>
          <th>Raw Max.</th>
          <th>Eng. Min.</th>
          <th>Eng. Max.</th>
          <th>Offset</th>
        </tr>
      </thead>
      <tbody>
        {sortedChannels.map((channel) => (
          <tr
            key={channel.id}
            className={classNames("channelLine", {
              hideChannel: !visibleChannels.includes(channel.id),
            })}
          >
            <td
              data-label="Channel"
              onClick={() =>
                visibleChannels.includes(channel.id)
                  ? setVisibleChannels(
                      visibleChannels.filter((c) => c !== channel.id)
                    )
                  : setVisibleChannels([...visibleChannels, channel.id])
              }
            >
              <div className="channelId">
                {channel.identifier}
                <FontAwesomeIcon
                  icon={
                    visibleChannels.includes(channel.id)
                      ? "chevron-up"
                      : "chevron-down"
                  }
                  className="me-1"
                />
              </div>
            </td>

            <td data-label="Name">
              <FormControl
                value={channel.name}
                onChange={(e) => {
                  editValue(channel.id, "name", e.target.value);
                }}
              />
            </td>
            <td data-label="System Name">
              <FormattedFormControl
                type="nullableString"
                value={channel.system_name}
                onChange={(e) => {
                  editValue(channel.id, "system_name", e.target.value);
                }}
              />
            </td>
            <td data-label="Unit">
              <Form.Select
                required
                aria-label="Select a unit for the conversion"
                onChange={(e) => {
                  const unitId: number = Number(e.target.value);
                  editValue(channel.id, "unit_id", unitId);
                }}
                value={(channel as any).unit_id ?? channel.unit?.id}
              >
                <option value="">(unassigned)</option>
                {units.map((unit: GetUnits_unit) => (
                  <option key={`unit_${unit.id}`} value={unit.id}>
                    {unit.symbol || gettext("(no unit)")}
                  </option>
                ))}
              </Form.Select>
            </td>
            <td data-label="Conversion">
              <Form.Select
                value={channel.conversion?.type || "none"}
                onChange={(e) => {
                  editValue(channel.id, "conversion.type", e.target.value);
                }}
              >
                <option>none</option>
                <option>raw_value_to_engineering_units</option>
                <option>little_endian_float</option>
              </Form.Select>
            </td>
            <td data-label="Raw Min.">
              <FormattedFormControl
                type="number"
                value={channel.conversion?.raw_min}
                onChange={(e) => {
                  editValue(channel.id, "conversion.raw_min", e.target.value);
                }}
              />
            </td>
            <td data-label="Raw Max.">
              <FormattedFormControl
                type="number"
                value={channel.conversion?.raw_max}
                onChange={(e) => {
                  editValue(channel.id, "conversion.raw_max", e.target.value);
                }}
              />
            </td>
            <td data-label="Eng. Min.">
              <FormattedFormControl
                type="number"
                value={channel.conversion?.engineering_min}
                onChange={(e) => {
                  editValue(
                    channel.id,
                    "conversion.engineering_min",
                    e.target.value
                  );
                }}
              />
            </td>
            <td data-label="Eng. Max.">
              <FormattedFormControl
                type="number"
                value={channel.conversion?.engineering_max}
                onChange={(e) => {
                  editValue(
                    channel.id,
                    "conversion.engineering_max",
                    e.target.value
                  );
                }}
              />
            </td>
            <td data-label="Offset">
              <FormattedFormControl
                type="number"
                value={channel.conversion?.offset}
                onChange={(e) => {
                  editValue(channel.id, "conversion.offset", e.target.value);
                }}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};
export default ConversionTablePage;
