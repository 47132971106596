import { read as readXLSX } from "xlsx";
import * as PapaParse from "papaparse";

/**
 * Utilities related to import of CSV & XLSX files
 */

export type UploadedDatapoint = [string, string, string, string, string];

export const getXSXSheetData = (
  sheet: any,
  columns: string[] = ["A", "B", "C", "D", "E"]
): UploadedDatapoint[] => {
  const datapoints: UploadedDatapoint[] = [];

  let row = 1;
  let timestamp;
  do {
    timestamp = sheet[columns[2] + row];
    if (timestamp === undefined || timestamp.t !== "n") {
      row++;
      continue;
    }

    const station = sheet[columns[0] + row];
    const dataSet = sheet[columns[1] + row];
    const value = sheet[columns[3] + row];
    const unit = sheet[columns[4] + row];

    if (value === undefined) {
      row++;
      continue;
    }

    const datapoint: UploadedDatapoint = [
      station.v,
      dataSet.v,
      timestamp.w,
      value.v,
      unit?.v,
    ];
    datapoints.push(datapoint);
    row++;
  } while (timestamp !== undefined);
  return datapoints;
};

const onFileError = (e: any) => {
  // TODO: improve error handling
  console.error(e);
};

export const onInputFileChange =
  (
    onFileLoaded: ((data: UploadedDatapoint[]) => void) | null,
    onFileContentsAsString: undefined | ((data: string) => void) = undefined
  ) =>
  (e: React.ChangeEvent<HTMLInputElement>) => {
    let reader: FileReader = new FileReader();
    const files: FileList = e.target.files!;
    const parserOptions: PapaParse.ParseConfig = {} as PapaParse.ParseConfig;
    const fileEncoding = "UTF-8";

    if (files.length > 0) {
      const file = files[0];
      const isXLSX = file.type.includes("officedocument");

      const parseXLSX = async () => {
        const fileData = await files[0].arrayBuffer();
        const workbook = readXLSX(fileData);
        const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
        const data = getXSXSheetData(firstSheet);
        onFileLoaded?.(data);
      };

      reader.onload = (_event: Event) => {
        if (onFileContentsAsString) {
          onFileContentsAsString(reader.result as string);
        }
        if (!onFileLoaded) {
          return;
        }
        const csvData = PapaParse.parse(
          reader.result as string,
          Object.assign(parserOptions, {
            error: onFileError,
            encoding: fileEncoding,
          })
        );
        onFileLoaded?.(csvData?.data ?? []);
      };

      if (isXLSX) {
        parseXLSX();
      } else {
        reader.readAsText(files[0], fileEncoding);
      }
    }
  };
