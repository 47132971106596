import { gql } from "@apollo/client";

import { GetMeasurementStation_station_primary_datasource_alerts } from "./__generated__/GetMeasurementStation";
import { GetMeasurementStations_station } from "./__generated__/GetMeasurementStations";
import { alertFragment } from "../pages/__generated__/alertFragment";
import { useQuery } from "@apollo/client";
import { GetTriggeredAlertsQuery } from "utils/__generated__/GetTriggeredAlertsQuery";
import { GetTriggeredStationAlertsQuery } from "utils/__generated__/GetTriggeredStationAlertsQuery";

export const ALERT_LEVEL_NONE = 0;
export const ALERT_LEVEL_YELLOW = 1;
export const ALERT_LEVEL_RED = 2;

export const STATION_ORIGIN_OMGOS = "omgos";
export const STATION_ORIGIN_FMI = "fmi";
export const STATION_ORIGIN_SNOWER = "snower";

export const STATION_VISIBILITY_PRIVATE = "private";
export const STATION_VISIBILITY_PUBLIC = "public";

export const useAlertStatus = ({
  alerts,
  startDate,
  endDate,
}: {
  alerts:
    | alertFragment[]
    | GetMeasurementStation_station_primary_datasource_alerts[];
  startDate?: Date;
  endDate?: Date;
}) => {
  const currentTimestamp = new Date();
  const yesterdayTimestamp = new Date(
    currentTimestamp.getTime() - 86400 * 1000
  );
  const filterStartDate = startDate ?? yesterdayTimestamp;
  const filterEndDate = endDate ?? currentTimestamp;
  const activeAlertCount = alerts.filter((alert) => alert.active).length;

  const { data: triggeredAlertsData } = useQuery<GetTriggeredAlertsQuery>(
    GET_TRIGGERED_ALERTS,
    {
      skip: !alerts.length,
      variables: {
        sourceUUID: alerts[0]?.source_uuid,
        minTimestamp: filterStartDate,
        maxTimestamp: filterEndDate,
      },
    }
  );

  return {
    activeAlertCount,
    isAlertTriggered: !!triggeredAlertsData?.alert_history?.length,
  };
};

export const useStationAlertLevels = ({
  stationIds,
  startDate,
  endDate,
}: {
  stationIds: number[];
  startDate?: Date;
  endDate?: Date;
}) => {
  const currentTimestamp = new Date();
  const yesterdayTimestamp = new Date(
    currentTimestamp.getTime() - 86400 * 1000
  );
  const filterStartDate = startDate ?? yesterdayTimestamp;
  const filterEndDate = endDate ?? currentTimestamp;

  const { data: triggeredStationAlertsData } = useQuery<GetTriggeredStationAlertsQuery>(
    GET_TRIGGERED_STATION_ALERTS,
    {
      skip: !stationIds.length,
      variables: {
        stationIds: stationIds,
        minTimestamp: filterStartDate,
        maxTimestamp: filterEndDate,
      },
    }
  );

  const alertLevelsPerStationId: Record<string, number> = {};
  triggeredStationAlertsData?.alert_history.forEach((alert) => {
    if (!alert?.alert?.station_id) {
      return;
    }
    alertLevelsPerStationId[alert.alert.station_id] = ALERT_LEVEL_RED;
  });
  return alertLevelsPerStationId;
};

/**
 * Shared station queries
 */

export const GET_MANAGED_STATION_LIST = gql`
  query GetManagedStationList($userId: Int!) {
    station(
      where: {
        organization: {
          organization_users: {
            role: { _eq: "admin" }
            user_id: { _eq: $userId }
          }
        }
      }
    ) {
      id
      identifier
      name
    }
  }
`;

export const GET_STATION_LIST = gql`
  query GetStationList {
    station {
      id
      identifier
      name
    }
  }
`;

export const GET_MEASUREMENT_STATION_QUERY = gql`
  query GetMeasurementStationQuery($identifier: String, $visible: [Boolean!]!) {
    station(where: { identifier: { _eq: $identifier } }) {
      id
      identifier
      name
      lat
      lon
      organization_id
      map_layer
      fmi_station_networks {
        fmi_network {
          name
          weather_enabled
          code
        }
      }
      station_widgets {
        station_id
        base_source_uuid
        axis_scale_manual
        axis_scale_min
        axis_scale_max
        station_widget_sources {
          source_uuid
        }
      }
      datasources(where: { visible: { _in: $visible } }) {
        type
        source_id
        source_uuid
        unit {
          id
          name
          symbol
        }
        name
        visible
        order
        widget_size
        datasource_annotation {
          id
          timestamp
          value
          text
          color
        }
      }
    }
  }
`;

export const GET_TRIGGERED_ALERTS = gql`
  query GetTriggeredAlertsQuery(
    $sourceUUID: uuid!
    $minTimestamp: timestamptz!
    $maxTimestamp: timestamptz!
  ) {
    alert_history(
      where: {
        alert: { source_uuid: { _eq: $sourceUUID } }
        timestamp: { _gte: $minTimestamp, _lte: $maxTimestamp }
      }
    ) {
      id
      timestamp
      exceeding_value
    }
  }
`;

export const GET_TRIGGERED_STATION_ALERTS = gql`
query GetTriggeredStationAlertsQuery($stationIds: [Int!]!, $minTimestamp: timestamptz! $maxTimestamp: timestamptz!) {
  alert_history(where: {alert: {station_id: {_in: $stationIds}}, timestamp: {_gte: $minTimestamp, _lte: $maxTimestamp}}) {
    id
    timestamp
    exceeding_value
    alert {
      station_id
    }
  }
}
`;

export const GET_MEASUREMENT_STATION_META = gql`
  query GetMeasurementStationMetaQuery($identifier: String) {
    station(where: { identifier: { _eq: $identifier } }) {
      id
      identifier
      name
      lat
      lon
      organization_id
      fmi_station_networks {
        fmi_network {
          name
          weather_enabled
          code
        }
      }
      primary_datasource {
        name
        type
        source_id
        source_uuid
        unit {
          symbol
        }
        alerts {
          id
          source_uuid
          lower_limit
          upper_limit
          channel_id
          formula_id
          active
          level
          alert_frequency_minutes
          alert_timeframe_minutes
        }
      }
    }
  }
`;

export const GET_MEASUREMENT_STATION_META_BY_ID = gql`
  query GetMeasurementStationMetaByIdQuery($id: Int!) {
    station(where: { id: { _eq: $id } }) {
      id
      identifier
      name
      lat
      lon
      organization_id
    }
  }
`;

export const GET_MEASUREMENT_STATION_MEAN = gql`
  query GetMeasurementStationMeanQuery($identifier: String) {
    station(where: { identifier: { _eq: $identifier } }) {
      id
      mean_24h {
        value
        latest_value
        timestamp
      }
    }
  }
`;

export const GET_MEASUREMENT_STATION_SUBSCRIPTION = gql`
  subscription GetMeasurementStation(
    $identifier: String
    $visible: [Boolean!]!
  ) {
    station(where: { identifier: { _eq: $identifier } }) {
      id
      identifier
      name
      lat
      lon
      organization_id
      map_layer
      origin
      visibility
      fmi_station_networks {
        fmi_network {
          name
          weather_enabled
          code
        }
      }
      primary_datasource {
        name
        type
        source_id
        source_uuid
        unit {
          symbol
        }
        alerts {
          id
          source_uuid
          lower_limit
          upper_limit
          channel_id
          formula_id
          active
          level
          alert_frequency_minutes
          alert_timeframe_minutes
        }
      }
      station_widgets {
        station_id
        base_source_uuid
        axis_scale_manual
        axis_scale_min
        axis_scale_max
        station_widget_sources {
          source_uuid
        }
      }
      datasources(where: { visible: { _in: $visible } }) {
        type
        source_id
        source_uuid
        unit {
          id
          name
          symbol
        }
        name
        visible
        order
        widget_size
        datasource_annotation {
          id
          timestamp
          value
          text
          color
        }
      }
    }
  }
`;

export const GET_MEASUREMENT_STATIONS = gql`
  query GetMeasurementStations {
    station(order_by: { identifier: asc }) {
      id
      identifier
      name
      lon
      lat
      status
      origin
      visibility
      organization {
        name
      }
      fmi_station_networks {
        fmi_network {
          name
          weather_enabled
          code
        }
      }
      last_data_received {
        station_id
        received_at
      }
      primary_datasource {
        name
        type
        source_id
        source_uuid
        unit {
          symbol
        }
        alerts {
          id
          source_uuid
          lower_limit
          upper_limit
          channel_id
          formula_id
          active
          level
          alert_frequency_minutes
          alert_timeframe_minutes
        }
      }
    }
  }
`;

export const GET_DATASOURCE = gql`
  subscription GetDatasource($type: String!, $sourceId: Int!) {
    source: get_datasource(args: { type: $type, id: $sourceId }) {
      source_uuid
      source_id
      unit {
        id
        name
        symbol
      }
      name
      system_name
      type
      order
      visible
      widget_size
      datasource_annotation {
        id
        text
        color
      }
      alerts {
        id
        source_uuid
        lower_limit
        upper_limit
        channel_id
        formula_id
        active
        level
        alert_frequency_minutes
        alert_timeframe_minutes
      }
    }
  }
`;

export const GET_DATASOURCES_BY_STATION = gql`
  query GetDatasourcesByStation($stationId: Int!) {
    datasource(where: { station_id: { _eq: $stationId } }) {
      source_id
      type
      name
      source_uuid
      unit {
        symbol
      }
    }
  }
`;

export const GET_DATASOURCES_BY_UUID = gql`
  subscription GetDatasourcesByUUID($uuids: _uuid!) {
    source: get_datasources_by_uuid(args: { uuids: $uuids }) {
      source_uuid
      source_id
      unit {
        id
        name
        symbol
      }
      station {
        id
        name
        identifier
      }
      name
      system_name
      type
      order
      visible
      widget_size
      datasource_annotation {
        id
        text
        color
        timestamp
        value
      }
      alerts {
        id
        source_uuid
        lower_limit
        upper_limit
        channel_id
        formula_id
        active
        level
        alert_frequency_minutes
        alert_timeframe_minutes
      }
    }
  }
`;

export const GET_DATASOURCE_NAMES = gql`
  query GetDatasourceNames($sourceUUID: uuid) {
    datasource(where: { source_uuid: { _eq: $sourceUUID } }) {
      source_id
      name
      system_name
      type
      unit {
        symbol
      }
      station {
        name
      }
    }
  }
`;

export const GET_DATASOURCE_DATA = gql`
  query GetDatasourceData(
    $type: String!
    $sourceId: Int!
    $startDate: timestamptz!
    $endDate: timestamptz!
  ) {
    source: get_datasource(args: { type: $type, id: $sourceId }) {
      source_id
      type
      data(
        args: { start: $startDate, end: $endDate }
        order_by: { timestamp: asc }
      ) {
        timestamp
        value
        invalid
      }
    }
  }
`;

export const GET_DATASOURCE_DATA_BY_UUID = gql`
  query GetDatasourceDataByUUID(
    $sourceUUID: uuid!
    $startDate: timestamptz!
    $endDate: timestamptz!
  ) {
    source: get_datasource_by_uuid(args: { source_uuid: $sourceUUID }) {
      source_id
      source_uuid
      type
      unit {
        id
        name
        symbol
      }
      station {
        name
        identifier
      }
      name
      visible
      order
      widget_size
      datasource_annotation {
        id
        timestamp
        value
        text
        color
      }
      data(
        args: { start: $startDate, end: $endDate }
        order_by: { timestamp: asc }
      ) {
        timestamp
        value
        invalid
      }
    }
  }
`;

export const GET_MANUAL_POINT_SET = gql`
  query GetManualPointSetQuery($sourceUUID: uuid) {
    manual_point_set(where: { source_uuid: { _eq: $sourceUUID } }) {
      datapoints(order_by: { timestamp: desc }) {
        id
        timestamp
        value
      }
    }
  }
`;

export const GET_MANUAL_POINT_SETS = gql`
  query GetManualPointSetsQuery($identifier: String) {
    manual_point_set(
      where: { station: { identifier: { _eq: $identifier } } }
      order_by: { order: asc }
    ) {
      id
      identifier
      name
      system_name
      source_uuid
      unit {
        symbol
        id
        name
      }
      station {
        identifier
      }
      visible
      datapoints(order_by: { timestamp: desc }) {
        id
        timestamp
        value
      }
    }
  }
`;

export const GET_MANUAL_POINT_SETS_COMBINED = gql`
  query GetManualPointSetsCombinedQuery {
    manual_point_set(order_by: { order: asc }) {
      id
      identifier
      name
      system_name
      source_uuid
      unit {
        symbol
        id
        name
      }
      station {
        identifier
      }
      visible
      datapoints(order_by: { timestamp: desc }) {
        id
        timestamp
        value
      }
    }
  }
`;

export const GET_DASHBOARD_STATIONS = gql`
  query GetDashboardStations($slug: String!) {
    organization(where: { slug: { _eq: $slug } }) {
      id
      name
    }
    station(where: { organization: { slug: { _eq: $slug } } }) {
      id
      identifier
      name
      primary_datasource {
        name
        type
        source_id
        source_uuid
        unit {
          symbol
        }
      }
    }
  }
`;

export const GET_ORGANIZATION_DASHBOARDS = gql`
  query GetOrganizationDashboards {
    dashboard {
      id
      name
      organization_id
    }
  }
`;

export const GET_DASHBOARD_STRUCTURE = gql`
  query GetDashboardStructure($id: Int!) {
    dashboard(where: { id: { _eq: $id } }) {
      id
      name
      structure
      organization_id
    }
  }
`;

export const GET_VERTICAL_TEMPERATURE = gql`
  query GetVerticalTemperature(
    $stationId: Int!
    $minTimestamp: timestamptz!
    $maxTimestamp: timestamptz
  ) {
    get_vertical_temperature(
      args: {
        _max_timestamp: $maxTimestamp
        _min_timestamp: $minTimestamp
        _station_id: $stationId
      }
    ) {
      channel_id
      value
      timestamp
      system_name
    }
  }
`;

export const GET_ACCRUED_FORMULA_VALUES = gql`
  query GetAccruedFormulaValues(
    $uuids: _uuid!
    $startDate: timestamptz!
    $endDate: timestamptz!
  ) {
    hasura_accrued_values(
      args: { start: $startDate, end: $endDate, source_uuids: $uuids }
    ) {
      accrual_date
      accrued_total
    }
  }
`;

export const GET_MAP_LAYERS = gql`
  query GetMapLayersQuery {
    map_layer(order_by: { display_name: asc }) {
      attribution
      display_name
      id
      max_zoom
      min_zoom
      system_name
      url
    }
  }
`;

/**
 * Shared methods
 */

export const getSourceUnitSymbol = (source: {
  type: string | null;
  unit?: { symbol: string } | null;
}) => {
  /**
   * Quick front-end hack to transform antiderivative formulas in litres to m³
   */
  if (
    source?.type === "antiderivative_formula" &&
    source?.unit?.symbol === "l"
  ) {
    return "[m³]";
  }
  return source?.unit?.symbol ? `[${source?.unit?.symbol}]` : "";
};

export const sortStationList = (
  stationList: GetMeasurementStations_station[],
  filter: (row: GetMeasurementStations_station) => boolean = () => true
): GetMeasurementStations_station[] => {
  const sortedList = [...stationList.filter(filter)];
  sortedList.sort((a, b) => {
    const x = a.organization?.name.toLowerCase() || "";
    const y = b.organization?.name.toLowerCase() || "";

    const x2 = a.name.toLowerCase();
    const y2 = b.name.toLowerCase();

    return x.localeCompare(y, "fi") || x2.localeCompare(y2, "fi");
  });
  return sortedList;
};
