import * as React from "react";
import DatePicker from "react-datepicker";
import debounce from "lodash/debounce";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import fi from "date-fns/locale/fi";
import "react-datepicker/dist/react-datepicker.css";
import "./DateTimeRangeSelector.scss";
import { usePersistentState } from "hooks/state";
import { toUTC, adjustToLocalTime } from "utils/dates";
import Button from "react-bootstrap/Button";

registerLocale("fi", fi);
setDefaultLocale("fi");

let defaultEndDate = toUTC(new Date());
let defaultStartDate = toUTC(new Date(+defaultEndDate - 7 * 86400000));
defaultEndDate.setHours(0, 0, 0, 0);
defaultStartDate.setHours(0, 0, 0, 0);

export const usePersistentDateTimeRange = (
  cacheKey = "DateTimeRangeSelector",
  range?: [Date, Date]
) =>
  usePersistentState(
    cacheKey,
    range || [defaultStartDate, defaultEndDate],
    (value: [Date, Date]) =>
      value.map((dateStr: string | Date) =>
        typeof dateStr === "string" ? toUTC(new Date(dateStr)) : dateStr
      )
  );

export const usePersistentIntervalUnit = (
  defaultUnit: { index: number; value: number } | null = null,
  cacheKey: string = "IntervalUnitSelector"
) => usePersistentState(cacheKey, defaultUnit);

const delayedRangeUpdate = debounce(
  (setRange: (range: [Date, Date]) => void, newRange: [Date, Date]) => {
    setRange(newRange);
  },
  1500
);

export interface DateRangeSelectorProps {
  range: [Date, Date];
  setRange: (range: [Date, Date]) => void;
  quickChoices?: [string, number | Date][];
  cacheKey?: string;
  disableMidnightRounding?: boolean;
}
const DateTimeRangeSelector = (props: DateRangeSelectorProps) => {
  const {
    range,
    setRange,
    quickChoices = [],
    cacheKey = "DateTimeRangeSelector",
    disableMidnightRounding = false,
  } = props;
  const [dateRange, setDateRange] = usePersistentDateTimeRange(cacheKey, range);
  const [startDate, endDate] = dateRange;
  const setStartDate = (value: Date | null) => {
    setDateRange([value, endDate]);
  };
  const setEndDate = (value: Date | null) => {
    setDateRange([startDate, value]);
  };

  const [quickSelectIndex, setQuickSelectIndex] = React.useState<
    number | undefined
  >();

  React.useEffect(() => {
    const getActiveQuickChoiceIndex = () => {
      if (!startDate || !endDate) {
        return undefined;
      }

      const microsecondsDiff = endDate!.getTime() - startDate!.getTime();

      let quickSelectIndex: number | undefined;
      let closestChoiceDiff: number | undefined;
      quickChoices?.forEach((choice, index) => {
        if (choice[1] instanceof Date) {
          return;
        }
        const choiceDiff = Math.abs(microsecondsDiff - choice[1]);
        if (closestChoiceDiff === undefined && choiceDiff === 0) {
          quickSelectIndex = index;
          closestChoiceDiff = choiceDiff;
        }
      });

      return quickSelectIndex;
    };

    setQuickSelectIndex(getActiveQuickChoiceIndex());
  }, [endDate, startDate, quickChoices]);

  return (
    <div className="DateTimeRangeSelector">
      <div className="date-inputs">
        <DatePicker
          selected={startDate}
          showTimeSelect
          onChange={(d) => {
            if (!d || !endDate) {
              return;
            }

            d = new Date(Math.min(+d, +endDate));

            setStartDate(d);
            setQuickSelectIndex(undefined);
            delayedRangeUpdate(setRange, [d, endDate]);
          }}
          maxDate={endDate}
          filterTime={(d) => !endDate || d <= endDate}
          dateFormat="dd.MM.yyyy HH:mm"
          className="form-control my-1"
          locale="fi"
        />
        <DatePicker
          selected={endDate}
          showTimeSelect
          onChange={(d) => {
            if (!d || !startDate) {
              return;
            }

            d = new Date(Math.max(+d, +startDate));
            setEndDate(d);
            setQuickSelectIndex(undefined);
            delayedRangeUpdate(setRange, [startDate, d]);
          }}
          minDate={startDate}
          filterTime={(d) => !startDate || d >= startDate}
          dateFormat="dd.MM.yyyy HH:mm"
          className="form-control my-1"
          locale="fi"
        />
      </div>
      {!!quickChoices.length && (
        <div className="quick-choices">
          {quickChoices.map(([text, quickChoice], index) => (
            <Button
              key={text}
              type="button"
              variant="outline-primary"
              size="sm"
              className="my-1 me-2"
              active={
                quickSelectIndex !== undefined && quickSelectIndex === index
              }
              onClick={(e) => {
                e.preventDefault();
                const end = new Date();
                let start;
                if (quickChoice instanceof Date) {
                  start = quickChoice;
                } else {
                  start = new Date(+end - quickChoice);
                }
                // If more than 24 hours, set time to midnight
                if (
                  !disableMidnightRounding &&
                  !(quickChoice instanceof Date) &&
                  quickChoice >= 86400000
                ) {
                  end.setHours(0, 0, 0, 0);
                  start.setHours(0, 0, 0, 0);
                }

                setEndDate(end);
                setStartDate(start);
                setRange([start, end]);
                setQuickSelectIndex(index);
              }}
            >
              {text}
            </Button>
          ))}
        </div>
      )}
    </div>
  );
};

export type IntervalUnit = { index: number; value: number };

export interface IntervalUnitSelectorProps {
  selectedUnit?: IntervalUnit | undefined | null;
  setSelectedUnit: (unit: IntervalUnit) => void;
  quickChoices?: [string, number][];
  cacheKey?: string;
}
export const IntervalUnitSelector = ({
  selectedUnit,
  setSelectedUnit,
  quickChoices = [],
}: IntervalUnitSelectorProps) => {
  return (
    <div className="IntervalUnitSelector">
      {!!quickChoices.length && (
        <div className="quick-choices">
          {quickChoices.map(([text, value], index) => (
            <Button
              key={text}
              type="button"
              variant="outline-primary"
              size="sm"
              className="my-1 me-2"
              active={selectedUnit?.index === index}
              onClick={(e) => {
                e.preventDefault();
                setSelectedUnit({
                  index,
                  value,
                });
              }}
            >
              {text}
            </Button>
          ))}
        </div>
      )}
    </div>
  );
};

export default DateTimeRangeSelector;
