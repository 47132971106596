import * as React from "react";
import apiClient from "api";
import { useApolloClient } from "@apollo/client";
import { GetMeasurementStation_station } from "../utils/__generated__/GetMeasurementStation";
import { setLocale } from "../i18n";

export interface OrganizationRoles {
  organizationId: number;
  role: string;
}

export interface User {
  id: number;
  displayName: string;
  email: string;
  admin?: boolean;
  organizationRoles: OrganizationRoles[];
  locale: string;
}
export interface AuthState {
  loggedIn: boolean;
  user?: User;
}
export const initialAuthState = {
  loggedIn: false,
  user: undefined,
};

interface AuthContextProps {
  auth: AuthState;
  setAuth: (auth: AuthState) => void;
}
const AuthContext = React.createContext<AuthContextProps>({
  auth: initialAuthState,
  setAuth: () => {},
});

export const AuthProvider: React.FC = ({ children }) => {
  const [auth, setAuth] = React.useState<AuthState>(initialAuthState);
  const contextValue = React.useMemo(
    () => ({ auth, setAuth }),
    [auth, setAuth]
  );
  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

// Utils

export const isOrgAdmin = (
  user: User | undefined,
  organization_id: number
): boolean => {
  if (!organization_id) {
    return false;
  }
  return (
    user?.admin ||
    !!user?.organizationRoles.find(
      (org) =>
        org.role === "admin" && organization_id === Number(org.organizationId)
    )
  );
};

export const isStationAdmin = (
  user: User | undefined,
  station?: GetMeasurementStation_station
): boolean => {
  return isOrgAdmin(user, station?.organization_id || 0);
};

export const isStationQualityController = (
  user: User | undefined,
  station: GetMeasurementStation_station
): boolean => {
  return (
    user?.admin ||
    !!user?.organizationRoles.find(
      (org) =>
        ["quality_controller", "admin"].includes(org.role) &&
        station.organization_id === Number(org.organizationId)
    )
  );
};

// Hooks
export const useAuth = (): AuthState => React.useContext(AuthContext).auth;

export const useIsAdmin = (
  station?: GetMeasurementStation_station
): boolean => {
  const { user } = useAuth();
  if (user?.admin) {
    return true;
  }
  return isStationAdmin(user, station);
};
export const useIsQualityController = (
  station: GetMeasurementStation_station
): boolean => {
  const { user } = useAuth();
  return isStationQualityController(user, station);
};

export const useCurrentUser = (refresh: boolean = false) => {
  const { setAuth, auth } = React.useContext(AuthContext);
  return async (): Promise<LoginStatus> => {
    if (auth.loggedIn && !refresh) {
      return {
        success: true,
        user: auth.user,
      };
    }

    let response;
    try {
      response = await apiClient.request("/auth/current-user");
    } catch (e) {
      console.error("Failed to load current user", e);
    }

    if (!response?.user) {
      setAuth({
        loggedIn: false,
      });
      return {
        success: false,
      };
    }

    const locale = response.user.locale;
    const authState: AuthState = {
      loggedIn: true,
      user: response.user,
    };
    setAuth(authState);
    setLocale(locale);
    return {
      success: true,
      user: authState.user,
    };
  };
};

export interface LoginProps {
  email: string;
  password: string;
  remember?: boolean;
}
export interface LoginStatus {
  success: boolean;
  user?: User;
  error?: string;
}
export const useLogin = () => {
  const retrieveCurrentUser = useCurrentUser();
  const apolloClient = useApolloClient();
  return async ({
    email,
    password,
    remember = false,
  }: LoginProps): Promise<LoginStatus> => {
    let response;
    let error;
    try {
      // Retrieve a session cookie used to generate auth JWTs
      response = await apiClient.request("/auth/login-user", {
        method: "POST",
        data: {
          email,
          password,
          persistent: remember,
        },
      });
    } catch (e) {
      error = String(e);
      console.error("#1 Login failed", e);
    }

    if (response?.code !== 1) {
      return {
        success: false,
        error,
      };
    }

    let user;
    try {
      // Use the previously obtained JWT token to retrieve the user
      user = await retrieveCurrentUser();
    } catch (e) {
      error = String(e);
      console.error("Failed to load current user", e);
    }
    if (!user?.success) {
      return {
        success: false,
        error,
      };
    }

    // Clear apollo client so any non-permissions are cleared
    await apolloClient.resetStore();

    return user;
  };
};

export const onLogoutUser = () => {
  apiClient.request("/auth/token/logout", {
    method: "POST",
  });
  window.localStorage.removeItem("authJWT");
};

export const useLogout = () => {
  const apolloClient = useApolloClient();
  const { setAuth } = React.useContext(AuthContext);
  return () => {
    setAuth({ loggedIn: false, user: undefined });
    onLogoutUser();
    apolloClient.resetStore().catch((e) => {
      console.error("reseting apollo store failed, refreshing", e);
      window.location.reload();
    });
  };
};

export interface PasswordRecoveryRequestProps {
  email: string;
}
export const usePasswordRecoveryRequest =
  () =>
  async ({ email }: PasswordRecoveryRequestProps): Promise<LoginStatus> => {
    const response = await apiClient.request("/auth/request-recovery-token", {
      method: "POST",
      data: { email },
    });
    const { success } = response;
    return { success };
  };

export interface PasswordRecoverySubmitProps {
  email: string;
  recoveryToken: string;
  password: string;
  confirmPassword: string;
}
export const usePasswordRecoverySubmit =
  () =>
  async ({
    email,
    recoveryToken,
    password,
    confirmPassword,
  }: PasswordRecoverySubmitProps): Promise<LoginStatus> => {
    const response = await apiClient.request("/auth/submit-recovery-token", {
      method: "POST",
      data: { email, recoveryToken, password, confirmPassword },
    });
    const { success } = response;
    return { success };
  };
