import * as React from "react";
import { toast } from "react-toastify";
import { Button, Card, Form } from "react-bootstrap";
import FormControl from "react-bootstrap/FormControl";
import PageHeader from "theme/components/common/PageHeader";
import Table from "react-bootstrap/Table";
import { gettext, getLocaleDropdownOptions, getLocaleName } from "i18n";
import { useAuth, useCurrentUser } from "state/auth";
import { getFormValueHandler } from "utils/forms";
import apiClient from "../api";

const ProfilePage = () => {
  const { user } = useAuth();
  const retrieveCurrentUser = useCurrentUser(true);
  const [editing, setEditing] = React.useState(false);
  const [validated, setValidated] = React.useState<boolean>(false);
  const [editedValues, setEditedValues] = React.useState<Record<string, any>>(
    {}
  );

  const setValueHandler = getFormValueHandler(editedValues, setEditedValues);

  const onResetForm = () => {
    setValidated(false);
    setEditedValues({});
    setEditing(false);
  };

  const onSave = () => {
    const values = { ...editedValues };
    if (!Object.keys(values).length) {
      toast.success(gettext("No changes made"));
      return;
    }

    apiClient
      .request("/auth/update-profile", {
        method: "POST",
        data: {
          ...values,
        },
      })
      .then(async () => {
        toast.success(gettext("Profile updated successfully"));
        onResetForm();
        retrieveCurrentUser();
      })
      .catch((e: any) => {
        console.error(e);
        toast.error(
          gettext("Failed to update profile!") + " " + gettext(e.toString())
        );
      });
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    // Show any potential input errors
    setValidated(true);

    // If the form is valid, submit
    const form = event.currentTarget;

    if (form.checkValidity()) {
      onSave();
    }
  };

  if (!user) {
    return <div>Not logged in</div>;
  }

  return (
    <div className="ProfilePage">
      <PageHeader title="My profile" className="mb-3" />
      <Card>
        <Card.Body>
          {!editing && (
            <>
              <strong>Name:</strong> {user.displayName}
              <br />
              <strong>Email:</strong> {user.email}
              <br />
              <strong>Language:</strong> {gettext(getLocaleName(user.locale))}
              <br />
              <strong>Password:</strong>{" "}
              &#x2022;&#x2022;&#x2022;&#x2022;&#x2022;&#x2022;&#x2022;&#x2022;&#x2022;&#x2022;
              <div className="actionButtons">
                <Button variant="light" onClick={() => setEditing(true)}>
                  Edit
                </Button>
              </div>
            </>
          )}
          {editing && (
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <Table>
                <tbody>
                  <tr>
                    <th>Email</th>
                    <td>
                      <FormControl
                        type="email"
                        value={editedValues.email ?? user.email ?? ""}
                        placeholder={"user@example.com"}
                        onChange={setValueHandler("email")}
                        required
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>Display Name</th>
                    <td>
                      <FormControl
                        value={
                          editedValues.displayName ?? user.displayName ?? ""
                        }
                        placeholder={gettext("Display Name")}
                        onChange={setValueHandler("displayName")}
                        required
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>Selected language</th>
                    <td>
                      <Form.Select
                        required
                        aria-label="Selected language"
                        onChange={setValueHandler("locale")}
                        value={editedValues?.locale ?? user.locale ?? ""}
                      >
                        <option value="">Select language</option>
                        {Object.entries(getLocaleDropdownOptions()).map(
                          ([key, label]) => (
                            <option key={`role_${key}`} value={key}>
                              {gettext(label)}
                            </option>
                          )
                        )}
                      </Form.Select>
                    </td>
                  </tr>
                  <tr>
                    <th colSpan={2}>
                      <strong>Change Password (optional)</strong>
                    </th>
                  </tr>
                  <tr>
                    <th>Current Password</th>
                    <td>
                      <FormControl
                        type="password"
                        value={editedValues.currentPassword ?? ""}
                        placeholder={gettext("Current Password")}
                        onChange={setValueHandler("currentPassword")}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>New Password</th>
                    <td>
                      <FormControl
                        type="password"
                        value={editedValues.newPassword ?? ""}
                        placeholder={gettext("New Password")}
                        onChange={setValueHandler("newPassword")}
                        required={!!editedValues.currentPassword}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>Confirm New Password</th>
                    <td>
                      <FormControl
                        type="password"
                        value={editedValues.confirmNewPassword ?? ""}
                        placeholder={gettext("Confirm New Password")}
                        onChange={setValueHandler("confirmNewPassword")}
                        required={!!editedValues.currentPassword}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2}>
                      <div className="actionButtons">
                        <Button variant="light" onClick={() => onResetForm()}>
                          Cancel
                        </Button>
                        <Button type="submit">Save</Button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Form>
          )}
        </Card.Body>
      </Card>
    </div>
  );
};
export default ProfilePage;
