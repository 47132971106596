import * as React from "react";
import classNames from "classnames";
import { useNavigate, useParams } from "react-router-dom";
import {
  GetUserOrganizations,
  GetUserOrganizationsVariables,
  GetUserOrganizations_organization_user,
} from "../utils/__generated__/GetUserOrganizations";
import {
  GetOrganizations,
  GetOrganizations_organization,
} from "../utils/__generated__/GetOrganizations";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FormControl from "react-bootstrap/FormControl";
import Table from "react-bootstrap/Table";
import { useQuery } from "@apollo/client";
import PageHeader from "theme/components/common/PageHeader";
import { Button, Card, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import apiClient from "../api";
import {
  USER_ROLE_OPTIONS,
  ORG_ACTION_ADD,
  ORG_ACTION_EDIT,
  ORG_ACTION_REMOVE,
  loadEditableUser,
  EditableUserOption,
  GET_ORGANIZATIONS,
  GET_USER_ORGANIZATIONS,
} from "utils/users";
import { gettext, getLocaleDropdownOptions } from "i18n";
import { getFormValueHandler } from "utils/forms";

import "./UserEditPage.scss";
import { useCurrentUser } from "state/auth";

interface UserOrgMutation {
  action: string;
  organization_id?: number;
  role?: string;
}

interface UserOrganizationsPickerProps {
  editableUser: EditableUserOption;
  orgMutations: UserOrgMutation[];
  setOrgMutations: React.Dispatch<React.SetStateAction<UserOrgMutation[]>>;
  userOrganizations: GetUserOrganizations_organization_user[];
}
const UserOrganizationsPicker: React.FC<UserOrganizationsPickerProps> = ({
  editableUser,
  orgMutations,
  setOrgMutations,
  userOrganizations = [],
}: UserOrganizationsPickerProps) => {
  const { data: orgData } = useQuery<GetOrganizations>(GET_ORGANIZATIONS, {
    variables: {},
  });

  const organizations: GetOrganizations_organization[] =
    orgData?.organization || [];

  const unassignedOrganizations = organizations?.filter(
    (organization: GetOrganizations_organization) =>
      !userOrganizations.find(
        (userOrg) => userOrg.organization.id === organization.id
      ) &&
      !orgMutations.find(
        (mutation: UserOrgMutation) =>
          mutation.organization_id === organization.id && !!mutation.role
      )
  );

  return (
    <Table className="existingOrganizationTable">
      <tbody>
        <tr>
          <th>Name</th>
          <th>Role</th>
          <td></td>
        </tr>
        {userOrganizations?.map(
          (userOrganization: GetUserOrganizations_organization_user) => {
            const removalMutation = orgMutations.find(
              (mutation: UserOrgMutation) =>
                mutation.action === ORG_ACTION_REMOVE &&
                mutation.organization_id === userOrganization.organization.id
            );
            const editMutation = orgMutations.find(
              (mutation: UserOrgMutation) =>
                mutation.action === ORG_ACTION_EDIT &&
                mutation.organization_id === userOrganization.organization.id
            );
            return (
              <tr
                key={`existing_org_${userOrganization.organization.id}`}
                className={classNames({
                  pendingRemoval: !!removalMutation,
                })}
              >
                <td>{userOrganization.organization.name}</td>
                <td>
                  {!editMutation ? (
                    gettext(USER_ROLE_OPTIONS[userOrganization.role])
                  ) : (
                    <Form.Select
                      required
                      value={editMutation?.role || ""}
                      onChange={(e) => {
                        const role = e.target.value;
                        const newMutation: UserOrgMutation = {
                          action: ORG_ACTION_EDIT,
                          organization_id: userOrganization.organization.id,
                          role,
                        };
                        setOrgMutations([
                          ...orgMutations.filter(
                            (mutation: UserOrgMutation) =>
                              mutation !== editMutation
                          ),
                          newMutation,
                        ]);
                      }}
                    >
                      {Object.entries(USER_ROLE_OPTIONS).map(([key, label]) => (
                        <option key={`role_${key}`} value={key}>
                          {gettext(label)}
                        </option>
                      ))}
                    </Form.Select>
                  )}
                </td>
                <td>
                  <div className="tableActionButtons">
                    <>
                      {!removalMutation &&
                        (!!editMutation ? (
                          <Button
                            size="sm"
                            onClick={() => {
                              setOrgMutations([
                                ...orgMutations.filter(
                                  (mutation: UserOrgMutation) =>
                                    mutation !== editMutation
                                ),
                              ]);
                            }}
                          >
                            Cancel
                          </Button>
                        ) : (
                          <Button
                            size="sm"
                            onClick={() => {
                              const newMutation: UserOrgMutation = {
                                action: ORG_ACTION_EDIT,
                                organization_id:
                                  userOrganization.organization.id,
                                role: userOrganization.role,
                              };
                              setOrgMutations([...orgMutations, newMutation]);
                            }}
                          >
                            Edit
                          </Button>
                        ))}
                      <FontAwesomeIcon
                        icon="xmark"
                        onClick={() => {
                          if (removalMutation) {
                            setOrgMutations([
                              ...orgMutations.filter(
                                (mutation: UserOrgMutation) =>
                                  mutation !== removalMutation
                              ),
                            ]);
                          } else {
                            const newMutation: UserOrgMutation = {
                              action: ORG_ACTION_REMOVE,
                              organization_id: userOrganization.organization.id,
                            };
                            setOrgMutations([
                              ...orgMutations.filter(
                                (mutation: UserOrgMutation) =>
                                  !editMutation || mutation !== editMutation
                              ),
                              newMutation,
                            ]);
                          }
                        }}
                      />
                    </>
                  </div>
                </td>
              </tr>
            );
          }
        )}
        {!!organizations.length &&
          orgMutations.map((mutation: UserOrgMutation, idx: number) => {
            const organization = organizations.find(
              (org: GetOrganizations_organization) =>
                org.id === mutation.organization_id
            );
            /**
             * Exclude non-add operations here instead of via .filter
             * to preserve global index of pending mutations
             */
            if (mutation.action !== ORG_ACTION_ADD) {
              return <React.Fragment key={`blank_${idx}`}></React.Fragment>;
            }
            return (
              <tr key={`add_org_${idx}`}>
                <td>
                  <Form.Select
                    required
                    aria-label="Select an organization"
                    onChange={(e) => {
                      const orgId = e.target.value;
                      if (!orgId) {
                        return;
                      }
                      orgMutations[idx].organization_id = Number(orgId);
                      setOrgMutations([...orgMutations]);
                    }}
                    value={organization?.id ?? ""}
                  >
                    <option value="">Select new organization</option>
                    {organizations
                      ?.filter(
                        (organization: GetOrganizations_organization) =>
                          !userOrganizations.find(
                            (userOrg) =>
                              userOrg.organization.id === organization.id
                          )
                      )
                      .map((organization: GetOrganizations_organization) => (
                        <option
                          key={`org_${organization.id}`}
                          value={organization.id}
                        >
                          {organization.name}
                        </option>
                      ))}
                  </Form.Select>
                </td>
                <td>
                  <Form.Select
                    required
                    aria-label="Organization role of the new user"
                    onChange={(e) => {
                      const role = e.target.value;
                      if (!role) {
                        return;
                      }
                      orgMutations[idx].role = role;
                      setOrgMutations([...orgMutations]);
                    }}
                    value={mutation?.role ?? ""}
                  >
                    <option value="">Select a role</option>
                    {Object.entries(USER_ROLE_OPTIONS).map(([key, label]) => (
                      <option key={`role_${key}`} value={key}>
                        {gettext(label)}
                      </option>
                    ))}
                  </Form.Select>
                </td>
                <td>
                  <FontAwesomeIcon
                    icon="xmark"
                    onClick={() => {
                      setOrgMutations([
                        ...orgMutations.filter((_, i) => i !== idx),
                      ]);
                    }}
                  />
                </td>
              </tr>
            );
          })}
        {!!editableUser.can_add_organizations.length &&
          !!unassignedOrganizations.length && (
            <tr>
              <td colSpan={3}>
                <Button
                  variant="light"
                  onClick={() => {
                    const newMutation: UserOrgMutation = {
                      action: ORG_ACTION_ADD,
                    };
                    setOrgMutations([...orgMutations, newMutation]);
                  }}
                >
                  Add New
                </Button>
              </td>
            </tr>
          )}
      </tbody>
    </Table>
  );
};

interface UserEditPageProps {
  permissions?: string[];
}
const UserEditPage: React.FC<UserEditPageProps> = ({ permissions = [] }) => {
  const { identifier } = useParams();
  const navigate = useNavigate();
  const userId = parseInt(identifier || "0", 10);
  const retrieveCurrentUser = useCurrentUser(true);

  const { data: userOrgData, refetch: userOrgRefetch } = useQuery<
    GetUserOrganizations,
    GetUserOrganizationsVariables
  >(GET_USER_ORGANIZATIONS, {
    variables: { userId },
  });

  const [validated, setValidated] = React.useState<boolean>(false);
  const [orgMutations, setOrgMutations] = React.useState<UserOrgMutation[]>([]);
  const [editableUser, setEditableUser] =
    React.useState<EditableUserOption | null>(null);
  const [editedValues, setEditedValues] = React.useState<Record<string, any>>(
    {}
  );

  const setValueHandler = getFormValueHandler(editedValues, setEditedValues);

  const setValue = (key: string, value: any) => {
    setEditedValues({
      ...editedValues,
      [key]: value,
    });
  };

  React.useEffect(() => {
    const loadUser = async () => {
      if (editableUser) {
        return;
      }
      const loadedUser = await loadEditableUser({ userId });
      if (loadedUser) {
        setEditableUser(loadedUser);
      }
    };
    loadUser();
  }, [editableUser, setEditableUser, userId]);

  const onSave = async (e: any) => {
    e.preventDefault();
    const orgChanges = orgMutations.length
      ? { orgChanges: [...orgMutations] }
      : {};

    const values = { ...editedValues, ...orgChanges };
    if (!Object.keys(values).length) {
      toast.success(gettext("No changes made"));
      return;
    }

    apiClient
      .request("/auth/update-user-record", {
        method: "POST",
        data: {
          id: userId,
          ...values,
        },
      })
      .then(async () => {
        toast.success(gettext("User updated successfully"));
        setValidated(false);
        await userOrgRefetch();
        setEditedValues({});
        retrieveCurrentUser();
        onRedirectBack();
      })
      .catch((e) => {
        console.error(e);
        toast.error(
          gettext("Failed to update user!") + " " + gettext(e.toString())
        );
      });
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    // Show any potential input errors
    setValidated(true);

    // If the form is valid, submit
    const form = event.currentTarget;

    if (form.checkValidity()) {
      onSave(event);
    }
  };
  const onRedirectBack = () => navigate(`/user-management`);
  const userOrganizations: GetUserOrganizations_organization_user[] =
    userOrgData?.organization_user || [];
  return (
    <div className="UserEditPage">
      <PageHeader title="Editing User" className="mb-3"></PageHeader>
      <Card>
        <Card.Body>
          {!editableUser && <div>No user record available</div>}
          {!!editableUser && (
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <Table>
                <tbody>
                  <tr>
                    <th>Email</th>
                    <td>
                      <FormControl
                        type="email"
                        disabled={!permissions.includes("change_email")}
                        value={editedValues.email ?? editableUser.email ?? ""}
                        placeholder={"user@example.com"}
                        onChange={setValueHandler("email")}
                        required
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>Display Name</th>
                    <td>
                      <FormControl
                        disabled={!permissions.includes("change_name")}
                        value={
                          editedValues.displayName ??
                          editableUser.display_name ??
                          ""
                        }
                        placeholder={gettext("Display Name")}
                        onChange={setValueHandler("displayName")}
                        required
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>Selected language</th>
                    <td>
                      <Form.Select
                        required
                        aria-label="Selected language"
                        onChange={setValueHandler("locale")}
                        value={
                          editedValues?.locale ?? editableUser.locale ?? ""
                        }
                      >
                        <option value="">Select language</option>
                        {Object.entries(getLocaleDropdownOptions()).map(
                          ([key, label]) => (
                            <option key={`role_${key}`} value={key}>
                              {gettext(label)}
                            </option>
                          )
                        )}
                      </Form.Select>
                    </td>
                  </tr>
                  {permissions.includes("modify_admin") && (
                    <tr>
                      <th>Global Admin</th>
                      <td>
                        <Form.Check
                          type="checkbox"
                          checked={
                            editedValues.globalAdmin ??
                            editableUser.is_admin ??
                            false
                          }
                          onChange={(e: any) =>
                            setValue("globalAdmin", e.target.checked)
                          }
                        />
                      </td>
                    </tr>
                  )}
                  {permissions.includes("change_password") && (
                    <tr>
                      <th>New Password</th>
                      <td>
                        <FormControl
                          type="password"
                          value={editedValues.password ?? ""}
                          placeholder={gettext("(optional)")}
                          onChange={setValueHandler("password")}
                        />
                      </td>
                    </tr>
                  )}
                  <tr>
                    <th>Organizations</th>
                    <td>
                      <UserOrganizationsPicker
                        editableUser={editableUser}
                        userOrganizations={userOrganizations}
                        orgMutations={orgMutations}
                        setOrgMutations={setOrgMutations}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2}>
                      <div className="actionButtons">
                        <Button variant="light" onClick={onRedirectBack}>
                          Back
                        </Button>
                        <Button type="submit">Save</Button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Form>
          )}
        </Card.Body>
      </Card>
    </div>
  );
};

export default UserEditPage;
