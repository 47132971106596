import * as React from 'react';
import Button from 'react-bootstrap/Button';

class ErrorBoundary extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = { error: null }
  }

  static getDerivedStateFromError(error: Error) {
    console.warn(error)
    return { error: error.toString() }
  }

  //componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
  //  console.warn('Error', error);
  //  console.warn('ErrorInfo', errorInfo);
  //}

  render() {
    if (this.state.error) {
      return (
        <div>
          {process.env.NODE_ENV === 'development' ? (
            <>
              <strong>An error occured:</strong>
              <div>{this.state.error}</div>
            </>
          ) : (
            <div>An error occured.</div>
          )}
          <div className="my-2">
            <Button type="button" onClick={() => this.setState({ error: null })}>Try again.</Button>
          </div>
        </div>
      )
    }
    return this.props.children
  }
}
export default ErrorBoundary;
