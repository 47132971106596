import apiClient from "../../api";
import * as React from "react";
import { toast } from "react-toastify";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import { dateToString } from "../../utils/formatting";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export interface ExportLinksProps {
  startDate: Date;
  endDate: Date;
  station: ExportLinksStation;
}

export interface ExportLinksStation {
  identifier: string;
  name: string;
  datasources: ExportLinksDatasource[];
}

export interface ExportLinksDatasource {
  // Hasura/Apollo think these are nullable ;_;
  type: string | null;
  source_id: number | null;
  name: string | null;
  visible: boolean | null;
}

function datasourceKey(datasource: ExportLinksDatasource) {
  return `${datasource.type}-${datasource.source_id}`;
}

const ExportLinks = (props: ExportLinksProps) => {
  const { startDate, endDate, station } = props;
  const [modalOpen, setModalOpen] = React.useState(false);
  const [allSelected, setAllSelected] = React.useState(false);
  const [exportType, setExportType] = React.useState<"excel" | "csv">("excel");
  const [selectedStatus, setSelectedStatus] = React.useState<
    Record<string, boolean | undefined>
  >({});

  const isDatasourceSelected = (datasource: ExportLinksDatasource): boolean =>
    selectedStatus[datasourceKey(datasource)] ?? datasource.visible ?? false;

  const setAllDatasourceSelected = (selected: boolean) => {
    setAllSelected(selected);

    if (selectedStatus) {
      const newSelectedStatus = station.datasources.reduce(
        (acc: any, datasource) => {
          acc[datasourceKey(datasource)] = selected;
          return acc;
        },
        {}
      );

      setSelectedStatus(newSelectedStatus);
    }
  };

  const setDatasourceSelected = (
    datasource: ExportLinksDatasource,
    selected: boolean
  ) => {
    setSelectedStatus({
      ...selectedStatus,
      [datasourceKey(datasource)]: selected,
    });
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const createExportHandler = (exportType: "csv" | "excel") => (e: any) => {
    e.preventDefault();

    // TODO: antiderivative formulas etc
    const channelIds = station.datasources
      .filter((d) => d.type === "channel" && isDatasourceSelected(d))
      .map((d) => d.source_id!);
    const formulaIds = station.datasources
      .filter((d) => d.type === "formula" && isDatasourceSelected(d))
      .map((d) => d.source_id!);
    const manualPointSetIds = station.datasources
      .filter((d) => d.type === "manual_point_set" && isDatasourceSelected(d))
      .map((d) => d.source_id!);

    const basename = `${station.identifier} ${station.name}`;
    const ext = exportType === "csv" ? "csv" : "xlsx";
    const filename = `${basename}.${ext}`;
    const params = new URLSearchParams();
    params.append("channel_ids", channelIds.join(","));
    params.append("formula_ids", formulaIds.join(","));
    params.append("manual_point_set_ids", manualPointSetIds.join(","));
    params.append("start", startDate.toISOString());
    params.append("end", endDate.toISOString());
    params.append("basename", basename);

    apiClient
      .requestRaw(`/export/${exportType}?${params}`)
      .then((res) => {
        if (res.status >= 400) {
          const msg = `Error exporting data. Status: ${res.status} ${res.statusText}`;
          console.error(msg);
          throw Error(msg);
        }
        return res;
      })
      .then((res) => res.blob())
      .then((blob) => {
        const file = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = file;
        a.download = filename;
        document.body.append(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(file);
        toast.success("Data exported successfully", { theme: "colored" });
      })
      .catch((err) => {
        console.error(err);
        toast.error("Error exporting data", { theme: "colored" });
      });
  };

  return (
    <div className="ExportLinks">
      <Button
        type="button"
        variant="outline-primary"
        size="sm"
        onClick={() => setModalOpen(true)}
        className="mb-2"
      >
        Export
      </Button>

      <Modal
        show={modalOpen}
        onHide={closeModal}
        size="lg"
        aria-labelledby="exportlinks-modal-title"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Export Data
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table>
            <thead>
              <tr>
                <th></th>
                <th>
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="nextTooltip">Select all</Tooltip>}
                  >
                    <Form.Check
                      checked={allSelected}
                      onChange={(e) => setAllDatasourceSelected(!allSelected)}
                    />
                  </OverlayTrigger>
                </th>
              </tr>
            </thead>
            <tbody>
              {station.datasources.map((datasource) => (
                <tr key={datasourceKey(datasource)}>
                  <td>{datasource.name} ({(datasource as any)?.unit?.symbol})</td>
                  <td>
                    <Form.Check
                      checked={isDatasourceSelected(datasource)}
                      onChange={(e) =>
                        setDatasourceSelected(datasource, e.target.checked)
                      }
                    />
                  </td>
                </tr>
              ))}
              <tr>
                <td>Start date</td>
                <td>{dateToString(startDate)}</td>
              </tr>
              <tr>
                <td>End date</td>
                <td>{dateToString(endDate)}</td>
              </tr>
              <tr>
                <td>Type</td>
                <td>
                  <Form.Select
                    value={exportType}
                    onChange={(e) =>
                      setExportType(e.target.value as "csv" | "excel")
                    }
                  >
                    <option value="excel">Excel</option>
                    <option value="csv">CSV</option>
                  </Form.Select>
                </td>
              </tr>
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={closeModal}>
            Close
          </Button>
          <Button variant="primary" onClick={createExportHandler(exportType)}>
            Export as {exportType.toUpperCase()}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ExportLinks;
