import * as React from "react";
import ReactDOMServer from "react-dom/server";
import LeafletMap from "./LeafletMap";
import { gettext } from "i18n";
import { BrowserRouter, Link } from "react-router-dom";
import "./MeasurementStationMap.scss";
import { useQuery } from "@apollo/client";
import { GetMeasurementStation_station_primary_datasource } from "utils/__generated__/GetMeasurementStation";
import { GetMapLayersQuery } from "utils/__generated__/GetMapLayersQuery";
import {
  GET_MAP_LAYERS,
  ALERT_LEVEL_NONE,
  useStationAlertLevels,
} from "utils/stations";

interface MapStation {
  id: number;
  identifier: string;
  name: string;
  lon: number;
  lat: number;
  visibility: string;
  primary_datasource: GetMeasurementStation_station_primary_datasource | null;
  fmi_station_networks: {
    fmi_network: {
      name: string;
      weather_enabled: boolean;
      code: string;
    };
  }[];
}
interface MeasurementStationMapProps {
  stations: MapStation[];
  height?: number | string;
  mapLayer?: string;
}

const alertEndDate = new Date();
const alertStartDate = new Date(+alertEndDate - 1 * 86400000);

const renderComponentToString = (
  Component: React.ComponentType<any>,
  props: any,
  children?: any
) => {
  return ReactDOMServer.renderToString(
    <BrowserRouter>
      <Component {...props}>{children}</Component>
    </BrowserRouter>
  );
};

const MeasurementStationMap = (props: MeasurementStationMapProps) => {
  const { stations, height = 400, mapLayer } = props;
  const { data: layerData } = useQuery<GetMapLayersQuery>(GET_MAP_LAYERS);

  const stationIds = stations.map((s) => s.id);
  const alertlevelPerStation = useStationAlertLevels({
    stationIds,
    startDate: alertStartDate,
    endDate: alertEndDate,
  });


  const mapData = React.useMemo(
    () =>
      stations
        .filter((s) => {
          if (!s.lat || !s.lon) {
            return false;
          }
          // Only include weather_enabled stations
          return (
            !s.fmi_station_networks.length ||
            s.fmi_station_networks?.some((n) => n.fmi_network.weather_enabled)
          );
        })
        .map((s) => {
          const networkName = s.fmi_station_networks?.[0]?.fmi_network?.name;
          const stationName = networkName
            ? `${s.name} (${gettext(networkName)})`
            : s.name;

          const renderedTitle = renderComponentToString(
            Link,
            {
              to: `/stations/${s.identifier}`,
            },
            stationName
          );
          return {
            lon: s.lon,
            lat: s.lat,
            id: s.identifier,
            title: renderedTitle,
            public: s.visibility === "public",
            primarySourceName: s.primary_datasource?.name ?? "",
            primarytUnit: s.primary_datasource?.unit?.symbol ?? "",
            alertLevel: alertlevelPerStation[s.id] ?? ALERT_LEVEL_NONE,
          };
        }),
    [stations, alertlevelPerStation]
  );

  if (!mapData.length) {
    return <div>Ei mittausasemia</div>;
  }

  return (
    <div>
      {!!layerData && (
        <LeafletMap
          data={mapData}
          style={{ height }}
          mapLayer={mapLayer}
          layerData={layerData}
          center={
            stations[0]?.lat && stations[0]?.lon
              ? [stations[0].lat, stations[0].lon]
              : [65.012615, 25.471453]
          }
          zoom={3}
          scrollWheelZoom={false}
        />
      )}
    </div>
  );
};
export default MeasurementStationMap;
