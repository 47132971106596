import { gql } from "@apollo/client";
import * as React from "react";
import { toast } from "react-toastify";
import { useQuery } from "@apollo/client";
import { Button, Card } from "react-bootstrap";
import { useModalContext } from "state/modal";
import PageHeader from "theme/components/common/PageHeader";
import Table from "react-bootstrap/Table";
import {
  GetAuthTokens,
  GetAuthTokensVariables,
  GetAuthTokens_auth_token,
} from "./__generated__/GetAuthTokens";
import { gettext } from "../i18n";
import { useAuth, useLogout } from "state/auth";
import AddAuthTokenForm from "components/modals/AddAuthTokenForm";
import { timestampToDatetimeString } from "utils/formatting";
import apiClient from "api";

type AuthToken = GetAuthTokens_auth_token;

const GET_AUTH_TOKENS = gql`
  query GetAuthTokens($userId: Int) {
    auth_token(where: { user_id: { _eq: $userId } }) {
      id
      description
      expire_timestamp
      user_id
    }
  }
`;

const TokensPage = () => {
  const { user } = useAuth();
  const logout = useLogout();
  const { activeModal, modalPayload, setActiveModal } = useModalContext();

  const [editing, setEditing] = React.useState(false);
  const [submitDisabled, setSubmitDisabled] = React.useState(false);
  const [validated, setValidated] = React.useState<boolean>(false);
  const [newToken, setNewToken] = React.useState<string>("");
  const [editedValues, setEditedValues] = React.useState<Record<string, any>>(
    {}
  );

  const {
    data,
    refetch: refetchTokens,
    loading,
  } = useQuery<GetAuthTokens, GetAuthTokensVariables>(GET_AUTH_TOKENS, {
    variables: {
      userId: user?.id,
    },
  });

  const tokens = data?.auth_token ?? [];

  const onUpdateToken = async (
    payload: Record<string, any> = {},
    action: string = "edit"
  ) => {
    if (submitDisabled) {
      return;
    }
    setSubmitDisabled(true);
    const requestPath = `/auth/token/${action}`;

    try {
      const response = await apiClient.request(requestPath, {
        method: "POST",
        data: {
          ...payload,
        },
      });
      toast.success(gettext("Success"));
      refetchTokens();

      if (response.logout) {
        logout();
      }
      if (response.token) {
        setNewToken(response.token);
      }
      setSubmitDisabled(false);
      return true;
    } catch (e) {
      toast.error(gettext((e as any).message.toString()));
      setSubmitDisabled(false);
    }
    return false;
  };

  const onDeleteToken = (id: number) => {
    if (
      !window.confirm(gettext("Are you sure you want to delete this token?"))
    ) {
      return;
    }
    onUpdateToken(
      {
        id,
      },
      "delete"
    );
  };

  const onAddToken = (description: string) => {
    onUpdateToken(
      {
        description,
      },
      "add"
    );
  };

  if (!user || !tokens.length) {
    return <div>Not logged in</div>;
  }
  return (
    <div className="TokensPage">
      <PageHeader title="Access tokens" className="mb-3" />
      <Card>
        <Card.Body>
          <Table>
            <thead>
              <tr>
                <th>Description</th>
                <th>Expiry date</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {tokens?.map((token: AuthToken) => (
                <tr key={token.id}>
                  <td>{gettext(token.description || "Active token")}</td>
                  <td>{timestampToDatetimeString(token.expire_timestamp)}</td>
                  <td>
                    <Button
                      variant="danger"
                      onClick={() => onDeleteToken(token.id)}
                    >
                      {gettext("Delete")}
                    </Button>
                  </td>
                </tr>
              ))}
              <tr>
                <td colSpan={2}>
                  <Button
                    type="submit"
                    onClick={() => {
                      setActiveModal("addAuthToken");
                    }}
                    disabled={submitDisabled}
                  >
                    Add
                  </Button>
                </td>
              </tr>
            </tbody>
          </Table>
        </Card.Body>
      </Card>
      <AddAuthTokenForm
        onAddToken={onAddToken}
        newToken={newToken}
        setNewToken={setNewToken}
        show={activeModal === "addAuthToken"}
      />
    </div>
  );
};
export default TokensPage;
