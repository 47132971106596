import { gql } from "@apollo/client";

/**
 * Unit related queries and utilities
 */

export const GET_UNITS = gql`
  query GetUnits {
    unit {
      id
      name
      symbol
    }
  }
`;
