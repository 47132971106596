import * as React from "react";

// This array is populated from localStorage upon request, and then always kept up to date
const persistedValues: Record<string, any> = {};

function getAndCachePersistentItem(
  key: string,
  defaultValue: any,
  storageProvider: Storage,
  cacheValueParser?: (value: any) => any
) {
  if (persistedValues[key] !== undefined) {
    return persistedValues[key];
  }

  const item = storageProvider.getItem(key);
  if (item !== null) {
    try {
      const val = JSON.parse(item);
      persistedValues[key] = cacheValueParser ? cacheValueParser(val) : val;
      return val;
    } catch (e) {
      console.error("Error parsing localStorage item", key, item, e);
    }
  }

  if (defaultValue !== undefined) {
    persistedValues[key] = defaultValue;
  }
  return defaultValue;
}

function setPersistentItem(
  key: string,
  value: any,
  storageProvider: Storage,
  cacheValueParser?: (value: any) => any
) {
  if (value === undefined) {
    storageProvider.removeItem(key);
    persistedValues[key] = undefined;
  } else {
    const toStore = JSON.stringify(value);
    storageProvider.setItem(key, toStore);
    persistedValues[key] = cacheValueParser
      ? cacheValueParser(JSON.parse(toStore))
      : JSON.parse(toStore);
    return persistedValues[key];
  }
}

export const usePersistentState = (
  key: string,
  defaultValue: any,
  cacheValueParser?: (value: any) => any,
  storageProvider?: Storage
) => {
  const provider = storageProvider || window.sessionStorage;
  const [, _setValue] = React.useState(() =>
    getAndCachePersistentItem(key, defaultValue, provider, cacheValueParser)
  );
  const setValue = React.useCallback(
    (newValue: any) => {
      _setValue(newValue);
      setPersistentItem(key, newValue, provider, cacheValueParser);
    },
    [_setValue, key, cacheValueParser, provider]
  );
  return [persistedValues[key], setValue];
};
