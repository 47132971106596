import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Form, Row, Col } from "react-bootstrap";
// import Divider from "theme/components/common/Divider";
// import SocialAuthButtons from "theme/components/authentication/SocialAuthButtons";
import { useLogin } from "../../state/auth";
import {gettext} from '../../i18n';

interface LoginFormProps {
  layout: string;
  hasLabel: boolean;
}

const LoginForm = ({ hasLabel = false, layout = "simple" }: LoginFormProps) => {
  const login = useLogin();
  const navigate = useNavigate();

  // State
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    remember: false,
  });

  // Handler
  const doLogin = async (
    email: string,
    password: string,
    remember?: boolean
  ) => {
    const { success, error } = await login({
      email,
      password,
      remember,
    });

    if (!success) {
      toast.error(error || gettext("Login failed"), {
        theme: "colored",
      });
      return;
    }

    toast.success(gettext("Logged in as %1", email), {
      theme: "colored",
    });
    navigate("/stations");
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    doLogin(formData.email, formData.password, formData.remember).catch((e) => {
      console.error("Error in login:", e);
    });
  };

  // const handleSocialAuth = (e: any) => {
  //   e.preventDefault();
  //   doLogin("foo@example.invalid", "password", formData.remember).catch((e) => {
  //     console.error("Error in login:", e);
  //   });
  // };

  const handleFieldChange = (e: any) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Email address</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? gettext("Email address") : ""}
          value={formData.email}
          name="email"
          onChange={handleFieldChange}
          type="email"
        />
      </Form.Group>

      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Password</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? gettext("Password") : ""}
          value={formData.password}
          name="password"
          onChange={handleFieldChange}
          type="password"
        />
      </Form.Group>

      <Row className="justify-content-between align-items-center">
        <Col xs="auto">
          <Form.Check type="checkbox" id="rememberMe">
            <Form.Check.Input
              type="checkbox"
              name="remember"
              checked={formData.remember}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  remember: e.target.checked,
                })
              }
            />
            <Form.Check.Label className="mb-0">Remember Me</Form.Check.Label>
          </Form.Check>
        </Col>

        <Col xs="auto">
          <Link className="fs--1 mb-0" to="/authentication/forgot-password">
            Forgot Password?
          </Link>
        </Col>
      </Row>

      <Form.Group>
        <Button
          type="submit"
          color="primary"
          className="mt-3 w-100"
          disabled={!formData.email || !formData.password}
        >
          Log in
        </Button>
      </Form.Group>

      {/* <Divider className="mt-4">or log in with</Divider>

      <SocialAuthButtons onClick={handleSocialAuth} /> */}
    </Form>
  );
};

LoginForm.propTypes = {
  layout: PropTypes.string,
  hasLabel: PropTypes.bool,
};

LoginForm.defaultProps = {
  layout: "simple",
  hasLabel: false,
};

export default LoginForm;
