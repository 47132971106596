import * as React from 'react';
import apiClient from '../../api';
import {useAuth} from '../../state/auth';
import Button from 'react-bootstrap/Button';
import {Link} from 'react-router-dom';
import isEmpty from "lodash/isEmpty";


export interface StationFileLinksProps {
  stationIdentifier: string;
}

const StationFileLinks = ({stationIdentifier}: StationFileLinksProps) => {
  const {user} = useAuth()
  const [files, setFiles] = React.useState<string[]>([]);

  React.useEffect(() => {
    apiClient
      .requestRaw(`/files/station/${stationIdentifier}`)
      .then(r => r.json())
      .then((data) => {
        setFiles(data);
      }).catch((e) => {
      console.error('Error fetching station files:', e);
    });
  }, [setFiles, stationIdentifier]);

  return (
    <div className="StationFileLinks">
      {(user?.admin || !isEmpty(user?.organizationRoles) || files.length > 0) && (
        <Button
          as={Link as any}
          to={`/stations/${stationIdentifier}/files`}
          variant="outline-primary"
          size="sm"
          className="me-2 mb-2"
        >
          Files
        </Button>
      )}
    </div>
  )
}

export default StationFileLinks;
