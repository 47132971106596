import * as React from "react";
import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useCurrentUser } from "../../state/auth";

const RememberLogin: React.FC = ({ children }) => {
  const [loaded, setLoaded] = useState(false);
  const retrieveCurrentUser = useCurrentUser();

  useEffect(() => {
    if (loaded) {
      return;
    }
    setLoaded(true);
    const attemptLoadUser = async () => {
      await retrieveCurrentUser();
    };
    attemptLoadUser();
  }, [loaded, retrieveCurrentUser]);

  if (!loaded) {
    return (
      <div>
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }
  return <>{children}</>;
};
export default RememberLogin;
