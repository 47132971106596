import * as React from "react";
import { toast } from "react-toastify";
import { useQuery } from "@apollo/client";
import Card from "react-bootstrap/Card";
import apiClient from "api";
import { useNavigate } from "react-router-dom";
import BackToLink from "components/common/BackToLink";
import FormControl from "react-bootstrap/FormControl";
import Table from "react-bootstrap/Table";
import { Button, Form } from "react-bootstrap";
import { gettext } from "i18n";
import { useAuth } from "../../state/auth";
import { getFormValueHandler } from "../../utils/forms";
import { GET_ORGANIZATIONS } from "../../utils/users";
import {
  GetOrganizations,
  GetOrganizations_organization,
} from "../../utils/__generated__/GetOrganizations";
import PageHeader from "../../theme/components/common/PageHeader";

const DashboardAdminAddPage = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [validated, setValidated] = React.useState<boolean>(false);
  const [submitDisabled, setSubmitDisabled] = React.useState<boolean>(false);
  const [editedValues, setEditedValues] = React.useState<Record<string, any>>(
    {}
  );
  const setValueHandler = getFormValueHandler(editedValues, setEditedValues);
  const { data } = useQuery<GetOrganizations>(GET_ORGANIZATIONS);

  const onSave = async (e: any) => {
    e.preventDefault();
    if (submitDisabled) {
      return;
    }
    setSubmitDisabled(true);
    const config: Record<string, any> = {};
    const values = { ...editedValues };
    apiClient
      .request("/admin/add-dashboard", {
        method: "POST",
        data: {
          ...values,
          config,
        },
      })
      .then(async () => {
        toast.success(gettext("Dashboard created successfully"));
        setEditedValues({});
        setValidated(false);
        navigate(`/dashboards`);

        // TODO: Temporary hack to force a reload of GET_ORGANIZATION_DASHBOARDS
        window.location.reload();
      })
      .catch((e) => {
        console.error(e);
        toast.error(
          `${gettext("Failed to create new Dashboard!")} ${gettext(
            e.toString()
          )}`
        );
      });
    setSubmitDisabled(false);
  };
  const handleSubmit = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    setValidated(true);

    const form = event.currentTarget;
    if (form.checkValidity()) {
      onSave(event);
    }
  };
  if (!user?.admin) {
    return <div>You do not have permissions to access this page</div>;
  }
  const organizationList: GetOrganizations_organization[] | undefined =
    data?.organization;

  return (
    <div>
      <BackToLink to={`/dashboards`}>Back</BackToLink>
      <PageHeader title={gettext("Add new dashboard")} className="mb-3" />
      <Card>
        <Card.Body>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Table>
              <tbody>
                <tr>
                  <th>Name</th>
                  <td>
                    <FormControl
                      value={editedValues.name ?? ""}
                      placeholder={gettext("Name")}
                      onChange={setValueHandler("name")}
                      required
                    />
                  </td>
                </tr>
                <tr>
                  <th>Organization</th>
                  <td>
                    <Form.Select
                      aria-label="Organization of the new dashboard"
                      onChange={setValueHandler("organization")}
                      value={editedValues.organization ?? ""}
                    >
                      <option></option>
                      {organizationList?.map(
                        (organization: GetOrganizations_organization) => (
                          <option
                            key={`org_${organization.id}`}
                            value={organization.id}
                          >
                            {organization.name}
                          </option>
                        )
                      )}
                    </Form.Select>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <Button type="submit" disabled={submitDisabled}>
                      Add
                    </Button>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
};

export default DashboardAdminAddPage;
