import { useQuery, ApolloQueryResult } from "@apollo/client";
import { OperationVariables } from "@apollo/client/core";
import { GetUnits, GetUnits_unit } from "../utils/__generated__/GetUnits";
import { GET_UNITS } from "../utils/units";

interface SortedUnits {
  data: GetUnits_unit[];
  refetch: (
    variables?: Partial<OperationVariables> | undefined
  ) => Promise<ApolloQueryResult<GetUnits>>;
}
export const useSortedUnits = (): SortedUnits => {
  const { data: unitData, refetch } = useQuery<GetUnits>(GET_UNITS);

  return {
    data: unitData?.unit
      ? [...unitData.unit].sort((a, b) => (a.symbol > b.symbol ? 1 : -1))
      : [],
    refetch,
  };
};

export default useSortedUnits;
