import * as React from "react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { loadContextPermissions } from "../../utils/users";

interface PermissionContextCheckProps {
  children: React.ReactElement;
  category: string;
  target?: string;
  requiredPermission?: string;
}
const PermissionContextCheck: React.FC<PermissionContextCheckProps> = ({
  children,
  category,
  target,
  requiredPermission,
}: PermissionContextCheckProps) => {
  const [loaded, setLoaded] = useState(false);
  const { identifier } = useParams();
  const [permissions, setPermissions] = useState<string[]>([]);
  if (target === undefined && identifier) {
    target = identifier;
  }

  useEffect(() => {
    const loadPermissions = async () => {
      try {
        const contextPermissions = await loadContextPermissions({
          category,
          target,
          requiredPermission,
        });
        if (contextPermissions) {
          setPermissions(contextPermissions);
        }
      } catch (e) {
        console.error(e);
      }
      setLoaded(true);
    };
    loadPermissions();
  }, [category, target, requiredPermission]);

  if (!loaded) {
    return (
      <div>
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }
  if (requiredPermission && !permissions.includes(requiredPermission)) {
    return <></>;
  }
  return <>{React.cloneElement(children, { permissions })}</>;
};
export default PermissionContextCheck;
