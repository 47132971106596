import * as React from 'react';

const refreshEventName = "refresh-react-components";
const refreshEvent = new Event(refreshEventName)

export const ReactComponentRefresher: React.FC = ({ children }) => {
  const [shouldRefresh, setShouldRefresh] = React.useState(false);
  React.useEffect(() => {
    if (shouldRefresh) {
      // Not sure if the timeout is necessary
      setTimeout(() => {
        setShouldRefresh(false);
      }, 10);
    }

    const refreshListener = () => {
      console.log("Refreshing components");
      setShouldRefresh(true);
    }

    window.addEventListener(refreshEventName, refreshListener);

    return () => {
      window.removeEventListener(refreshEventName, refreshListener);
    }
  }, [shouldRefresh, setShouldRefresh])

  if (shouldRefresh) {
    return <div />;
  }

  return <>{children}</>;
}

export const refreshReactComponents = () => {
  window.dispatchEvent(refreshEvent);
}

// TODO: helper function for debugging
(window as any).__refresh = refreshReactComponents;
