import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './init-echarts';

import 'react-toastify/dist/ReactToastify.min.css';
import './theme/helpers/initFA';
import './theme/assets/scss/theme.scss';
import './theme/assets/scss/user.scss';
import * as i18n from './i18n'

i18n.init();

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
