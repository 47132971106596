import React from "react";
import PropTypes from "prop-types";
import SimpleBarReact from "simplebar-react";
import { Table, Pagination } from "react-bootstrap";

const AdvanceTable = ({
  table,
  getTableProps,
  headers,
  page,
  prepareRow,
  headerClassName,
  rowClassName,
  tableProps,
  pagination,
  canPreviousPage,
  canNextPage,
  nextPage,
  previousPage,
  gotoPage,
  pageCount,
  pageIndex,
  pageSize,
  setPageSize,
}) => {
  return (
    <SimpleBarReact>
      <Table {...getTableProps(tableProps)}>
        <thead className={headerClassName}>
          <tr>
            {headers.map((column, index) => (
              <th
                key={index}
                {...column.getHeaderProps(
                  column.getSortByToggleProps(column.headerProps)
                )}
              >
                {column.render("Header")}
                {column.canSort ? (
                  column.isSorted ? (
                    column.isSortedDesc ? (
                      <span className="sort desc" />
                    ) : (
                      <span className="sort asc" />
                    )
                  ) : (
                    <span className="sort" />
                  )
                ) : (
                  ""
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr key={i} className={rowClassName} {...row.getRowProps()}>
                {row.cells.map((cell, index) => {
                  return (
                    <td
                      key={index}
                      {...cell.getCellProps(cell.column.cellProps)}
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
        {!!pagination && (
          <tfoot>
            <tr>
              <td colSpan={headers.length}>
                <Pagination>
                  <Pagination.Prev
                    onClick={() => previousPage()}
                    disabled={!canPreviousPage}
                  />
                  {pageIndex > 0 && (
                    <Pagination.Item
                      onClick={() => gotoPage(0)}
                      disabled={!canPreviousPage}
                    >
                      {1}
                    </Pagination.Item>
                  )}
                  {pageIndex > 1 && <Pagination.Ellipsis disabled />}
                  {pageIndex > 1 && (
                    <Pagination.Item
                      onClick={() => gotoPage(pageIndex - 1)}
                      disabled={!canPreviousPage}
                    >
                      {pageIndex}
                    </Pagination.Item>
                  )}
                  <Pagination.Item active>{pageIndex + 1}</Pagination.Item>
                  {pageIndex < pageCount - 2 && (
                    <Pagination.Item
                      onClick={() => gotoPage(pageIndex + 1)}
                      disabled={!canNextPage}
                    >
                      {pageIndex + 2}
                    </Pagination.Item>
                  )}
                  {pageIndex < pageCount - 3 && (
                    <Pagination.Ellipsis disabled />
                  )}
                  {pageIndex < pageCount - 1 && (
                    <Pagination.Item
                      onClick={() => gotoPage(pageCount - 1)}
                      disabled={!canNextPage}
                    >
                      {pageCount}
                    </Pagination.Item>
                  )}
                  <Pagination.Next
                    onClick={() => nextPage()}
                    disabled={!canNextPage}
                  />
                </Pagination>
              </td>
            </tr>
          </tfoot>
        )}
      </Table>
    </SimpleBarReact>
  );
};
AdvanceTable.propTypes = {
  getTableProps: PropTypes.func,
  headers: PropTypes.array,
  page: PropTypes.array,
  prepareRow: PropTypes.func,
  headerClassName: PropTypes.string,
  rowClassName: PropTypes.string,
  tableProps: PropTypes.object,
  pagination: PropTypes.bool,
  canPreviousPage: PropTypes.bool,
  canNextPage: PropTypes.bool,
  nextPage: PropTypes.func,
  previousPage: PropTypes.func,
  gotoPage: PropTypes.func,
  pageCount: PropTypes.number,
  pageIndex: PropTypes.number,
  pageSize: PropTypes.number,
  setPageSize: PropTypes.func,
};

export default AdvanceTable;
