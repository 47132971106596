import * as React from "react";
import { toast } from "react-toastify";
import { gql, useQuery } from "@apollo/client";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import ReactEChartsCore from "echarts-for-react/lib/core";
import * as echarts from "echarts/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faCopy } from "@fortawesome/free-solid-svg-icons";
import { gettext } from "i18n";
import {
  GetStationDashboardData,
  GetStationDashboardData_flow,
  GetStationDashboardDataVariables,
} from "./__generated__/GetStationDashboardData";
import {
  GetAccruedFormulaValues,
  GetAccruedFormulaValuesVariables,
} from "utils/__generated__/GetAccruedFormulaValues";
import { GetDatasourceDataByUUID_source } from "utils/__generated__/GetDatasourceDataByUUID";
import { GetDatasourceData_source_data } from "utils/__generated__/GetDatasourceData";
import {
  GetVerticalTemperature,
  GetVerticalTemperatureVariables,
  GetVerticalTemperature_get_vertical_temperature,
} from "utils/__generated__/GetVerticalTemperature";
import {
  GetMeasurementStationMetaByIdQuery,
  GetMeasurementStationMetaByIdQueryVariables,
} from "utils/__generated__/GetMeasurementStationMetaByIdQuery";
import { useCachedSubscription } from "../../hooks/apollo";
import Spinner from "react-bootstrap/Spinner";
import Table from "react-bootstrap/Table";
import { useModalContext } from "state/modal";
import { IntervalUnit } from "components/dates/DateTimeRangeSelector";
import {
  toLocaleDateString,
  formatNumberSafe,
  formatNumberLocale,
  timestampToDatetimeString,
} from "utils/formatting";
import { insertIntoList } from "utils/arrays";
import { getLiteralArray } from "utils/hasura";
import {
  DataPoint,
  getChartSeriesColor,
  FullDataSourceLoader,
  AdditionalDatapointData,
} from "utils/datacharts";
// TODO: extract these components so they need not be imported from MeasurementStationPage
import { DataChart } from "pages/MeasurementStationPage";
import {
  getSourceUnitSymbol,
  GET_VERTICAL_TEMPERATURE,
  GET_MEASUREMENT_STATION_META_BY_ID,
  GET_ACCRUED_FORMULA_VALUES,
} from "utils/stations";
import classNames from "classnames";

type AvailableWidget = {
  key: string;
  component: (sourceUUID: string | undefined, metaData: any) => React.ReactNode;
};

interface RenderWidget extends AvailableWidget {
  sourceUUID?: string;
  metaData?: any;
}

type WidgetGroupItem = { key: string; sourceUUID?: string; metaData?: any };

type VerticalTemperatureRecord =
  GetVerticalTemperature_get_vertical_temperature;

const GET_STATION_DASHBOARD_DATA = gql`
  subscription GetStationDashboardData(
    $startDate: timestamptz!
    $endDate: timestamptz!
    $sourceType: String!
    $sourceId: Int!
  ) {
    flow: get_datasource(args: { type: $sourceType, id: $sourceId }) {
      type
      source_id
      source_uuid
      unit {
        id
        name
        symbol
      }
      name
      visible
      order
      widget_size
      station {
        name
        identifier
      }
      datasource_annotation {
        id
        timestamp
        value
        text
        color
      }
      data(
        args: { start: $startDate, end: $endDate }
        order_by: { timestamp: asc }
      ) {
        timestamp
        value
        invalid
      }
    }
  }
`;

const getYAxisValue = (record: VerticalTemperatureRecord) => {
  let cm = record.system_name?.replace("vertical_temperature_", "") ?? "";
  cm = cm.replace("cm", "");

  const numericValue = parseInt(cm, 10);
  return numericValue !== 0 ? -numericValue : numericValue;
};

interface VerticalTempChartProps {
  stationDatasets: Record<number, StationDataset>;
}
const VerticalTempChart: React.FC<VerticalTempChartProps> = ({
  stationDatasets,
}) => {
  const [chartInstance, setChartInstance] = React.useState<any>(null);
  const [lastSeriesLength, setLastSeriesLength] = React.useState<number>(0);

  const stationNames = Object.values(stationDatasets).map(
    (set) => set.stationName
  );

  let min: number = 0;
  let max: number = 0;
  let totalSeriesLength = 0;
  Object.values(stationDatasets).forEach((dataset) => {
    const { temperatureData } = dataset;
    temperatureData.forEach((record) => {
      min = Math.min(min, record.value);
      max = Math.max(max, record.value);
      totalSeriesLength++;
    });
  });

  // Add some padding to the min and max values to prevent the chart from
  // having a value exactly at the edge of the chart.
  min -= 0.01;
  max += 0.01;
  if (min > -1) {
    min = -2;
  }
  if (max < 1) {
    max = 2;
  }

  const chartOptions = React.useMemo<any>(() => {
    return {
      legend: {
        data: stationNames,
      },
      tooltip: {
        trigger: "axis",
        formatter: (value: any) => {
          const depth = value[0]?.data[1];
          let label = `${gettext("Temperature")} (${depth}cm):<br/>`;

          value.forEach((item: any) => {
            const temperature = item.data?.[0];
            label += `${item.marker} ${item.seriesName}: ${temperature}°C<br/>`;
          });

          return label;
        },
        axisPointer: {
          axis: "y",
        },
      },
      grid: {
        left: "3%",
        right: "4%",
        bottom: "3%",
        containLabel: true,
      },
      xAxis: {
        type: "value",
        axisLabel: {
          formatter: "{value} °C",
        },
        min,
        max,
      },
      yAxis: {
        type: "value",
        axisLine: { onZero: false },
        axisLabel: {
          formatter: "{value} cm",
        },
        boundaryGap: false,
      },
      series: Object.values(stationDatasets).map((dataset, idx) => {
        const isFirst = idx === 0;
        const { stationName, temperatureData } = dataset;
        const seriesName = stationName;

        const valuesXY = temperatureData.map((record) => {
          const yAxisValue = getYAxisValue(record);
          return [record.value, yAxisValue];
        });
        // sort highest to lowest, otherwise it will draw nonsensical scribbles
        valuesXY.sort((a, b) => b[1] - a[1]);

        const seriesColor = getChartSeriesColor(idx);
        const lineStyle = {
          type: "solid",
          color: "rgba(162, 204, 255, 1)",
        };
        return {
          name: seriesName,
          type: "line",
          symbolSize: 6,
          symbol: "circle",
          smooth: true,
          itemStyle: {
            color: seriesColor,
            borderColor: seriesColor,
            borderWidth: 1,
          },
          lineStyle: {
            width: 3,
            color: seriesColor,
            shadowColor: "rgba(0,0,0,0.3)",
            shadowBlur: 10,
            shadowOffsetY: 8,
          },
          data: valuesXY,
          ...(isFirst && {
            markLine: {
              data: [{ name: "0 °C", xAxis: 0, lineStyle }],
              label: {
                formatter: () => {
                  return `0 °C`;
                },
                position: "middle",
                padding: [0, 0, -5, 0],
                show: true,
              },
              symbol: "none",
              silent: true,
            },
            markArea: {
              silent: true,
              data: [
                [
                  { originX: "start" },
                  {
                    xAxis: 0,
                    itemStyle: {
                      color: "rgba(182, 224, 255, 0.4)",
                    },
                  },
                ],
              ],
            },
          }),
        };
      }),
    };
  }, [stationNames, min, max, stationDatasets]);

  React.useEffect(() => {
    if (!chartInstance) {
      return;
    }
    if (!chartOptions || !totalSeriesLength) {
      return;
    }

    const echartInstance = chartInstance.getEchartsInstance();
    if (lastSeriesLength !== totalSeriesLength) {
      echartInstance.clear();
      setLastSeriesLength(totalSeriesLength);
    }
    echartInstance.setOption(chartOptions);
  }, [chartOptions, chartInstance, lastSeriesLength, totalSeriesLength]);

  return (
    <ReactEChartsCore
      ref={setChartInstance}
      option={chartOptions}
      echarts={echarts}
      style={{ height: "18.75rem" }}
      opts={{
        locale: "FI",
      }}
    />
  );
};

const dailyValuesToDatapoints = (values: {
  [key: string]: number;
}): DataPoint[] => {
  return Object.keys(values).map((dateKey) => {
    const date = new Date(dateKey);

    // Date input (and chart startDate) is timezone-aware, but dateKey produces dates in UTC.
    // Align the dates by adjusting the timezone offset.
    const adjustedDate = new Date(
      date.getTime() - new Date().getTimezoneOffset() * 60 * 1000
    );
    return {
      invalid: false,
      timestamp: adjustedDate.toISOString(),
      value: values[dateKey],
      __typename: "data",
    };
  });
};

interface StatsSummaryWidgetProps {
  flow: GetStationDashboardData_flow;
  datapoints: GetDatasourceData_source_data[];
  endDate: Date;
}

const StatsSummaryWidget: React.FC<StatsSummaryWidgetProps> = ({
  flow,
  datapoints,
  endDate,
}) => {
  const intervalStart = new Date(endDate.getTime() - 24 * 60 * 60 * 1000);
  const datapointsWithinInterval =
    datapoints?.filter(
      (datapoint) => new Date(datapoint.timestamp) > intervalStart
    ) ?? [];
  const lastDatapoint = datapoints[datapoints.length - 1];
  const averageWithinInterval =
    datapointsWithinInterval.reduce(
      (sum, datapoint) => sum + datapoint.value,
      0
    ) / (datapointsWithinInterval.length || 1);
  const average30d =
    datapoints?.reduce((sum, datapoint) => sum + datapoint.value, 0) /
    (datapoints?.length || 1);

  const min30d = datapoints.reduce(
    (min, datapoint) => Math.min(min, datapoint.value),
    Infinity
  );
  const max30d = datapoints.reduce(
    (max, datapoint) => Math.max(max, datapoint.value),
    -Infinity
  );

  return (
    <Card>
      <Card.Body>
        <Table>
          <tbody>
            <tr>
              <th>
                Latest datapoint
                <br />
                <small>
                  (
                  {lastDatapoint
                    ? timestampToDatetimeString(lastDatapoint.timestamp)
                    : "N/A"}
                  )
                </small>
              </th>
              <td>
                <DashboardNumber
                  value={lastDatapoint?.value ?? 0}
                  unit={flow.unit?.symbol}
                />
              </td>
            </tr>
            <tr>
              <th>Average (24h)</th>
              <td>
                <DashboardNumber
                  value={averageWithinInterval}
                  unit={flow.unit?.symbol}
                />
              </td>
            </tr>
            <tr>
              <th>Average</th>
              <td>
                <DashboardNumber value={average30d} unit={flow.unit?.symbol} />
              </td>
            </tr>
            <tr>
              <th>Min</th>
              <td>
                <DashboardNumber
                  value={min30d !== Infinity ? min30d : 0}
                  unit={flow.unit?.symbol}
                />
              </td>
            </tr>
            <tr>
              <th>Max</th>
              <td>
                <DashboardNumber
                  value={max30d !== -Infinity ? max30d : 0}
                  unit={flow.unit?.symbol}
                />
              </td>
            </tr>
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

interface PrimaryParameterAverageChartProps {
  flow: GetStationDashboardData_flow;
  startDate: Date;
  endDate: Date;
  dailyAverages: { [key: string]: number };
  dailyMins: { [key: string]: number };
  dailyMaxs: { [key: string]: number };
}
const PrimaryParameterAverageChart: React.FC<
  PrimaryParameterAverageChartProps
> = ({ flow, startDate, endDate, dailyAverages, dailyMins, dailyMaxs }) => {
  // Convert daily averages into a list of datapoints for a chart
  const dailyAverageDatapoints: DataPoint[] =
    dailyValuesToDatapoints(dailyAverages);
  const dailyMinDatapoints: GetDatasourceData_source_data[] =
    dailyValuesToDatapoints(dailyMins) as GetDatasourceData_source_data[];
  const dailyMaxDatapoints: GetDatasourceData_source_data[] =
    dailyValuesToDatapoints(dailyMaxs) as GetDatasourceData_source_data[];

  const additionalDatapoints: AdditionalDatapointData[] = [
    {
      source: flow,
      data: dailyMinDatapoints,
      seriesData: {
        name: "Min",
        stack: "confidence-band",
      },
    },
    {
      source: flow,
      data: dailyMaxDatapoints,
      subtractData: dailyMinDatapoints,
      seriesData: {
        name: "Max",
        stack: "confidence-band",
        areaStyle: {
          color: "#ccc",
        },
      },
    },
  ];

  return (
    <Card className="DataSourceCard">
      <Card.Header>
        <h5>
          {flow.name} [{flow.unit?.symbol}]: <span>Average</span>{" "}
        </h5>
      </Card.Header>
      <Card.Body>
        <DataChart
          confidenceBand
          source={flow}
          datapoints={dailyAverageDatapoints}
          additionalDatapoints={additionalDatapoints}
          startDate={startDate}
          endDate={endDate}
          datapointGroupDateFormat="YYYY-MM-DD"
          labelDateFormat="DD.MM.YYYY"
        />
      </Card.Body>
    </Card>
  );
};

interface DailyAveragesTableProps {
  dailyAverageKeys: string[];
  dailyAverages: { [key: string]: number };
  dailyMins: { [key: string]: number };
  dailyMaxs: { [key: string]: number };
}
const DailyAveragesTable: React.FC<DailyAveragesTableProps> = ({
  dailyAverageKeys,
  dailyAverages,
  dailyMins,
  dailyMaxs,
}) => {
  const copyToClipboard = () => {
    let tableContents = "";
    dailyAverageKeys.forEach((dateKey) => {
      const average = dailyAverages[dateKey];
      const min = dailyMins[dateKey];
      const max = dailyMaxs[dateKey];
      const date = new Date(dateKey);
      tableContents += `${toLocaleDateString(date)}\t${formatNumberSafe(
        max
      )}\t${formatNumberSafe(average)}\t${formatNumberSafe(min)}\n`;
    });

    try {
      navigator.clipboard.writeText(tableContents);
      toast.success(gettext("Value copied to clipboard"));
    } catch (err) {
      console.error(
        "Failed to copy to clipboard: ",
        err,
        `\n\nValue:\n${tableContents}`
      );
      toast.error(gettext("Action failed"));
    }
  };

  return (
    <Card className="DailyAveragesTable">
      <Card.Body>
        <Table>
          <thead>
            <tr>
              <th className="copy-to-clipboard" onClick={copyToClipboard}>
                <FontAwesomeIcon icon={faCopy} />
                <span>copy</span>
              </th>

              <th>Max</th>

              <th>Average</th>

              <th>Min</th>
            </tr>
          </thead>
          <tbody>
            {!dailyAverageKeys.length && (
              <tr>
                <td colSpan={4}>
                  <p>No data available</p>
                </td>
              </tr>
            )}
            {dailyAverageKeys.map((dateKey) => {
              const average = dailyAverages[dateKey];
              const min = dailyMins[dateKey];
              const max = dailyMaxs[dateKey];
              const date = new Date(dateKey);

              return (
                <tr key={dateKey}>
                  <td>{toLocaleDateString(date)}</td>

                  <td>{formatNumberSafe(max)}</td>

                  <td>
                    <b>{formatNumberSafe(average)}</b>
                  </td>

                  <td>{formatNumberSafe(min)}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

interface StationDataset {
  stationName: string;
  temperatureData: VerticalTemperatureRecord[];
}

interface TemperatureProfileWidgetMetadata {
  addStationIds: number[];
}

interface TemperatureProfileWidgetProps {
  stationId: number;
  startDate: Date;
  endDate: Date;
  metaData?: TemperatureProfileWidgetMetadata;
}

const TemperatureProfileWidget: React.FC<TemperatureProfileWidgetProps> = ({
  stationId,
  startDate,
  endDate,
  metaData,
}) => {
  const { addStationIds } = metaData ?? {};
  const [stationDatasets, setStationDatasets] = React.useState<
    Record<number, StationDataset>
  >({});
  const stationIds = React.useMemo(() => {
    if (!addStationIds?.length) {
      return [stationId];
    }
    return Array.from(new Set([...addStationIds]));
  }, [stationId, addStationIds]);
  const datasetLoaderMemo = React.useMemo(() => {
    return stationIds.map((stationId) => (
      <StationDatasetLoader
        key={`station_dataset_loader_${stationId}`}
        stationId={stationId}
        startDate={startDate}
        endDate={endDate}
        stationDatasets={stationDatasets}
        setStationDatasets={setStationDatasets}
      />
    ));
  }, [stationIds, startDate, endDate, stationDatasets, setStationDatasets]);

  return (
    <Card className={classNames("DataSourceCard")}>
      <Card.Header>
        <h5>Temperature profile</h5>
      </Card.Header>
      <Card.Body>
        {datasetLoaderMemo}
        {!Object.keys(stationDatasets).length && <Spinner animation="border" />}
        <VerticalTempChart stationDatasets={stationDatasets} />
      </Card.Body>
    </Card>
  );
};

interface StationDatasetLoaderProps {
  stationId: number;
  startDate: Date;
  endDate: Date;
  stationDatasets: Record<number, StationDataset>;
  setStationDatasets: (datasets: Record<number, StationDataset>) => void;
}

const StationDatasetLoader: React.FC<StationDatasetLoaderProps> = ({
  stationId,
  startDate,
  endDate,
  stationDatasets,
  setStationDatasets,
}) => {
  const { data: verticalTempData, loading: verticalTempLoading } = useQuery<
    GetVerticalTemperature,
    GetVerticalTemperatureVariables
  >(GET_VERTICAL_TEMPERATURE, {
    variables: {
      stationId: stationId,
      minTimestamp: startDate,
      maxTimestamp: endDate,
    },
  });

  // Get station name from GET_MEASUREMENT_STATION_META_BY_ID
  const { data: stationMeta, loading: stationMetaLoading } = useQuery<
    GetMeasurementStationMetaByIdQuery,
    GetMeasurementStationMetaByIdQueryVariables
  >(GET_MEASUREMENT_STATION_META_BY_ID, {
    variables: {
      id: stationId,
    },
  });

  React.useEffect(() => {
    if (!verticalTempData || verticalTempLoading) {
      return;
    }
    if (!stationMeta || stationMetaLoading) {
      return;
    }

    const data = verticalTempData?.get_vertical_temperature ?? [];
    const stationName = stationMeta?.station?.[0]?.name ?? "";

    setStationDatasets({
      ...stationDatasets,
      [stationId]: {
        stationName,
        temperatureData: data,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    stationId,
    verticalTempData,
    verticalTempLoading,
    stationMeta,
    stationMetaLoading,
    setStationDatasets,
  ]);

  return <></>;
};

interface MultiSourceChartProps {
  startDate: Date;
  endDate: Date;
  sourceUUIDs: string[];
  title?: string;
}
const MultiSourceChart: React.FC<MultiSourceChartProps> = ({
  startDate,
  endDate,
  sourceUUIDs,
  title,
}) => {
  const [combinedDatapoints, setCombinedDatapoints] = React.useState<
    AdditionalDatapointData[]
  >([]);

  return (
    <Card className="DataSourceCard">
      <Card.Header>
        {!!title && <h5>{gettext(title)}</h5>}
        {!!combinedDatapoints?.length && (
          <span className="additional-data-sources">
            {combinedDatapoints?.map(
              (
                additionalDatapointData: AdditionalDatapointData,
                idx: number
              ) => {
                const addSource = additionalDatapointData.source;
                const addStation = additionalDatapointData.source.station;
                const sourceName = `${addStation?.name} (${
                  addStation?.identifier
                }) - ${addSource.name} ${getSourceUnitSymbol(addSource)}`;
                return (
                  <h6
                    key={`additional_data_source_${addSource.source_uuid}`}
                    className="additional-data-source"
                    style={{ color: getChartSeriesColor(idx) }}
                  >
                    {sourceName}
                  </h6>
                );
              }
            )}
          </span>
        )}
      </Card.Header>
      <Card.Body>
        {sourceUUIDs?.map((sourceUUID, idx) => {
          return (
            <FullDataSourceLoader
              key={`additional_loader_${sourceUUID}_${idx}`}
              sourceUUID={sourceUUID}
              startDate={startDate}
              endDate={endDate}
              additionalDatapoints={combinedDatapoints}
              setSourceData={(
                source: GetDatasourceDataByUUID_source,
                additionalData: GetDatasourceData_source_data[]
              ) => {
                let newCombinedDatapoints = [
                  ...combinedDatapoints.filter(
                    (additionalDatapoint: AdditionalDatapointData) =>
                      additionalDatapoint.source.source_uuid !==
                      source.source_uuid
                  ),
                  {
                    source,
                    data: additionalData,
                  },
                ];

                // Sort newCombinedDatapoints by source.source_uuid to match the same order as in sourceUUIDs
                newCombinedDatapoints = newCombinedDatapoints.sort(
                  (a, b) =>
                    sourceUUIDs.indexOf(a.source.source_uuid) -
                    sourceUUIDs.indexOf(b.source.source_uuid)
                );
                setCombinedDatapoints(newCombinedDatapoints);
              }}
            />
          );
        })}

        {!!combinedDatapoints.length && (
          <DataChart
            source={combinedDatapoints[0].source}
            datapoints={combinedDatapoints[0].data}
            startDate={startDate}
            endDate={endDate}
            additionalDatapoints={
              combinedDatapoints.length > 1 ? combinedDatapoints.slice(1) : []
            }
          />
        )}
      </Card.Body>
    </Card>
  );
};

const getSumGroups = (
  accruedValues: GetAccruedFormulaValues,
  monthly: boolean,
  displayUnit: string | undefined
) => {
  const sumGroups: { [key: string]: number } = {};
  const isCubicMeter = displayUnit === "m³";
  accruedValues?.hasura_accrued_values?.forEach((accruedValue) => {
    const dateKey = accruedValue.accrual_date;
    sumGroups[dateKey] = accruedValue.accrued_total;
    if (isCubicMeter) {
      sumGroups[dateKey] = sumGroups[dateKey] / 1000;
    }
  });
  if (monthly) {
    const monthlySumGroups: { [key: string]: number } = {};
    Object.keys(sumGroups).forEach((dateKey) => {
      const date = new Date(dateKey);
      const utcMonth = date.getUTCMonth();
      const utcMonthStr =
        utcMonth + 1 < 10 ? `0${utcMonth + 1}` : `${utcMonth + 1}`;
      const monthKey = `${date.getUTCFullYear()}-${utcMonthStr}-01`;
      monthlySumGroups[monthKey] =
        (monthlySumGroups[monthKey] ?? 0) + sumGroups[dateKey];
    });
    return monthlySumGroups;
  }
  return sumGroups;
};

interface TotalDischargeTableProps {
  startDate: Date;
  endDate: Date;
  sourceUUIDs: string[];
  title?: string;
  monthly?: boolean;
  displayUnit?: string;
}
const TotalDischargeTable: React.FC<TotalDischargeTableProps> = ({
  startDate,
  endDate,
  sourceUUIDs,
  title,
  displayUnit,
  monthly = false,
}) => {
  const [combinedDatapoints, setCombinedDatapoints] = React.useState<
    AdditionalDatapointData[]
  >([]);

  const unitLabel = displayUnit ?? "l";
  const literalUUIDs = getLiteralArray(sourceUUIDs);
  const { data: accruedValues, loading: accruedValuesLoading } = useQuery<
    GetAccruedFormulaValues,
    GetAccruedFormulaValuesVariables
  >(GET_ACCRUED_FORMULA_VALUES, {
    variables: {
      uuids: literalUUIDs,
      startDate: startDate,
      endDate: endDate,
    },
  });
  const sumGroups = React.useMemo(
    () =>
      accruedValuesLoading || accruedValues === undefined
        ? {}
        : getSumGroups(accruedValues, monthly, displayUnit),
    [accruedValues, accruedValuesLoading, monthly, displayUnit]
  );
  const sortedSumGroups = Object.keys(sumGroups).sort();

  const copyToClipboard = () => {
    let tableContents = "";
    sortedSumGroups.forEach((dateKey) => {
      const sum = sumGroups[dateKey];
      const date = new Date(dateKey);
      tableContents += `${
        monthly
          ? date.toLocaleDateString("fi-FI", {
              month: "2-digit",
              year: "numeric",
            })
          : toLocaleDateString(date)
      }\t${Math.round(sum)}\n`;
    });

    try {
      navigator.clipboard.writeText(tableContents);
      toast.success(gettext("Value copied to clipboard"));
    } catch (err) {
      console.error(
        "Failed to copy to clipboard: ",
        err,
        `\n\nValue:\n${tableContents}`
      );
      toast.error(gettext("Action failed"));
    }
  };

  return (
    <Card className="DataSourceCard">
      <Card.Header>
        {!!title && (
          <h5>
            {gettext(title)} [{unitLabel}]
          </h5>
        )}
        {!title && (
          <h5>
            {gettext(
              monthly ? "Total Monthly Discharge" : "Total Daily Discharge"
            )}{" "}
            [{unitLabel}]
          </h5>
        )}
        {!!combinedDatapoints?.length && (
          <span className="additional-data-sources">
            {combinedDatapoints?.map(
              (
                additionalDatapointData: AdditionalDatapointData,
                idx: number
              ) => {
                const addSource = additionalDatapointData.source;
                const addStation = additionalDatapointData.source.station;
                const sourceName = `${addStation?.name} (${
                  addStation?.identifier
                }) - ${addSource.name} ${getSourceUnitSymbol(addSource)}`;
                return (
                  <h6
                    key={`additional_data_source_${addSource.source_uuid}`}
                    className="additional-data-source"
                    style={{ color: getChartSeriesColor(idx) }}
                  >
                    {sourceName}
                  </h6>
                );
              }
            )}
          </span>
        )}
      </Card.Header>
      <Card.Body>
        {sourceUUIDs?.map((sourceUUID, idx) => {
          return (
            <FullDataSourceLoader
              key={`additional_loader_${sourceUUID}_${idx}`}
              sourceUUID={sourceUUID}
              startDate={startDate}
              endDate={endDate}
              additionalDatapoints={combinedDatapoints}
              setSourceData={(
                source: GetDatasourceDataByUUID_source,
                additionalData: GetDatasourceData_source_data[]
              ) => {
                let newCombinedDatapoints = [
                  ...combinedDatapoints.filter(
                    (additionalDatapoint: AdditionalDatapointData) =>
                      additionalDatapoint.source.source_uuid !==
                      source.source_uuid
                  ),
                  {
                    source,
                    data: additionalData,
                  },
                ];

                // Sort newCombinedDatapoints by source.source_uuid to match the same order as in sourceUUIDs
                newCombinedDatapoints = newCombinedDatapoints.sort(
                  (a, b) =>
                    sourceUUIDs.indexOf(a.source.source_uuid) -
                    sourceUUIDs.indexOf(b.source.source_uuid)
                );

                setCombinedDatapoints(newCombinedDatapoints);
              }}
            />
          );
        })}
        {!!combinedDatapoints.length && (
          <>
            {!!sortedSumGroups.length && (
              <span
                className="copy-to-clipboard standalone"
                onClick={copyToClipboard}
              >
                <FontAwesomeIcon icon={faCopy} />
                <span>copy</span>
              </span>
            )}
            <Table>
              <thead>
                <tr>
                  <th>{monthly ? gettext("Month") : gettext("Date")}</th>
                  <th>Sum</th>
                </tr>
              </thead>
              <tbody>
                {sortedSumGroups.map((dateKey) => {
                  const sum = sumGroups[dateKey];
                  const date = new Date(dateKey);
                  return (
                    <tr key={dateKey}>
                      <td>
                        {monthly
                          ? `${date.getMonth() + 1}.${date.getFullYear()}`
                          : toLocaleDateString(date)}
                      </td>
                      <td>{formatNumberLocale(sum, 0)}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </>
        )}
      </Card.Body>
    </Card>
  );
};

interface PrimaryParameterChartProps {
  flow: GetStationDashboardData_flow;
  datapoints: GetDatasourceData_source_data[];
  startDate: Date;
  endDate: Date;
}
const PrimaryParameterChart: React.FC<PrimaryParameterChartProps> = ({
  flow,
  datapoints,
  startDate,
  endDate,
}) => {
  return (
    <Card className="DataSourceCard">
      <Card.Header>
        <h5>
          {flow.name} [{flow.unit?.symbol}]
        </h5>
      </Card.Header>
      <Card.Body>
        <DataChart
          source={flow}
          datapoints={datapoints}
          startDate={startDate}
          endDate={endDate}
        />
      </Card.Body>
    </Card>
  );
};

interface IframeWidgetProps {
  title: string;
  sourceUrl: string;
  aspectWidth: number;
  aspectHeight: number;
}
const IframeWidget: React.FC<IframeWidgetProps> = ({
  title,
  sourceUrl,
  aspectWidth,
  aspectHeight,
}) => {
  const aspectRatio = (aspectHeight * 100) / aspectWidth;

  return (
    <Card className="DataSourceCard">
      <Card.Header>
        <h5>{gettext(title)}</h5>
      </Card.Header>
      <Card.Body
        className="IframeBody"
        style={{
          paddingTop: `${aspectRatio}%`,
        }}
      >
        <iframe title={title} src={sourceUrl}></iframe>
      </Card.Body>
    </Card>
  );
};

interface StationDashboardProps {
  stationIdentifier: string;
  stationId: number;
  sourceId: number;
  sourceType: string;
  startDate: Date;
  endDate: Date;
  intervalChoices: [string, number][];
  interval?: IntervalUnit;
  editedStructure: any[] | null;
  setEditedStructure: React.Dispatch<React.SetStateAction<any[] | null>>;
  setChangesMade: React.Dispatch<React.SetStateAction<boolean>>;
  canEditWidgets?: boolean;
}
const StationDashboard: React.FC<StationDashboardProps> = ({
  stationIdentifier,
  stationId,
  sourceId,
  sourceType,
  startDate,
  endDate,
  interval,
  intervalChoices,
  editedStructure,
  setEditedStructure,
  setChangesMade,
  canEditWidgets = false,
}: StationDashboardProps) => {
  const [draggingWidget, setDraggingWidget] = React.useState<any>(null);
  const { setActiveModal, setModalPayload } = useModalContext();
  const showAddWidget = React.useMemo(
    () => (e: any) => {
      setActiveModal("add-dashboard-widget");
      setModalPayload({
        stationIdentifier,
      });
    },
    [setActiveModal, setModalPayload, stationIdentifier]
  );

  const stationIdx = editedStructure
    ?.map((structureRow: any) => structureRow.station.identifier)
    .indexOf(stationIdentifier);
  const stationStructure = editedStructure?.find(
    (structureRow: any) => structureRow.station.identifier === stationIdentifier
  );
  const stationWidgetRows = stationStructure?.station?.widget_rows ?? null;

  const { data } = useCachedSubscription<
    GetStationDashboardData,
    GetStationDashboardDataVariables
  >(GET_STATION_DASHBOARD_DATA, {
    variables: {
      startDate,
      endDate,
      sourceType,
      sourceId,
    },
  });

  const AVAILABLE_WIDGETS: AvailableWidget[] = React.useMemo(() => {
    if (!data) {
      return [];
    }
    const flow = data.flow!;
    const datapoints = data.flow!.data ?? [];

    const dailyAverages: { [key: string]: number } = {};
    const dailyDatapoints: { [key: string]: number } = {};
    const dailyMins: { [key: string]: number } = {};
    const dailyMaxs: { [key: string]: number } = {};
    datapoints.forEach((datapoint) => {
      const date = new Date(datapoint.timestamp);
      const endDateMidnight = new Date(endDate.getTime());
      const startDateMidnight = new Date(startDate.getTime());

      endDateMidnight.setHours(0, 0, 0, 0);
      endDateMidnight.setDate(endDateMidnight.getDate() + 1);
      startDateMidnight.setHours(0, 0, 0, 0);

      if (date < startDateMidnight || date > endDateMidnight) {
        return;
      }

      const dateKey = `${date.getFullYear()}-${
        date.getMonth() + 1
      }-${date.getDate()}`;

      if (!dailyAverages[dateKey]) {
        dailyAverages[dateKey] = 0;
        dailyMins[dateKey] = Infinity;
        dailyMaxs[dateKey] = -Infinity;
      }
      dailyAverages[dateKey] += datapoint.value;
      dailyDatapoints[dateKey] = (dailyDatapoints[dateKey] ?? 0) + 1;
      dailyMins[dateKey] = Math.min(dailyMins[dateKey], datapoint.value);
      dailyMaxs[dateKey] = Math.max(dailyMaxs[dateKey], datapoint.value);
    });
    Object.keys(dailyAverages).forEach((dateKey) => {
      dailyAverages[dateKey] /= dailyDatapoints[dateKey];
    });

    let dailyAverageKeys = Object.keys(dailyAverages);
    // sort daily averages by date, latest at the top
    dailyAverageKeys.sort((a, b) => {
      const dateA = new Date(a);
      const dateB = new Date(b);
      return dateB.getTime() - dateA.getTime();
    });

    return [
      {
        key: "iframe_widget",
        component: (sourceUUID: string | undefined, metaData: any = {}) => (
          <IframeWidget
            title={metaData.title}
            sourceUrl={metaData.sourceUrl}
            aspectWidth={metaData.aspectWidth}
            aspectHeight={metaData.aspectHeight}
          />
        ),
      },
      {
        key: "primary_parameter_chart",
        component: () => (
          <PrimaryParameterChart
            flow={flow}
            datapoints={datapoints}
            startDate={startDate}
            endDate={endDate}
          />
        ),
      },
      {
        key: "data_source_chart",
        component: (sourceUUID: string | undefined, metaData: any = {}) => {
          return (
            <MultiSourceChart
              startDate={startDate}
              endDate={endDate}
              title={metaData.title}
              sourceUUIDs={metaData.sourceUUIDs}
            />
          );
        },
      },
      {
        key: "stats_summary_widget",
        component: () => (
          <StatsSummaryWidget
            flow={flow}
            datapoints={datapoints}
            endDate={endDate}
          />
        ),
      },
      {
        key: "primary_parameter_average_chart",
        component: () => (
          <PrimaryParameterAverageChart
            flow={flow}
            startDate={startDate}
            endDate={endDate}
            dailyAverages={dailyAverages}
            dailyMins={dailyMins}
            dailyMaxs={dailyMaxs}
          />
        ),
      },
      {
        key: "daily_averages_table",
        component: () => (
          <DailyAveragesTable
            dailyAverageKeys={dailyAverageKeys}
            dailyAverages={dailyAverages}
            dailyMins={dailyMins}
            dailyMaxs={dailyMaxs}
          />
        ),
      },
      {
        key: "temperature_profile_widget",
        component: (sourceUUID: string | undefined, metaData: any = {}) => (
          <TemperatureProfileWidget
            stationId={stationId}
            startDate={startDate}
            endDate={endDate}
            metaData={metaData}
          />
        ),
      },
      {
        key: "total_daily_discharge_table",
        component: (sourceUUID: string | undefined, metaData: any = {}) => (
          <TotalDischargeTable
            startDate={startDate}
            endDate={endDate}
            title={metaData.title}
            displayUnit={metaData.displayUnit}
            sourceUUIDs={metaData.sourceUUIDs}
          />
        ),
      },
      {
        key: "total_monthly_discharge_table",
        component: (sourceUUID: string | undefined, metaData: any = {}) => (
          <TotalDischargeTable
            monthly
            startDate={startDate}
            endDate={endDate}
            title={metaData.title}
            displayUnit={metaData.displayUnit}
            sourceUUIDs={metaData.sourceUUIDs}
          />
        ),
      },
    ];
  }, [data, startDate, endDate, stationId]);

  if (stationWidgetRows === null) {
    return <></>;
  }

  if (!data) {
    return (
      <Card>
        <Card.Body>
          <Spinner animation="border" />
        </Card.Body>
      </Card>
    );
  }

  function allowDrop(e: any) {
    e.preventDefault();
  }

  function dragWidget(
    e: any,
    widgetIdx: number,
    widgetGroupIdx: number,
    widget: any
  ) {
    if (!canEditWidgets) {
      return;
    }
    setDraggingWidget({
      widgetIdx,
      widgetGroupIdx,
      widget,
    });
  }

  const dropWidget = (e: any, widgetIdx: number, widgetGroupIdx: number) => {
    e.preventDefault();

    if (!draggingWidget) {
      return;
    }

    const {
      widget,
      widgetIdx: oldWidgetIdx,
      widgetGroupIdx: oldWidgetGroupIdx,
    } = draggingWidget;

    setDraggingWidget(null);
    if (oldWidgetGroupIdx === widgetGroupIdx && oldWidgetIdx === widgetIdx) {
      return;
    }

    // Insert widget at the new position (widgetIdx, widgetGroupIdx)
    // and then remove the old widget from its original position

    // Insert widget
    const newGroupWidgets = stationWidgetRows?.[widgetGroupIdx]?.widgets ?? [];
    const newWidgetGroup = insertIntoList(
      newGroupWidgets,
      widgetIdx,
      widget,
      false
    );

    let modIdx = 0;
    if (oldWidgetGroupIdx === widgetGroupIdx) {
      modIdx = 1;
      if (oldWidgetIdx < widgetIdx) {
        modIdx = -1;
      }
      if (modIdx < 0) {
        modIdx = 0;
      }
    }
    let newStructure = insertIntoList(
      stationWidgetRows,
      widgetGroupIdx,
      {
        widgets: newWidgetGroup.filter((_: any, idx: number) => {
          if (oldWidgetGroupIdx !== widgetGroupIdx) {
            return true;
          }
          return idx !== oldWidgetIdx + modIdx;
        }),
      },
      true
    );

    if (oldWidgetGroupIdx !== widgetGroupIdx) {
      const oldWidgetGroup = newStructure?.[oldWidgetGroupIdx]?.widgets;
      const oldWidgetGroupWithoutWidget = oldWidgetGroup.filter(
        (_: any, idx: number) => idx !== oldWidgetIdx
      );
      newStructure = insertIntoList(
        newStructure,
        oldWidgetGroupIdx,
        {
          widgets: oldWidgetGroupWithoutWidget,
        },
        true
      );
    }

    const filteredStructure = insertIntoList(
      editedStructure,
      stationIdx,
      {
        hiddenTitle: stationStructure?.hiddenTitle,
        station: {
          ...stationStructure?.station,
          widget_rows: newStructure,
        },
      },
      true
    );

    setEditedStructure(filteredStructure);
    setChangesMade(true);
  };

  const onDeleteWidget = (idx: number, widgetGroupIdx: number) => {
    const newGroupWidgets = stationWidgetRows?.[widgetGroupIdx]?.widgets ?? [];
    const newWidgetGroup = newGroupWidgets.filter(
      (_: any, widgetIdx: number) => widgetIdx !== idx
    );
    const newStructure = insertIntoList(
      stationWidgetRows,
      widgetGroupIdx,
      {
        widgets: newWidgetGroup,
      },
      true
    );

    const filteredStructure = insertIntoList(
      editedStructure,
      stationIdx,
      {
        station: {
          ...stationStructure?.station,
          widget_rows: newStructure,
        },
      },
      true
    );
    setEditedStructure(filteredStructure);
    setChangesMade(true);
  };

  let visibleWidgets =
    stationWidgetRows
      ?.filter((visibleWidget: any) => !visibleWidget.hidden)
      .map((visibleWidget: any, widgetGroupIdx: number) => {
        const widgets = visibleWidget.widgets;

        const widgetRender: RenderWidget[] =
          (widgets as any)
            ?.filter((widgetParams: AvailableWidget) => {
              const widgetKey = widgetParams.key;
              const widget = AVAILABLE_WIDGETS.find(
                (widget) => widget.key === widgetKey
              );
              return !!widget;
            })
            .map((widgetParams: WidgetGroupItem) => {
              const found = AVAILABLE_WIDGETS.find(
                (widget) => widget.key === widgetParams.key
              );
              return {
                ...found,
                sourceUUID: widgetParams.sourceUUID,
                metaData: widgetParams.metaData,
              };
            }) ?? [];
        if (!widgetRender.length) {
          return (
            <React.Fragment
              key={`blank_widget_${widgetGroupIdx}`}
            ></React.Fragment>
          );
        }

        return (
          <Row key={`widget_group_${widgetGroupIdx}`} className="g-3 mb-3">
            {widgetRender.map((widget, idx) => {
              const widgetUUIDs = widget.metaData?.sourceUUIDs?.join(",");
              const widgetUUID =
                widget.sourceUUID ??
                widget.metaData?.sourceUUID ??
                widgetUUIDs ??
                0;
              return (
                <Col
                  key={`widget_column_${widget.key}_${idx}_${widgetUUID}`}
                  draggable={canEditWidgets}
                  onDragStart={(e: any) =>
                    dragWidget(e, idx, widgetGroupIdx, widget)
                  }
                  onDrop={(e: any) => dropWidget(e, idx, widgetGroupIdx)}
                  onDragOver={allowDrop}
                  className="widget-column"
                >
                  {canEditWidgets && (
                    <div
                      className="delete-widget-button"
                      onClick={() => onDeleteWidget(idx, widgetGroupIdx)}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </div>
                  )}
                  {widget.component?.(
                    widget.sourceUUID ?? widget.metaData?.sourceUUID,
                    widget.metaData
                  )}
                </Col>
              );
            })}
          </Row>
        );
      }) ?? [];

  const NewWidgetButton = canEditWidgets ? (
    <Row key="add_new_widget" className="g-3 mb-3 row-add-widget">
      <Col>
        <Card>
          <Card.Body className="add-widget-button" onClick={showAddWidget}>
            <h5>+ Add widget</h5>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  ) : (
    <> </>
  );

  if (!visibleWidgets.length) {
    return (
      <>
        <Row className="g-3 mb-3">
          <Col>
            <Card>
              <Card.Body>
                <p>No widgets available</p>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        {NewWidgetButton}
      </>
    );
  }

  return (
    <div>
      {visibleWidgets}
      {!!draggingWidget && (
        <Row key="widget_group_empty" className="g-3 mb-3">
          <Col
            onDrop={(e: any) => dropWidget(e, 0, visibleWidgets.length)}
            onDragOver={allowDrop}
          >
            <Card>
              <Card.Body>
                <b>+ New row</b>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}

      {NewWidgetButton}
    </div>
  );
};

interface DashboardNumberProps {
  value: number;
  unit?: string | undefined;
  precision?: number;
}
const DashboardNumber = (props: DashboardNumberProps) => {
  const { value, unit, precision = 5 } = props;
  return (
    <span>
      <span style={{ fontSize: "1.76rem", fontWeight: "bold" }}>
        {formatNumberSafe(value, precision)}
      </span>
      {!!unit && (
        <span
          style={{ fontSize: "1.3rem", fontWeight: "bold", paddingLeft: "8px" }}
        >
          {unit}
        </span>
      )}
    </span>
  );
};

export default StationDashboard;
