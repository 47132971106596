import { gql, useQuery } from "@apollo/client";
import React, { useState } from "react";
import dayjs from "dayjs";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Spinner from "react-bootstrap/Spinner";
import DatePicker from "react-datepicker";
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";
import { toast } from "react-toastify";
import DefaultTooltip from "../common/DefaultTooltip";
import { GetDatasourceAnnotations_datasource_datasource_annotation } from "./__generated__/GetDatasourceAnnotations";
import apiClient from "../../api";
import { DataPoint, defaultAnnotationColor } from "../../utils/datacharts";
import FormattedFormControl from "components/form/FormattedFormControl";
import FormControl from "react-bootstrap/FormControl";
import { useModalContext } from "state/modal";
import { gettext } from "../../i18n";
import "./ChartAnnotationForm.scss";

const GET_DATASOURCE_ANNOTATIONS = gql`
  query GetDatasourceAnnotations($sourceUUID: uuid) {
    datasource(where: { source_uuid: { _eq: $sourceUUID } }) {
      station_id
      source_id
      type
      datasource_annotation {
        id
        source_uuid
        text
        timestamp
        value
        color
      }
    }
  }
`;

interface AnnotationRowProps {
  annotation: GetDatasourceAnnotations_datasource_datasource_annotation;
  editedAnnotation?: GetDatasourceAnnotations_datasource_datasource_annotation;
  setEditedAnnotation: (
    annotation:
      | GetDatasourceAnnotations_datasource_datasource_annotation
      | undefined
  ) => void;
  onSaveEditedAnnotation: () => void;
  deleteAnnotation: (id: number) => void;
  isTimestamp?: boolean;
  isAdmin?: boolean;
}
const AnnotationRow: React.FC<AnnotationRowProps> = ({
  annotation,
  editedAnnotation,
  setEditedAnnotation,
  onSaveEditedAnnotation,
  deleteAnnotation,
  isTimestamp = false,
  isAdmin = false,
}: AnnotationRowProps) => (
  <tr>
    {editedAnnotation?.id === annotation.id ? (
      <>
        <td>
          {isTimestamp ? (
            <DatePicker
              selected={
                editedAnnotation?.timestamp
                  ? new Date(editedAnnotation?.timestamp)
                  : null
              }
              showTimeSelect
              onChange={(d) => {
                if (!d) {
                  return;
                }
                setEditedAnnotation({
                  ...editedAnnotation,
                  timestamp: d,
                });
              }}
              dateFormat="dd.MM.yyyy HH:mm"
              className="form-control my-1"
              locale="fi"
            />
          ) : (
            <FormattedFormControl
              type="number"
              value={editedAnnotation.value}
              onChange={(e) =>
                setEditedAnnotation({
                  ...editedAnnotation,
                  value: e.target.value,
                })
              }
            />
          )}
        </td>
        <td>
          <FormControl
            type="text"
            value={editedAnnotation.text}
            onChange={(e) =>
              setEditedAnnotation({
                ...editedAnnotation,
                text: e.target.value,
              })
            }
          />
        </td>
        <td>
          <FormControl
            type="color"
            value={editedAnnotation.color ?? "#c89833"}
            onChange={(e) =>
              setEditedAnnotation({
                ...editedAnnotation,
                color: e.target.value,
              })
            }
          />
        </td>
      </>
    ) : (
      <>
        <td>
          {isTimestamp
            ? dayjs(annotation.timestamp).format("DD.MM.YYYY HH:mm")
            : annotation.value}
        </td>
        <td>{annotation.text}</td>
        <td>
          <div className="colorPreview">
            <div
              className="box"
              style={{
                backgroundColor: annotation.color || defaultAnnotationColor,
              }}
            ></div>
            {annotation.color || gettext("Default")}
          </div>
        </td>
      </>
    )}

    <td className="alignRight">
      {editedAnnotation?.id === annotation.id ? (
        <>
          <Button size="sm" onClick={onSaveEditedAnnotation}>
            Save
          </Button>
          <Button size="sm" onClick={() => setEditedAnnotation(undefined)}>
            Cancel
          </Button>
        </>
      ) : (
        <>
          <Button
            size="sm"
            onClick={() => setEditedAnnotation({ ...annotation })}
          >
            Edit
          </Button>
        </>
      )}
      {isAdmin && (
        <Button
          variant="danger"
          size="sm"
          onClick={() => {
            deleteAnnotation(annotation.id);
          }}
        >
          Delete
        </Button>
      )}
    </td>
  </tr>
);

const DEFAULT_ANNOTATION_VALUES = { value: 0 };

interface ChartAnnotationFormProps {
  stationSourceUUIDs: string[];
  sourceUUID: string;
}

const ChartAnnotationForm = ({
  stationSourceUUIDs,
  sourceUUID,
}: ChartAnnotationFormProps) => {
  const {
    data,
    refetch: refetchAnnotations,
    loading,
  } = useQuery(GET_DATASOURCE_ANNOTATIONS, {
    variables: {
      sourceUUID,
    },
  });
  const { setActiveModal, modalPayload } = useModalContext();
  const { isAdmin, datapoint } = modalPayload ?? {};

  const onHide = () => setActiveModal(null);
  const [annotationValues, setAnnotationValues] = React.useState<
    Record<string, any>
  >(DEFAULT_ANNOTATION_VALUES);
  const [modalDisableSave, setModalDisableSave] =
    React.useState<boolean>(false);
  const [editedAnnotation, setEditedAnnotation] =
    useState<GetDatasourceAnnotations_datasource_datasource_annotation>();

  const [applyToAllStations, setApplyToAllStations] = React.useState(false);

  const closeModal = () => {
    setAnnotationValues(DEFAULT_ANNOTATION_VALUES);
    setModalDisableSave(true);
    onHide();
  };

  const saveAnnotation = () => {
    if (!annotationValues) {
      return;
    }
    setModalDisableSave(true);
    const values = { ...annotationValues };
    const requestPath = "/organization-member/source/annotate";

    apiClient
      .request(requestPath, {
        method: "POST",
        data: {
          stationId: data.datasource?.[0].station_id,
          source_uuid: applyToAllStations ? stationSourceUUIDs : [sourceUUID],
          ...(!!datapoint && {
            timestamp: datapoint.timestamp,
            value: datapoint.value,
          }),
          ...values,
        },
      })
      .then(() => {
        toast.success(gettext("Success"));
        refetchAnnotations();
        setAnnotationValues(DEFAULT_ANNOTATION_VALUES);
        setModalDisableSave(false);
      })
      .catch((e) => {
        toast.error(gettext(e.message.toString()));
        setModalDisableSave(false);
      });
  };

  const deleteAnnotation = (id: number) => {
    if (
      !window.confirm(
        gettext("Are you sure you want to delete this annotation?")
      )
    ) {
      return;
    }
    setModalDisableSave(true);
    const requestPath = `/organization-admin/source/annotate/${id}`;
    apiClient
      .request(requestPath, {
        method: "DELETE",
        data: {
          stationId: data.datasource?.[0].station_id,
        },
      })
      .then(() => {
        toast.success(gettext("Success"));
        refetchAnnotations();
        setModalDisableSave(false);
      })
      .catch((e) => {
        toast.error(gettext(e.message.toString()));
        setModalDisableSave(false);
      });
  };

  const setAnnotationValue = (key: string, value: any) => {
    setAnnotationValues({
      ...annotationValues,
      [key]: value,
    });
  };

  const onSaveEditedAnnotation = async () => {
    if (!editedAnnotation) {
      return;
    }
    try {
      await apiClient.request("/organization-member/source/annotate/edit", {
        method: "POST",
        data: {
          stationId: data.datasource?.[0].station_id,
          ...editedAnnotation,
        },
      });
      toast.success(gettext("Annotation updated successfully"));
      setEditedAnnotation(undefined);
      refetchAnnotations();
    } catch (error) {
      toast.error(gettext("Failed to update annotation"));
    }
  };

  if (loading) {
    return <Spinner animation="border" size="sm" />;
  }

  const datasource = data.datasource?.[0];
  if (!datasource) {
    return <div>No data available</div>;
  }

  return (
    <Modal
      show
      onHide={closeModal}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Add Annotation
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table responsive>
          <tbody>
            {!!datapoint && (
              <>
                <tr>
                  <th>Timestamp</th>
                  <td colSpan={2}>
                    {dayjs(datapoint.timestamp).format("DD.MM.YYYY HH:mm")}
                  </td>
                </tr>
                <tr>
                  <th>Value</th>
                  <td colSpan={2}>{datapoint.value}</td>
                </tr>
              </>
            )}
            <tr>
              <th>
                <Row>
                  <Col>Type</Col>
                  <Col>
                    <DefaultTooltip
                      popHeader="Annotation type"
                      popBody={
                        <>
                          <p>
                            Timestamp annotations render a vertical line, while
                            value-based annotations produce a horizontal line on
                            the chart.
                          </p>
                        </>
                      }
                    />
                  </Col>
                </Row>
              </th>
              <td>
                <ToggleButtonGroup
                  id="measurement-group"
                  name="measurement-group"
                  type="radio"
                  value={annotationValues?.type ?? 0}
                  onChange={(value) => {
                    setAnnotationValue("type", value);
                  }}
                >
                  <ToggleButton
                    size="sm"
                    id={`annotation-type-value`}
                    variant="outline-primary"
                    value={1}
                  >
                    Value
                  </ToggleButton>
                  <ToggleButton
                    size="sm"
                    id={`annotation-type-timestamp`}
                    variant="outline-primary"
                    value={0}
                  >
                    Timestamp
                  </ToggleButton>
                </ToggleButtonGroup>
              </td>
            </tr>
            {datapoint === null && (
              <tr>
                <th>
                  <Row>
                    <Col>
                      {annotationValues?.type
                        ? gettext("Value")
                        : gettext("Timestamp")}
                    </Col>
                    <Col>
                      <DefaultTooltip
                        popHeader={
                          annotationValues?.type
                            ? gettext("Value")
                            : gettext("Timestamp")
                        }
                        popBody={
                          <>
                            <p>
                              Enter the{" "}
                              {(annotationValues?.type
                                ? gettext("Value")
                                : gettext("Timestamp")
                              ).toLocaleLowerCase()}{" "}
                              identifying location of the annotation line
                            </p>
                          </>
                        }
                      />
                    </Col>
                  </Row>
                </th>
                <td>
                  <Form.Group>
                    {!!annotationValues?.type && (
                      <FormattedFormControl
                        type="number"
                        value={annotationValues?.value ?? ""}
                        onChange={(e) =>
                          setAnnotationValue("value", e.target.value)
                        }
                      />
                    )}
                  </Form.Group>

                  {!annotationValues?.type && (
                    <DatePicker
                      selected={
                        annotationValues?.timestamp
                          ? new Date(annotationValues?.timestamp)
                          : null
                      }
                      showTimeSelect
                      onChange={(d) => {
                        if (!d) {
                          return;
                        }
                        setAnnotationValue("timestamp", d);
                      }}
                      dateFormat="dd.MM.yyyy HH:mm"
                      className="form-control my-1"
                      locale="fi"
                    />
                  )}
                </td>
              </tr>
            )}
            <tr>
              <th>
                <Row>
                  <Col>Description</Col>
                  <Col>
                    <DefaultTooltip
                      popHeader="Description"
                      popBody={
                        <>
                          <p>
                            Enter the annotation text that will appear when
                            hovering over the chart line.
                          </p>
                        </>
                      }
                    />
                  </Col>
                </Row>
              </th>
              <td>
                <Form.Group>
                  <Form.Control
                    value={annotationValues?.text ?? ""}
                    placeholder={gettext("Enter annotation text")}
                    onChange={(e) => setAnnotationValue("text", e.target.value)}
                  />
                </Form.Group>
              </td>
            </tr>
            <tr>
              <th>
                <Row>
                  <Col>Line Color</Col>
                </Row>
              </th>
              <td>
                <Form.Group>
                  <Form.Control
                    type="color"
                    value={annotationValues?.color ?? "#c89833"}
                    onChange={(e) =>
                      setAnnotationValue("color", e.target.value)
                    }
                  />
                </Form.Group>
              </td>
            </tr>
            <tr>
              <th>
                <Row>
                  <Col>Add to all parameters in this station</Col>
                  <Col>
                    <DefaultTooltip
                      popBody={
                        <>
                          <p>
                            If you choose this option, the annotation is copied
                            to all parameters shown at this station
                          </p>
                        </>
                      }
                    />
                  </Col>
                </Row>
              </th>
              <td>
                <Form.Check
                  checked={applyToAllStations}
                  onChange={(e) => {
                    setApplyToAllStations(e.target.checked);
                  }}
                />
              </td>
            </tr>
          </tbody>
        </Table>
        {!!datasource?.datasource_annotation?.length && (
          <div className="annotationTables">
            {!!datasource?.datasource_annotation?.filter(
              (
                annotation: GetDatasourceAnnotations_datasource_datasource_annotation
              ) => !!annotation.timestamp
            ).length && (
              <Table responsive>
                <thead>
                  <tr>
                    <th colSpan={5}>Existing Timestamp Annotations</th>
                  </tr>
                  <tr>
                    <th>Timestamp</th>
                    <th>Description</th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {datasource?.datasource_annotation
                    ?.filter(
                      (
                        annotation: GetDatasourceAnnotations_datasource_datasource_annotation
                      ) => !!annotation.timestamp
                    )
                    .map(
                      (
                        annotation: GetDatasourceAnnotations_datasource_datasource_annotation
                      ) => (
                        <AnnotationRow
                          isTimestamp
                          key={annotation.id}
                          isAdmin={isAdmin}
                          annotation={annotation}
                          deleteAnnotation={deleteAnnotation}
                          editedAnnotation={editedAnnotation}
                          setEditedAnnotation={setEditedAnnotation}
                          onSaveEditedAnnotation={onSaveEditedAnnotation}
                        />
                      )
                    )}
                </tbody>
              </Table>
            )}

            {!!datasource?.datasource_annotation?.filter(
              (
                annotation: GetDatasourceAnnotations_datasource_datasource_annotation
              ) => !annotation.timestamp
            ).length && (
              <Table responsive>
                <thead>
                  <tr>
                    <th colSpan={5}>Existing Value Annotations</th>
                  </tr>
                  <tr>
                    <th>Value</th>
                    <th>Description</th>
                    <th>Color</th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {datasource?.datasource_annotation
                    ?.filter(
                      (
                        annotation: GetDatasourceAnnotations_datasource_datasource_annotation
                      ) => !annotation.timestamp
                    )
                    .map(
                      (
                        annotation: GetDatasourceAnnotations_datasource_datasource_annotation
                      ) => (
                        <AnnotationRow
                          key={annotation.id}
                          isAdmin={isAdmin}
                          annotation={annotation}
                          deleteAnnotation={deleteAnnotation}
                          editedAnnotation={editedAnnotation}
                          setEditedAnnotation={setEditedAnnotation}
                          onSaveEditedAnnotation={onSaveEditedAnnotation}
                        />
                      )
                    )}
                </tbody>
              </Table>
            )}
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => {
            setAnnotationValues(DEFAULT_ANNOTATION_VALUES);
            onHide();
          }}
        >
          Close
        </Button>

        {/* <Button type="button" variant="danger">
          Delete
        </Button> */}

        <Button
          type="button"
          onClick={saveAnnotation}
          disabled={modalDisableSave}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ChartAnnotationForm;
