import React from "react";
import Flex from "theme/components/common/Flex";
import PasswordRecoveryForm from "../components/auth/PasswordRecoveryForm";

const PasswordRecovery = () => (
  <>
    <Flex justifyContent="between" alignItems="center" className="mb-2">
      <h5>Password Recovery</h5>
    </Flex>
    <PasswordRecoveryForm />
  </>
);

export default PasswordRecovery;
