import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import { toast } from "react-toastify";
import { useModalContext } from "state/modal";
import { gettext } from "i18n";
import { StationPicker } from "utils/widgets";

interface AddDashboardStationFormProps {
  editedStructure: any[] | null;
  setEditedStructure: React.Dispatch<React.SetStateAction<any[] | null>>;
  onSaveEditedStructure: (e: any, newStructure: any[] | null) => Promise<void>;
}

const AddDashboardStationForm = ({
  editedStructure,
  setEditedStructure,
  onSaveEditedStructure,
}: AddDashboardStationFormProps) => {
  const { setActiveModal } = useModalContext();
  const onHide = () => setActiveModal(null);
  const [modalDisableSave, setModalDisableSave] =
    React.useState<boolean>(false);
  const [stationIdentifier, setStationIdentifier] = React.useState<string>("");

  const closeModal = () => {
    setModalDisableSave(true);
    onHide();
  };

  const saveEditedStructure = async (e: any) => {
    if (!stationIdentifier) {
      toast.error(gettext("Please select a station"));
      return;
    }

    // Add a new station structure to editedStructure JSON list
    const newStationStructure = {
      station: {
        identifier: stationIdentifier,
        widget_rows: [],
      },
    };
    const newEditedStructure = editedStructure
      ? [...editedStructure, newStationStructure]
      : [newStationStructure];
    setEditedStructure(newEditedStructure);
    // Save instantly
    onSaveEditedStructure(e, newEditedStructure);
    closeModal();
  };

  const existingStationIdentifiers =
    editedStructure?.map((s) => s.station.identifier) ?? [];

  return (
    <Modal
      show
      onHide={closeModal}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Add Station
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table responsive>
          <tbody>
            <tr>
              <th>{gettext("Source Station")}</th>
              <td>
                <StationPicker
                  byIdentifier
                  onChange={(e: any) => {
                    setStationIdentifier(e.target.value);
                  }}
                  value={stationIdentifier}
                  filter={(station: any) =>
                    !existingStationIdentifiers.includes(station.identifier)
                  }
                />
              </td>
            </tr>
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => {
            onHide();
          }}
        >
          Close
        </Button>
        <Button
          type="button"
          onClick={saveEditedStructure}
          disabled={modalDisableSave}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddDashboardStationForm;
