import { useQuery } from "@apollo/client";
import PageHeader from "theme/components/common/PageHeader";
import * as React from "react";
import Card from "react-bootstrap/Card";
import { toast } from "react-toastify";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import apiClient from "../../api";
import { GetMapLayersQuery } from "utils/__generated__/GetMapLayersQuery";
import { GET_MAP_LAYERS } from "utils/stations";
import { getFormValueHandler } from "../../utils/forms";
import { gettext } from "../../i18n";
import FormControl from "react-bootstrap/FormControl";
import "./MapLayerEditPage.scss";

interface EditableMapLayer {
  id: number;
  system_name: string;
  display_name: string;
  attribution: string;
  url: string;
  min_zoom: number;
  max_zoom: number;
}
const MapLayerEditPage = () => {
  const [editedValues, setEditedValues] = React.useState<EditableMapLayer>();
  const [addingLayer, setAddingLayer] = React.useState<EditableMapLayer>();
  const { data: layerQuery, refetch: refetchLayers } =
    useQuery<GetMapLayersQuery>(GET_MAP_LAYERS);

  const setValueHandler = getFormValueHandler(
    editedValues as Record<string, any>,
    setEditedValues as (
      value: React.SetStateAction<Record<string, any>>
    ) => void
  );

  const onUpdateMapLayer = async (
    {
      id,
      display_name,
      system_name,
      attribution,
      url,
      min_zoom,
      max_zoom,
    }: EditableMapLayer,
    action: string = "edit"
  ) => {
    setEditedValues(undefined);

    let requestPath = "";
    switch (action) {
      case "edit":
        requestPath = `/admin/edit-map-layer`;
        break;
      case "delete":
        requestPath = `/admin/delete-map-layer`;
        break;
      case "add":
        requestPath = `/admin/add-map-layer`;
        break;
      default:
        throw new Error("Invalid action");
    }

    try {
      await apiClient.request(requestPath, {
        method: "POST",
        data: {
          id,
          display_name,
          system_name,
          attribution,
          url,
          min_zoom,
          max_zoom,
        },
      });
      toast.success("Success");
      refetchLayers();
      return true;
    } catch (e) {
      toast.error(gettext((e as any).message.toString()));
    }
    return false;
  };

  const onSaveEdited = () =>
    onUpdateMapLayer(editedValues as EditableMapLayer, "edit");

  const onSaveNewMapLayer = async () => {
    const result = await onUpdateMapLayer(
      addingLayer as EditableMapLayer,
      "add"
    );
    if (result) {
      setAddingLayer(undefined);
    }
  };

  const onDeleteUnit = (id: number) => {
    if (
      !window.confirm(
        gettext("Are you sure you want to delete this map layer?")
      )
    ) {
      return;
    }
    onUpdateMapLayer({ id } as EditableMapLayer, "delete");
  };

  const mapLayers = layerQuery?.map_layer ? [...layerQuery?.map_layer] : [];
  mapLayers.sort((a, b) => (a.id > b.id ? 1 : -1));
  return (
    <div className="MapLayerEditPage">
      <PageHeader title="Edit Map Layers" className="mb-3"></PageHeader>
      <Card className="mb-3">
        <Card.Body>
          <Table responsive>
            <thead>
              <tr>
                <th>System name</th>
                <th>Display name</th>
                <th>URL</th>
                <th>Attribution</th>
                <th>Min zoom</th>
                <th>Max zoom</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {mapLayers.map((mapLayer) => (
                <tr key={mapLayer.id}>
                  {editedValues?.id === mapLayer.id ? (
                    <>
                      <td>
                        <FormControl
                          type="text"
                          placeholder="Enter system name"
                          value={editedValues.system_name}
                          onChange={setValueHandler("system_name")}
                        />
                      </td>
                      <td>
                        <FormControl
                          type="text"
                          placeholder="Enter display name"
                          value={editedValues.display_name}
                          onChange={setValueHandler("display_name")}
                        />
                      </td>
                      <td>
                        <FormControl
                          type="text"
                          placeholder="Enter URL"
                          value={editedValues.url}
                          onChange={setValueHandler("url")}
                        />
                      </td>
                      <td>
                        <FormControl
                          type="text"
                          placeholder="Enter attribution"
                          value={editedValues.attribution}
                          onChange={setValueHandler("attribution")}
                        />
                      </td>
                      <td className="numericColumn">
                        <FormControl
                          type="number"
                          placeholder="Enter min zoom"
                          value={editedValues.min_zoom}
                          onChange={setValueHandler("min_zoom")}
                        />
                      </td>
                      <td className="numericColumn">
                        <FormControl
                          type="number"
                          placeholder="Enter max zoom"
                          value={editedValues.max_zoom}
                          onChange={setValueHandler("max_zoom")}
                        />
                      </td>
                    </>
                  ) : (
                    <>
                      <td>{mapLayer.system_name}</td>
                      <td>{mapLayer.display_name}</td>
                      <td className="clippedColumn">{mapLayer.url}</td>
                      <td className="clippedColumn">{mapLayer.attribution}</td>
                      <td>{mapLayer.min_zoom}</td>
                      <td>{mapLayer.max_zoom}</td>
                    </>
                  )}

                  <td className="actionButtons">
                    {editedValues?.id === mapLayer.id ? (
                      <>
                        <Button size="sm" onClick={onSaveEdited}>
                          Save
                        </Button>
                        <Button
                          size="sm"
                          onClick={() => {
                            setEditedValues(undefined);
                          }}
                        >
                          Cancel
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button
                          size="sm"
                          onClick={() => {
                            setEditedValues({
                              ...mapLayer,
                            });
                          }}
                        >
                          Edit
                        </Button>
                        <Button
                          variant="danger"
                          size="sm"
                          onClick={() => onDeleteUnit(mapLayer.id)}
                        >
                          Delete
                        </Button>
                      </>
                    )}
                  </td>
                </tr>
              ))}
              <tr>
                {addingLayer && (
                  <>
                    <td>
                      <FormControl
                        type="text"
                        placeholder="Enter system name"
                        value={addingLayer.system_name}
                        onChange={(e) => {
                          setAddingLayer({
                            ...addingLayer,
                            system_name: e.target.value,
                          });
                        }}
                      />
                    </td>
                    <td>
                      <FormControl
                        type="text"
                        placeholder="Enter display name"
                        value={addingLayer.display_name}
                        onChange={(e) => {
                          setAddingLayer({
                            ...addingLayer,
                            display_name: e.target.value,
                          });
                        }}
                      />
                    </td>
                    <td>
                      <FormControl
                        type="text"
                        placeholder="Enter URL"
                        value={addingLayer.url}
                        onChange={(e) => {
                          setAddingLayer({
                            ...addingLayer,
                            url: e.target.value,
                          });
                        }}
                      />
                    </td>
                    <td>
                      <FormControl
                        type="text"
                        placeholder="Enter attribution"
                        value={addingLayer.attribution}
                        onChange={(e) => {
                          setAddingLayer({
                            ...addingLayer,
                            attribution: e.target.value,
                          });
                        }}
                      />
                    </td>
                    <td className="numericColumn">
                      <FormControl
                        type="number"
                        placeholder="Enter min zoom"
                        value={addingLayer.min_zoom}
                        onChange={(e) => {
                          setAddingLayer({
                            ...addingLayer,
                            min_zoom: parseInt(e.target.value, 10),
                          });
                        }}
                      />
                    </td>
                    <td className="numericColumn">
                      <FormControl
                        type="number"
                        placeholder="Enter max zoom"
                        value={addingLayer.max_zoom}
                        onChange={(e) => {
                          setAddingLayer({
                            ...addingLayer,
                            max_zoom: parseInt(e.target.value, 10),
                          });
                        }}
                      />
                    </td>
                    <td className="actionButtons">
                      <Button size="sm" onClick={() => onSaveNewMapLayer()}>
                        Save
                      </Button>
                      <Button
                        size="sm"
                        onClick={() => setAddingLayer(undefined)}
                      >
                        Cancel
                      </Button>
                    </td>
                  </>
                )}

                {!addingLayer && (
                  <td colSpan={3}>
                    <Button
                      size="sm"
                      onClick={() => {
                        setAddingLayer({
                          id: -1,
                          system_name: "",
                          display_name: "",
                          attribution: "",
                          url: "",
                          min_zoom: 0,
                          max_zoom: 0,
                        });
                      }}
                    >
                      Add Map Layer
                    </Button>
                  </td>
                )}
              </tr>
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </div>
  );
};

export default MapLayerEditPage;
