import PageHeader from "theme/components/common/PageHeader";
import * as React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import { gettext } from "../../i18n";

function padWithZero(num: number | string, targetLength: number): string {
  return String(num).padStart(targetLength, "0");
}

function createHmac(identifier: string) {
  const validChars =
    "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
  function getRandomCharacter() {
    // wanna win lottery? :'D
    for (let val of Array.from(crypto.getRandomValues(new Uint8Array(256)))) {
      val %= 64;
      if (val < validChars.length) {
        return validChars[val];
      }
    }
  }

  if (!identifier) {
    throw new Error("No identifier provided");
  }

  identifier = padWithZero(identifier, 7);

  let key = identifier + "-";

  for (let i = 0; i < 24; i++) {
    key += getRandomCharacter();
  }
  if (key.length > 32) {
    throw new Error("HMAC length exceeds the maximum of 32");
  }
  return key;
}

const HMACPage = () => {
  const [identifier, setIdentifier] = React.useState("");
  const [generatedHmac, setGeneratedHmac] = React.useState("");

  const onGenerate = (e: any) => {
    e.preventDefault();
    const hmac = createHmac(identifier);
    setGeneratedHmac(hmac);
  };

  return (
    <div className="HMACPage">
      <PageHeader
        title={gettext(`Generate HMAC`)}
        description={gettext(
          "Generate a HMAC shared secret for a measurement station."
        )}
        className="mb-3"
      ></PageHeader>
      <Card className="mb-3">
        <Card.Body>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Station identifier</Form.Label>
                <InputGroup>
                  <FormControl
                    type="text"
                    placeholder="Enter measurement station identifier"
                    onChange={(e) => setIdentifier(e.target.value)}
                    value={identifier}
                    disabled={!!generatedHmac}
                    maxLength={7}
                  />
                  <Button
                    variant="info"
                    disabled={!!generatedHmac || !identifier}
                    onClick={onGenerate}
                  >
                    Generate
                  </Button>
                </InputGroup>
              </Form.Group>
              {!!generatedHmac && (
                <div className="my-1">
                  <small>
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        setIdentifier("");
                        setGeneratedHmac("");
                      }}
                    >
                      Click here to reset the form and generate a new one (make
                      sure you have copied everything).
                    </a>
                  </small>
                </div>
              )}
            </Col>
            <Col>
              {generatedHmac && (
                <>
                  <div>
                    <strong>Your HMAC shared secret is:</strong>
                  </div>
                  <pre>{generatedHmac}</pre>
                </>
              )}
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </div>
  );
};

export default HMACPage;
