import { useQuery } from "@apollo/client";
import PageHeader from "theme/components/common/PageHeader";
import * as React from "react";
import Card from "react-bootstrap/Card";
import { toast } from "react-toastify";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import { GetUnits } from "../../utils/__generated__/GetUnits";
import apiClient from "../../api";
import { GET_UNITS } from "../../utils/units";
import { getFormValueHandler } from "../../utils/forms";
import { gettext } from "../../i18n";
import FormControl from "react-bootstrap/FormControl";
import "./UnitEditPage.scss";

interface EditableUnit {
  id: number;
  name?: string;
  symbol?: string;
}
const UnitEditPage = () => {
  const [editedValues, setEditedValues] = React.useState<EditableUnit>();
  const [addingUnit, setAddingUnit] = React.useState<EditableUnit>();
  const { data: unitQuery, refetch: refetchUnits } =
    useQuery<GetUnits>(GET_UNITS);

  const setValueHandler = getFormValueHandler(
    editedValues as Record<string, any>,
    setEditedValues as (
      value: React.SetStateAction<Record<string, any>>
    ) => void
  );

  const onUpdateUnit = async (
    { id, name, symbol }: EditableUnit,
    action: string = "edit"
  ) => {
    setEditedValues(undefined);

    let requestPath = "";
    switch (action) {
      case "edit":
        requestPath = `/admin/edit-unit`;
        break;
      case "delete":
        requestPath = `/admin/delete-unit`;
        break;
      case "add":
        requestPath = `/admin/add-unit`;
        break;
      default:
        throw new Error("Invalid action");
    }

    try {
      await apiClient.request(requestPath, {
        method: "POST",
        data: {
          id,
          name,
          symbol,
        },
      });
      toast.success("Success");
      refetchUnits();
      return true;
    } catch (e) {
      toast.error(gettext((e as any).message.toString()));
    }
    return false;
  };

  const onSaveEdited = () => onUpdateUnit(editedValues as EditableUnit, "edit");

  const onSaveNewUnit = async () => {
    const result = await onUpdateUnit(addingUnit as EditableUnit, "add");
    if (result) {
      setAddingUnit(undefined);
    }
  };

  const onDeleteUnit = (id: number) => {
    if (
      !window.confirm(gettext("Are you sure you want to delete this unit?"))
    ) {
      return;
    }
    onUpdateUnit({ id } as EditableUnit, "delete");
  };

  const units = unitQuery?.unit ? [...unitQuery?.unit] : [];
  units.sort((a, b) => (a.id > b.id ? 1 : -1));
  return (
    <div className="UnitEditPage">
      <PageHeader title="Edit Units" className="mb-3"></PageHeader>
      <Card className="mb-3">
        <Card.Body>
          <Table responsive>
            <thead>
              <tr>
                <th>Unit name (optional)</th>
                <th>Symbol</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {units.map((unit) => (
                <tr key={unit.id}>
                  {editedValues?.id === unit.id ? (
                    <>
                      <td>
                        <FormControl
                          type="text"
                          placeholder="Enter unit name (optional)"
                          value={editedValues.name}
                          onChange={setValueHandler("name")}
                        />
                      </td>
                      <td>
                        <FormControl
                          type="text"
                          placeholder="Enter unit symbol"
                          value={editedValues.symbol}
                          onChange={setValueHandler("symbol")}
                        />
                      </td>
                    </>
                  ) : (
                    <>
                      <td>{unit.name || gettext("(no name)")}</td>
                      <td>{unit.symbol || gettext("(no unit)")}</td>
                    </>
                  )}

                  <td className="actionButtons">
                    {editedValues?.id === unit.id ? (
                      <>
                        <Button size="sm" onClick={onSaveEdited}>
                          Save
                        </Button>
                        <Button
                          size="sm"
                          onClick={() => {
                            setEditedValues(undefined);
                          }}
                        >
                          Cancel
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button
                          size="sm"
                          onClick={() => {
                            setEditedValues({
                              ...unit,
                            });
                          }}
                        >
                          Edit
                        </Button>
                        <Button
                          variant="danger"
                          size="sm"
                          onClick={() => onDeleteUnit(unit.id)}
                        >
                          Delete
                        </Button>
                      </>
                    )}
                  </td>
                </tr>
              ))}
              <tr>
                {addingUnit && (
                  <>
                    <td>
                      <FormControl
                        type="text"
                        placeholder="Enter unit name (optional)"
                        value={addingUnit.name}
                        onChange={(e) => {
                          setAddingUnit({
                            ...addingUnit,
                            name: e.target.value,
                          });
                        }}
                      />
                    </td>
                    <td>
                      <FormControl
                        type="text"
                        placeholder="Enter unit symbol"
                        value={addingUnit.symbol}
                        onChange={(e) => {
                          setAddingUnit({
                            ...addingUnit,
                            symbol: e.target.value,
                          });
                        }}
                      />
                    </td>
                    <td className="actionButtons">
                      <Button size="sm" onClick={() => onSaveNewUnit()}>
                        Save
                      </Button>
                      <Button
                        size="sm"
                        onClick={() => setAddingUnit(undefined)}
                      >
                        Cancel
                      </Button>
                    </td>
                  </>
                )}

                {!addingUnit && (
                  <td colSpan={3}>
                    <Button
                      size="sm"
                      onClick={() => {
                        setAddingUnit({
                          id: -1,
                          name: "",
                          symbol: "",
                        });
                      }}
                    >
                      Add Unit
                    </Button>
                  </td>
                )}
              </tr>
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </div>
  );
};

export default UnitEditPage;
