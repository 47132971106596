import { gettext } from "../i18n";

const ERROR_MESSAGES: Record<number, string> = {
  400: "Bad request",
  401: "Unauthorized",
  403: "Forbidden",
  404: "Not found",
  500: "Internal server error",
  502: "Bad gateway",
  503: "Service unavailable",
  504: "Gateway timeout",
};

const getErrorMessageForCode = (code: number): string =>
  gettext(ERROR_MESSAGES[code] || "Unspecified server error");

interface RequestOpts {
  method?: "GET" | "POST" | "PUT" | "DELETE";
  username?: string;
  password?: string;
  data?: Record<string, any>;
  multipart?: boolean;
}

class ApiError extends Error {}

class ApiClient {
  private baseUrl: string;

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl.replace(/\/*$/, "");
  }

  async request(
    path: string,
    opts: RequestOpts = {}
  ): Promise<Record<string, any>> {
    const response = await this.requestRaw(path, opts);
    if (!response.ok) {
      const statusCode = response.status;
      let message = await response.text();
      let jsonError = false;
      try {
        const jsonData = JSON.parse(message);
        if (jsonData.error) {
          message = jsonData.error;
          jsonError = true;
        }
      } catch (e) {}
      // Unauthorized - session expired
      if (statusCode === 401) {
        window.location.reload();
      }
      if (!jsonError) {
        console.error(`API error ${statusCode}: `, message);
        message = `${statusCode}: ${getErrorMessageForCode(statusCode)}`;
      }
      throw new ApiError(message);
    }
    return await response.json();
  }

  async getAuthJWT() {
    let authJWT = JSON.parse(window.localStorage.getItem("authJWT") ?? "{}");
    const expiryThreshold = 2 * 60; // 2 minutes
    const currentTimestamp = new Date().getTime() / 1000;

    if (
      authJWT.token !== undefined &&
      authJWT.expiresAt !== null &&
      authJWT.expiresAt - expiryThreshold > currentTimestamp
    ) {
      return authJWT;
    }

    const url = this.getApiUrl("/auth/token/reissue");
    const response = await fetch(url, {
      method: "POST",
      credentials: "include",
    });

    if (!response.ok && response.status === 401) {
      authJWT = {
        token: null,
        expiresAt: null,
      };
      window.localStorage.removeItem("authJWT");
      return authJWT;
    }
    const data = await response.json();

    authJWT = {
      token: data.token,
      expiresAt: data.expires_at,
    };

    window.localStorage.setItem("authJWT", JSON.stringify(authJWT));
    return authJWT;
  }

  async requestRaw(path: string, opts: RequestOpts = {}): Promise<Response> {
    let { data, method = "GET", multipart } = opts;
    const url = this.getApiUrl(path);

    const authJWT = await this.getAuthJWT();
    const headers = new Headers();
    if (!multipart) {
      headers.set("Content-Type", "application/json");
    }
    if (authJWT.token) {
      headers.set("Authorization", `Bearer ${authJWT.token}`);
    }

    const fetchOpts: RequestInit = {
      method,
      credentials: "include",
      mode: "cors",
      headers,
    };
    if (data) {
      if (multipart) {
        fetchOpts.body = new FormData();
        for (const [key, value] of Object.entries(data)) {
          fetchOpts.body.append(key, value);
        }
      } else {
        fetchOpts.body = JSON.stringify(data);
      }
    }
    return await fetch(url, fetchOpts);
  }

  getApiUrl(path: string) {
    if (path.charAt(0) !== "/") {
      path = "/" + path;
    }
    return `${this.baseUrl}${path}`;
  }
}
export default ApiClient;
