import * as React from "react";
import { DataSource } from "utils/datacharts";

interface modalSettersProps {
  setSelectedDataSource: React.Dispatch<
    React.SetStateAction<DataSource | null>
  > | null;
  setModalPayload: React.Dispatch<React.SetStateAction<any>> | null;
  setActiveModal: React.Dispatch<React.SetStateAction<string | null>> | null;
}

/**
 * Static export of state setters for components that do not need reactivity
 * based on the current state value.
 * E.g. charts must be able to open a modal, but do not need to rerender when the modal visibility changes.
 */
export const modalSetters: modalSettersProps = {
  setSelectedDataSource: null,
  setModalPayload: null,
  setActiveModal: null,
};

export const initialModalState = {
  activeModal: null,
  modalPayload: null,
  selectedDataSource: null,
  setActiveModal: () => {},
  setSelectedDataSource: () => {},
  setModalPayload: () => {},
};

interface ModalContextProps {
  activeModal: string | null;
  modalPayload: any;
  selectedDataSource: DataSource | null;
  setActiveModal: React.Dispatch<React.SetStateAction<string | null>>;
  setSelectedDataSource: React.Dispatch<
    React.SetStateAction<DataSource | null>
  >;
  setModalPayload: React.Dispatch<React.SetStateAction<any>>;
}
const ModalContext = React.createContext<ModalContextProps>(initialModalState);

export const ModalProvider: React.FC = ({ children }) => {
  const [activeModal, setActiveModal] = React.useState<string | null>(null);
  const [modalPayload, setModalPayload] = React.useState<any>(null);
  const [selectedDataSource, setSelectedDataSource] =
    React.useState<DataSource | null>(null);

  if (!modalSetters.setActiveModal) {
    modalSetters.setActiveModal = setActiveModal;
    modalSetters.setModalPayload = setModalPayload;
    modalSetters.setSelectedDataSource = setSelectedDataSource;
  }
  const contextValue = React.useMemo(
    () => ({
      activeModal,
      modalPayload,
      setActiveModal,
      setModalPayload,
      selectedDataSource,
      setSelectedDataSource,
    }),
    [activeModal, modalPayload, selectedDataSource]
  );
  return (
    <ModalContext.Provider value={contextValue}>
      {children}
    </ModalContext.Provider>
  );
};

// Hooks

export const useModalContext = (): ModalContextProps =>
  React.useContext(ModalContext);
