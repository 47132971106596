import * as React from "react";
import {
  ApolloClient,
  InMemoryCache,
  NormalizedCacheObject,
  ApolloProvider,
  HttpLink,
  split,
  from,
} from "@apollo/client";
import apiClient from "api";
import { onError } from "@apollo/client/link/error";
import { getMainDefinition } from "@apollo/client/utilities";
import { setContext } from "@apollo/client/link/context";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";

const HASURA_URI =
  process.env.REACT_APP_HASURA_URI ?? "localhost:8080/v1/graphql";
const PROTOCOL_HTTP = process.env.REACT_APP_HASURA_SECURE ? "https" : "http";
const PROTOCOL_WS = process.env.REACT_APP_HASURA_SECURE ? "wss" : "ws";

function getAuthHeader(
  authJWT: Record<string, string> | undefined
): Record<string, string> {
  let headers: Record<string, any> = {};
  if (authJWT?.token) {
    headers["Authorization"] = `Bearer ${authJWT?.token}`;
  }

  return headers;
}

const redirectOnUnauthorized = (errorMessage: string | Error) => {
  // JWT expired - reload page
  if (String(errorMessage).includes("unauthorized")) {
    window.location.reload();
  }
};

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) => {
      redirectOnUnauthorized(message);
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      );
    });
  if (networkError) {
    redirectOnUnauthorized(networkError);
    console.log(`[Network error]: ${networkError}`);
  }
});

// TODO: https/wss
const httpLink = new HttpLink({
  uri: `${PROTOCOL_HTTP}://${HASURA_URI}`,
  credentials: "include",
});

const wsLink = new GraphQLWsLink(
  createClient({
    url: `${PROTOCOL_WS}://${HASURA_URI}`,
    connectionParams: () => {
      return apiClient.getAuthJWT().then((authJWT) => {
        return {
          headers: {
            ...getAuthHeader(authJWT),
          },
        };
      });
    },
  })
);

const connectionLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink
);

const authLink = setContext((_, { headers }) => {
  return apiClient.getAuthJWT().then((authJWT) => {
    return {
      headers: {
        ...headers,
        ...getAuthHeader(authJWT),
      },
    };
  });
});

export const apolloClient: ApolloClient<NormalizedCacheObject> =
  new ApolloClient({
    cache: new InMemoryCache({
      typePolicies: {
        datasource: {
          keyFields: ["type", "source_id"],
        },
      },
    }),
    link: from([errorLink, authLink.concat(connectionLink)]),
  });
export const AppApolloProvider: React.FC = ({ children }) => (
  <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
);
