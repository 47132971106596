/**
 * Types, constants and utilities related to datacharts
 */
import * as React from "react";
import { getColor } from "theme/helpers/utils";
import { usePolledQuery } from "../hooks/apollo";
import {
  GetMeasurementStation_station_datasources,
  GetMeasurementStation_station_datasources_datasource_annotation,
} from "../utils/__generated__/GetMeasurementStation";
import {
  GetDatasourceDataByUUID,
  GetDatasourceDataByUUIDVariables,
  GetDatasourceDataByUUID_source,
} from "utils/__generated__/GetDatasourceDataByUUID";
import { alertFragment } from "../pages/__generated__/alertFragment";
import { GET_DATASOURCE_DATA_BY_UUID } from "../utils/stations";
import { GetDatasourceData_source_data } from "utils/__generated__/GetDatasourceData";

export interface DataPoint {
  timestamp: string;
  value: number;
  invalid: boolean | null;
}

export type DataSource = GetMeasurementStation_station_datasources;

export type DataSourceAnnotation =
  GetMeasurementStation_station_datasources_datasource_annotation;

export interface AdditionalDatapointData {
  source: GetDatasourceDataByUUID_source;
  data: GetDatasourceData_source_data[];
  subtractData?: GetDatasourceData_source_data[];
  seriesData?: Object;
}

export interface DataChartProps {
  source: GetMeasurementStation_station_datasources;
  datapoints: DataPoint[] | null;
  showAnnotationForm?: (source: DataSource, datapoint: DataPoint) => void;
  medianDatapoints?: DataPoint[] | null;
  alerts?: alertFragment[];
  startDate?: Date;
  endDate?: Date;
  dataModifier?: number;
  additionalDatapoints?: AdditionalDatapointData[];
  confidenceBand?: boolean;
  datapointGroupDateFormat?: string | null;
  labelDateFormat?: string | null;
  scalingMin?: number;
  scalingMax?: number;
  manualScaling?: boolean;
  isAdmin?: boolean;
}

export const manualDatapointSymbolSVG = `path://M1972 5548 c-7 -7 -12 -21 -12 -32 0 -14 282 -303 867 -888 l868
    -868 -869 -869 c-798 -798 -868 -871 -862 -895 4 -17 15 -28 32 -32 24 -6 97
    64 895 862 l869 869 869 -869 c798 -798 871 -868 895 -862 17 4 28 15 32 32 6
    24 -64 97 -862 895 l-869 869 868 868 c585 585 867 874 867 888 0 26 -18 44
    -44 44 -14 0 -303 -282 -888 -867 l-868 -868 -868 868 c-585 585 -874 867
    -888 867 -11 0 -25 -5 -32 -12z`;

export const defaultAnnotationColor = "rgba(187, 127, 0, 0.8)";

export const getChartSeriesColor = (seriesIndex?: number): string => {
  if (!seriesIndex) {
    return getColor("primary");
  }
  const colors: string[] = [
    "#E8743B",
    "#5899DA",
    "#EB054A",
    "#945ECF",
    "#13A4B4",
    "#525DF4",
    "#BF399E",
    "#6C8893",
    "#EE6868",
    "#2F6497",
  ];
  const idx = (seriesIndex - 1) % colors.length;
  return colors[idx];
};

export const FullDataSourceLoader = ({
  sourceUUID,
  startDate,
  endDate,
  setSourceData,
  additionalDatapoints,
}: {
  sourceUUID: string;
  startDate?: Date;
  endDate?: Date;
  additionalDatapoints?: AdditionalDatapointData[];
  setSourceData: (
    source: GetDatasourceDataByUUID_source,
    data: GetDatasourceData_source_data[]
  ) => void;
}) => {
  const { data: dataSourceData } = usePolledQuery<
    GetDatasourceDataByUUID,
    GetDatasourceDataByUUIDVariables
  >(GET_DATASOURCE_DATA_BY_UUID, {
    variables: {
      sourceUUID,
      startDate: startDate?.toISOString(),
      endDate: endDate?.toISOString(),
    },
  });

  const dataSource = dataSourceData?.source;
  const datapoints = React.useMemo(
    () => dataSourceData?.source?.data,
    [dataSourceData]
  );

  React.useEffect(() => {
    if (datapoints === undefined || datapoints === null || !dataSource) {
      return;
    }

    // TODO: FIX THE REAL ISSUE HERE. See trello card 410 for details.
    if (
      !dataSource.source_id &&
      !dataSource.source_uuid &&
      !dataSource.type &&
      !dataSource.station
    ) {
      return;
    }

    const existingData = additionalDatapoints?.find(
      (d) => d.source.source_uuid === sourceUUID
    );
    if (existingData && existingData.data.length === datapoints.length) {
      return;
    }

    setSourceData(dataSource, datapoints);
  }, [setSourceData, sourceUUID, datapoints, additionalDatapoints, dataSource]);
  return <></>;
};
