import * as React from 'react';
import {Link} from 'react-router-dom';

export interface BackToLinkProps {
  to: string;
}
const BackToLink: React.FC<BackToLinkProps> = ({ to, children }) => {
  return (
    <div className="mb-2">
      <small>
        <Link to={to}>
          &lsaquo; {children}
        </Link>
      </small>
    </div>
  )
}

export default BackToLink;
