import * as React from "react";
import { useNavigate } from "react-router-dom";
import FormControl from "react-bootstrap/FormControl";
import Table from "react-bootstrap/Table";
import PageHeader from "theme/components/common/PageHeader";
import { Button, Card, Tabs, Tab, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import apiClient from "../api";
import { useAuth } from "../state/auth";
import { useQuery } from "@apollo/client";
import {
  GetOrganizations,
  GetOrganizations_organization,
} from "../utils/__generated__/GetOrganizations";
import {
  loadManagedOrganizations,
  ManagedOrganizationOption,
  GET_ORGANIZATIONS,
} from "../utils/users";
import { gettext } from "../i18n";
import { getFormValueHandler } from "../utils/forms";

import "./OrganizationManagementPage.scss";

interface OrganizationListTabProps {
  organizationList: GetOrganizations | undefined;
}
const OrganizationListTab: React.FC<OrganizationListTabProps> = ({
  organizationList,
}: OrganizationListTabProps) => {
  const navigate = useNavigate();

  return (
    <div className="OrganizationManagementPage">
      <PageHeader title="Existing Organizations" className="mb-3" />
      <Card>
        <Card.Body>
          {!organizationList?.organization?.length ? (
            <div>No data available</div>
          ) : (
            <Table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Name</th>
                  <th>Slug</th>
                </tr>
              </thead>
              <tbody>
                {organizationList?.organization?.map(
                  (organization: GetOrganizations_organization) => (
                    <tr
                      key={`organization_row_${organization.id}`}
                      className="activeRow"
                      onClick={() => {
                        navigate(`/organization/${organization.id}/edit`);
                      }}
                    >
                      <td>{organization.id}</td>
                      <td>{organization.name}</td>
                      <td>{organization.slug}</td>
                    </tr>
                  )
                )}
              </tbody>
            </Table>
          )}
        </Card.Body>
      </Card>
    </div>
  );
};

interface organizationAddTabProps {
  organizationListRefetch: () => void;
}
const OrganizationAddTab: React.FC<organizationAddTabProps> = ({
  organizationListRefetch,
}: organizationAddTabProps) => {
  const { user } = useAuth();
  const [validated, setValidated] = React.useState<boolean>(false);
  const [editedValues, setEditedValues] = React.useState<Record<string, any>>(
    {}
  );

  const setValueHandler = getFormValueHandler(editedValues, setEditedValues);

  const submitDisabled = false;
  const onSave = async (e: any) => {
    e.preventDefault();
    if (submitDisabled) {
      return;
    }
    const config: Record<string, any> = {};
    const values = { ...editedValues };
    apiClient
      .request("/auth/create-organization-record", {
        method: "POST",
        data: {
          ...values,
          config,
        },
      })
      .then(async () => {
        toast.success(gettext("Organization created successfully"));
        setEditedValues({});
        setValidated(false);
        organizationListRefetch();
      })
      .catch((e) => {
        console.error(e);
        toast.error(
          `${gettext("Failed to create new organization!")} ${gettext(
            e.toString()
          )}`
        );
      });
  };
  const handleSubmit = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    // Show any potential input errors
    setValidated(true);

    // If the form is valid, submit
    const form = event.currentTarget;
    if (form.checkValidity()) {
      onSave(event);
    }
  };
  if (!user) {
    return <div>Not logged in</div>;
  }
  if (!user.admin) {
    return <div>Unauthorized</div>;
  }

  return (
    <div>
      <PageHeader title="Add New Organization" className="mb-3" />
      <Card>
        <Card.Body>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Table>
              <tbody>
                <tr>
                  <th>Name</th>
                  <td>
                    <FormControl
                      value={editedValues.name ?? ""}
                      placeholder={gettext("Organization name")}
                      onChange={setValueHandler("name")}
                      required
                    />
                  </td>
                </tr>
                <tr>
                  <th>Slug</th>
                  <td>
                    <FormControl
                      value={editedValues.slug ?? ""}
                      placeholder={gettext("organization_slug")}
                      onChange={setValueHandler("slug")}
                      required
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <Button type="submit" disabled={submitDisabled}>
                      Add
                    </Button>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
};

interface OrganizationManagementTabsProps {
  organizationList: GetOrganizations | undefined;
  organizationListRefetch: () => void;
  permissions?: string[];
}
const OrganizationManagementTabs: React.FC<OrganizationManagementTabsProps> = ({
  organizationList,
  organizationListRefetch,
  permissions = [],
}: OrganizationManagementTabsProps) => {
  return (
    <Tabs defaultActiveKey="list" id="user-management-tabs" className="mb-3">
      {permissions.includes("list_organizations") && (
        <Tab eventKey="list" title="Existing Organizations">
          <OrganizationListTab organizationList={organizationList} />
        </Tab>
      )}

      {permissions.includes("add_organization") && (
        <Tab eventKey="addorganization" title="Add Organization">
          <OrganizationAddTab
            organizationListRefetch={organizationListRefetch}
          />
        </Tab>
      )}
    </Tabs>
  );
};

interface OrganizationManagementPageProps {
  permissions?: string[];
}
const OrganizationManagementPage: React.FC<OrganizationManagementPageProps> = ({
  permissions = [],
}: OrganizationManagementPageProps) => {
  const { user } = useAuth();

  const [managedOrganizations, setManagedOrganizations] = React.useState<
    ManagedOrganizationOption[]
  >([]);

  const organizationRefetch = () =>
    loadManagedOrganizations({
      setManagedOrganizations,
    });

  const { data: organizationList, refetch: organizationListRefetch } =
    useQuery<GetOrganizations>(GET_ORGANIZATIONS, { variables: {} });

  React.useEffect(() => {
    organizationRefetch();
  }, [user, setManagedOrganizations]);

  if (!user) {
    return <div>Not logged in</div>;
  }
  if (!user.admin && !managedOrganizations.length) {
    return <div>Unauthorized</div>;
  }

  return (
    <div className="OrganizationManagementPage">
      <OrganizationManagementTabs
        organizationList={organizationList}
        organizationListRefetch={organizationListRefetch}
        permissions={permissions}
      />
    </div>
  );
};
export default OrganizationManagementPage;
