import * as React from "react";

/**
 * Utilities for easier form handling
 */

export const getFormValueHandler =
  (
    currentFormState: Record<string, any>,
    formStateSetter: (value: React.SetStateAction<Record<string, any>>) => void
  ) =>
  (key: string, parser?: (raw: string) => any) =>
  (e: any) => {
    let value = parser ? parser(e.target.value) : e.target.value;

    formStateSetter({
      ...currentFormState,
      [key]: value,
    });
  };

export const searchIncludes = (value: string, searchTerm: string) =>
  value.toLowerCase().includes(searchTerm.toLowerCase());
