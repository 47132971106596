import * as React from "react";
import { formatNumberLocale, parseNumberInput } from "../../utils/formatting";
import FormControl, { FormControlProps } from "react-bootstrap/FormControl";

const decimalNumberRegex = /^(?!00)\d+(\.|,)0{1,}$/;

const typeFormatters: Record<string, Function> = {
  number: (value: string) => {
    if (
      !String(value).endsWith(".") &&
      !String(value).endsWith(",") &&
      !decimalNumberRegex.test(value)
    ) {
      const parsedNumber = parseNumberInput(value);
      if (Number.isNaN(parsedNumber)) {
        return {
          value: 0,
          error: true,
        };
      }
      return {
        value: formatNumberLocale(parsedNumber),
        error: false,
      };
    }
    return {
      value,
      error: false,
    };
  },
  nullableString: (value: string | null) => {
    if (value === null) {
      return {
        value: "",
        error: false,
      };
    }
    return {
      value,
      error: false,
    };
  },
};

const typeParsers: Record<string, Function> = {
  number: (value: string) => {
    if (
      !String(value).endsWith(".") &&
      !String(value).endsWith(",") &&
      !decimalNumberRegex.test(value)
    ) {
      return parseNumberInput(value);
    }
    return value;
  },
  nullableString: (value: string | null) => {
    if (value === "") {
      return null;
    }
    return value;
  },
};

export interface FormattedFormControlProps
  extends Omit<FormControlProps, "value"> {
  value: string | null;
  type?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}
const FormattedFormControl: React.FC<FormattedFormControlProps> = ({
  value,
  onChange,
  type,
  children,
  ...props
}) => {
  const [isEditing, setIsEditing] = React.useState<boolean>(false);
  const [editingValue, setEditingValue] = React.useState<string | null>(null);
  const { displayValue, error } = React.useMemo(() => {
    const formatter = typeFormatters[type || "text"];
    if (formatter) {
      const formatted = formatter(editingValue ?? value);
      return {
        displayValue: formatted.value,
        error: formatted.error,
      };
    }
    return {
      displayValue: editingValue,
      error: false,
    };
  }, [editingValue, value, type]);

  // User-friendly value, different from the current form value
  const renderValue = isEditing || error ? editingValue : displayValue;
  return (
    <>
      <FormControl
        isInvalid={error}
        value={renderValue}
        onFocus={() => {
          setIsEditing(true);

          if (error) {
            setEditingValue(editingValue);
            return;
          }

          let initialValue = value;
          const parser = typeParsers[type || "text"];
          if (parser) {
            initialValue = parser(value);
          }
          setEditingValue(initialValue);
        }}
        onBlur={(e) => {
          if (error) {
            return;
          }
          setIsEditing(false);
          setEditingValue(null);
          onChange({
            ...e,
            target: {
              ...e.target,
              value: editingValue,
            },
          } as React.ChangeEvent<HTMLInputElement>);
        }}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setEditingValue(e.target.value);
        }}
        {...props}
      />
      <FormControl.Feedback />
    </>
  );
};

export default FormattedFormControl;
