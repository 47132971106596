import dayjs from "dayjs";
import "dayjs/locale/fi";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import * as dateFns from "date-fns";
import fi from "date-fns/locale/fi";
import { getLocale } from "../i18n";

dayjs.extend(LocalizedFormat).locale("fi");

interface NumberParserInterface {
  constructor: Function;
  parse(value: string): number;
}
export class NumberParser implements NumberParserInterface {
  _group: RegExp;
  _decimal: RegExp;
  _numeral: RegExp;
  _index: (substring: string, ...args: any[]) => string;

  constructor(locale: string = "fi") {
    const format = new Intl.NumberFormat(locale);
    const parts = format.formatToParts(12345.6);
    const numerals = Array.from({ length: 10 }).map((_, i) => format.format(i));
    const index = new Map(numerals.map((d, i) => [d, i]));
    this._group = new RegExp(
      `[${parts!.find((d) => d.type === "group")!.value}]`,
      "g"
    );
    this._decimal = new RegExp(
      `[${parts!.find((d) => d.type === "decimal")!.value}]`,
      "g"
    );
    this._numeral = new RegExp(`[${numerals.join("")}]`, "g");
    this._index = (d) => String(index.get(d));
  }
  parse(value: string, thousandsSeparator: boolean = false): number {
    let parsedValue: string | number = value.trim();

    // Parse scientific notation (e.g. 1.23e-4)
    if (parsedValue.includes("e")) {
      const [mantissa, exponent] = parsedValue.split("e");
      return Number(mantissa) * 10 ** Number(exponent);
    }

    parsedValue = parsedValue.replaceAll(this._group, "");

    if (thousandsSeparator) {
      parsedValue = parsedValue.replaceAll(".", "");
    }

    parsedValue = parsedValue
      .replaceAll(/\s/g, "")
      .replaceAll(this._decimal, ".")
      .replaceAll(this._numeral, this._index);

    return +parsedValue;
  }
}

export const parserFi = new NumberParser();

export const timestampToDatetimeString = (
  timestamp: number | string,
  systemDate: boolean = false
) => dateToString(new Date(timestamp), systemDate);

export const dateToString = (date: Date, systemDate: boolean = false) =>
  dateFns.format(date, systemDate ? "yyyy-MM-dd HH:mm" : "dd.MM.yyyy HH:mm", {
    locale: fi,
  });

export const stringToDate = (dateString: string): dayjs.Dayjs =>
  dayjs(dateString);

// Format number to significant digits
export const formatNumber = (x: number, n = 3): string =>
  (+x.toPrecision(n)).toFixed(n + 5).replace(/\.?0+$/, "");

export const formatNumberSafe = (x: any, n = 5): string => {
  if (typeof x === "number") {
    return formatNumber(x, n);
  }
  if (!x) {
    return "";
  }
  return x.toString();
};

export const toLocaleDateString = (date: Date) =>
  date.toLocaleDateString("fi-FI");

export const formatNumberLocale = (x: number, n = 5): string =>
  x.toLocaleString(getLocale() || "fi-FI", { maximumFractionDigits: n });

export const sortByKey = (array: any[], key: string) =>
  [...array].sort((a, b) => {
    const x = a[key].toLowerCase();
    const y = b[key].toLowerCase();
    return x.localeCompare(y, "fi");
  });

export const parseNumberInput = (
  value: string | number,
  thousandsSeparator: boolean | null = null
) => {
  if (thousandsSeparator === null) {
    // Auto-detect: if both decimal and thousands separators are present, assume thousands separator
    thousandsSeparator =
      String(value).includes(".") && String(value).includes(",");
  }
  return parserFi.parse(String(value), thousandsSeparator);
};

/**
 * Attempt to parse all locale-aware numeric inputs in a form payload (dictionary)
 */
export const parseNumericFormValues = (
  payload: Record<string, any>
): Record<string, any> => {
  const modifiedPayload = { ...payload };
  Object.keys(modifiedPayload).forEach((key) => {
    if (typeof modifiedPayload[key] === "string") {
      if (key === "system_name" && modifiedPayload[key].length === 0) {
        modifiedPayload[key] = null;
        return;
      }
      const parsedValue = parseNumberInput(modifiedPayload[key]);
      if (!isNaN(parsedValue)) {
        modifiedPayload[key] = parsedValue;
      }
    } else if (modifiedPayload[key] instanceof Date) {
      // Do not modify dates
      return;
    } else if (typeof modifiedPayload[key] === "object") {
      modifiedPayload[key] = parseNumericFormValues(modifiedPayload[key]);
    }
  });
  return modifiedPayload;
};

/**
 * Accepts a list of objects that have a value property
 * and attempts to parse it as a locale-aware number
 */
export const parseNumericListValues = (
  listOfObjects: {
    value: string | number;
  }[]
) => {
  const mutatedList = [...listOfObjects];
  mutatedList.forEach((item) => {
    const parsedValue = parseNumberInput(String(item.value));
    if (!isNaN(parsedValue)) {
      item.value = parsedValue;
    }
  });
  return mutatedList;
};
