import * as React from "react";
import { useNavigate } from "react-router-dom";
import FormControl from "react-bootstrap/FormControl";
import Table from "react-bootstrap/Table";
import { useApolloClient } from "@apollo/client";
import PageHeader from "theme/components/common/PageHeader";
import { Button, Card, Tabs, Tab, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import apiClient from "../api";
import { useAuth } from "../state/auth";
import {
  USER_ROLE_OPTIONS,
  loadManagedOrganizations,
  loadEditableUsers,
  ManagedOrganizationOption,
  EditableUserOption,
} from "../utils/users";
import { gettext, getLocaleDropdownOptions } from "i18n";
import { getFormValueHandler } from "../utils/forms";

import "./UserManagementPage.scss";

interface UserListTabProps {
  editableUsers?: EditableUserOption[];
}
const UserListTab: React.FC<UserListTabProps> = ({
  editableUsers,
}: UserListTabProps) => {
  const navigate = useNavigate();

  return (
    <div>
      <PageHeader title="Existing Users" className="mb-3" />
      <Card>
        <Card.Body>
          {!editableUsers?.length ? (
            <div>No data available</div>
          ) : (
            <Table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Display Name</th>
                  <th>Email</th>
                </tr>
              </thead>
              <tbody>
                {editableUsers?.map((user) => (
                  <tr
                    key={`user_row_${user.id}`}
                    className="activeRow"
                    onClick={() => {
                      navigate(`/user/${user.id}/edit`);
                    }}
                  >
                    <td>{user.id}</td>
                    <td>{user.display_name}</td>
                    <td>{user.email}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </Card.Body>
      </Card>
    </div>
  );
};

interface UserAddTabProps {
  managedOrganizations: ManagedOrganizationOption[];
  userRefetch: () => void;
  permissions?: string[];
}
const UserAddTab: React.FC<UserAddTabProps> = ({
  userRefetch,
  managedOrganizations,
  permissions = [],
}: UserAddTabProps) => {
  const { user } = useAuth();
  const [validated, setValidated] = React.useState<boolean>(false);
  const [editedValues, setEditedValues] = React.useState<Record<string, any>>(
    {}
  );
  const apolloClient = useApolloClient();
  const setValueHandler = getFormValueHandler(editedValues, setEditedValues);

  const setValue = (key: string, value: any) => {
    setEditedValues({
      ...editedValues,
      [key]: value,
    });
  };

  const submitDisabled = false;
  const onSave = async (e: any) => {
    e.preventDefault();
    if (submitDisabled) {
      return;
    }
    const config: Record<string, any> = {};
    const values = { ...editedValues };
    apiClient
      .request("/auth/create-user-record", {
        method: "POST",
        data: {
          ...values,
          config,
        },
      })
      .then(async () => {
        toast.success("User created successfully");
        setEditedValues({});
        setValidated(false);
        await userRefetch();
        // If this was an existing user updated by an org admin, reset store to refresh their org memberships
        await apolloClient.resetStore();
      })
      .catch((e) => {
        console.error(e);
        toast.error("Failed to create new user! " + e.toString());
      });
  };
  const handleSubmit = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    // Show any potential input errors
    setValidated(true);

    // If the form is valid, submit
    const form = event.currentTarget;
    if (form.checkValidity()) {
      onSave(event);
    }
  };
  if (!user) {
    return <div>Not logged in</div>;
  }

  return (
    <div>
      <PageHeader title="Add New User" className="mb-3" />
      <Card>
        <Card.Body>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Table>
              <tbody>
                <tr>
                  <th>Email</th>
                  <td>
                    <FormControl
                      type="email"
                      value={editedValues.email ?? ""}
                      placeholder={"user@example.com"}
                      onChange={setValueHandler("email")}
                      required
                    />
                  </td>
                </tr>
                <tr>
                  <th>Display Name</th>
                  <td>
                    <FormControl
                      value={editedValues.displayName ?? ""}
                      placeholder={gettext("Display Name")}
                      onChange={setValueHandler("displayName")}
                      required
                    />
                  </td>
                </tr>
                <tr>
                  <th>Language</th>
                  <td>
                    <Form.Select
                      required
                      aria-label="Selected language"
                      onChange={setValueHandler("locale")}
                      value={editedValues?.locale ?? ""}
                    >
                      <option value="">Select language</option>
                      {Object.entries(getLocaleDropdownOptions()).map(
                        ([key, label]) => (
                          <option key={`role_${key}`} value={key}>
                            {gettext(label)}
                          </option>
                        )
                      )}
                    </Form.Select>
                  </td>
                </tr>
                {permissions.includes("modify_admin") && (
                  <tr>
                    <th>Global Admin</th>
                    <td>
                      <Form.Check
                        type="checkbox"
                        onChange={(e: any) =>
                          setValue("globalAdmin", e.target.checked)
                        }
                      />
                    </td>
                  </tr>
                )}
                <tr>
                  <th>Organization</th>
                  <td>
                    <Form.Select
                      aria-label="Organization of the new user"
                      onChange={setValueHandler("organization")}
                      value={editedValues.organization ?? ""}
                    >
                      <option></option>
                      {managedOrganizations.map((organization) => (
                        <option
                          key={`org_${organization.id}`}
                          value={organization.id}
                        >
                          {organization.name}
                        </option>
                      ))}
                    </Form.Select>
                  </td>
                </tr>
                <tr>
                  <th>Organization Role</th>
                  <td>
                    <Form.Select
                      aria-label="Organization role of the new user"
                      onChange={setValueHandler("role")}
                      value={editedValues.role ?? ""}
                      required={!!editedValues.organization}
                    >
                      <option></option>
                      {Object.entries(USER_ROLE_OPTIONS).map(([key, label]) => (
                        <option key={`role_${key}`} value={key}>
                          {gettext(label)}
                        </option>
                      ))}
                    </Form.Select>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <Button type="submit" disabled={submitDisabled}>
                      Add
                    </Button>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
};

interface UserManagementTabsProps {
  managedOrganizations: ManagedOrganizationOption[];
  editableUsers: EditableUserOption[];
  userRefetch: () => void;
  permissions?: string[];
}
const UserManagementTabs: React.FC<UserManagementTabsProps> = ({
  managedOrganizations,
  editableUsers,
  userRefetch,
  permissions = [],
}: UserManagementTabsProps) => {
  const defaultKey = permissions.includes("list_users") ? "list" : "add";
  return (
    <Tabs
      defaultActiveKey={defaultKey}
      id="user-management-tabs"
      className="mb-3"
    >
      {permissions.includes("list_users") && (
        <Tab eventKey="list" title="Existing Users">
          <UserListTab editableUsers={editableUsers} />
        </Tab>
      )}
      {permissions.includes("add_user") && (
        <Tab eventKey="add" title="Add User">
          <UserAddTab
            managedOrganizations={managedOrganizations}
            userRefetch={userRefetch}
            permissions={permissions}
          />
        </Tab>
      )}
    </Tabs>
  );
};

interface UserManagementPageProps {
  permissions?: string[];
}
const UserManagementPage: React.FC<UserManagementPageProps> = ({
  permissions,
}) => {
  const { user } = useAuth();

  const [managedOrganizations, setManagedOrganizations] = React.useState<
    ManagedOrganizationOption[]
  >([]);
  const [editableUsers, setEditableUsers] = React.useState<
    EditableUserOption[]
  >([]);

  const organizationRefetch = () =>
    loadManagedOrganizations({
      setManagedOrganizations,
    });
  const userRefetch = () => loadEditableUsers({ setEditableUsers });

  React.useEffect(() => {
    organizationRefetch();
    userRefetch();
  }, [setManagedOrganizations, setEditableUsers]);

  if (!user) {
    return <div>Not logged in</div>;
  }
  return (
    <div className="UserManagementPage">
      <UserManagementTabs
        editableUsers={editableUsers}
        userRefetch={userRefetch}
        managedOrganizations={managedOrganizations}
        permissions={permissions}
      />
    </div>
  );
};
export default UserManagementPage;
