/**
 * Date utils
 */


export function toUTC(date: Date): Date {
  return new Date(Date.UTC(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds(),
    date.getUTCMilliseconds()
  ));
}

// Shift the entered UTC date to the local time zone
export function adjustToLocalTime(date: Date) {
  // Create a new Date as if it's in UTC
  const utcDate = new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes())
  );

  return utcDate;
};
