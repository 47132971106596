import * as React from "react";
import classNames from "classnames";
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface DefaultTooltipProps {
  popBody: React.ReactElement;
  popHeader?: string;
  iconClassName?: string;
}

const DefaultTooltip = ({
  popHeader,
  popBody,
  iconClassName,
}: DefaultTooltipProps) => {
  const popover = (
    <Popover id="popover-basic" style={{ margin: 0 }}>
      {!!popHeader && <Popover.Header as="h3">{popHeader}</Popover.Header>}
      <Popover.Body>{popBody}</Popover.Body>
    </Popover>
  );
  return (
    <OverlayTrigger
      delay={{ show: 100, hide: 400 }}
      trigger={["hover", "focus"]}
      placement="top"
      overlay={popover}
    >
      <FontAwesomeIcon
        className={classNames(
          "d-inline-flex align-items-center",
          iconClassName
        )}
        icon="circle-exclamation"
        key={`circle-exclamation-${popHeader}`}
      />
    </OverlayTrigger>
  );
};

export default DefaultTooltip;
