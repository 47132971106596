import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { gettext } from "../../../../i18n";
import { Button } from "react-bootstrap";

function SubscribeButton() {
  return (
    <Button
      className="btn btn-light"
      href="https://mittausguru.fi/?ff_landing=5"
      target="_blank"
    >
      <span className="button-text">{gettext("Subscribe")}</span>
      <FontAwesomeIcon icon="envelope" />
    </Button>
  );
}

export default SubscribeButton;
