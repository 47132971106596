import { gql } from "@apollo/client";
import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import { toast } from "react-toastify";
import * as dateFns from "date-fns";
import fi from "date-fns/locale/fi";
import { useQuery } from "@apollo/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DateTimeRangeSelector, {
  usePersistentDateTimeRange,
} from "../../components/dates/DateTimeRangeSelector";
import apiClient from "../../api";
import { gettext } from "../../i18n";
import Form from "react-bootstrap/Form";
import { useModalContext } from "state/modal";
import {
  GetExistingDeletionRequests,
  GetExistingDeletionRequestsVariables,
  GetExistingDeletionRequests_datapoint_deletion_request,
} from "./__generated__/GetExistingDeletionRequests";
import { GetMeasurementStation_station_datasources } from "../../utils/__generated__/GetMeasurementStation";

import "./DataRemovalSettingForm.scss";
import DefaultTooltip from "../common/DefaultTooltip";

const GET_EXISTING_DELETION_REQUESTS = gql`
  query GetExistingDeletionRequests($sourceUUID: uuid!) {
    datapoint_deletion_request(where: { source_uuid: { _eq: $sourceUUID } }) {
      id
      lower_limit
      range_from
      select_spikes
      range_to
      spike_threshold
      timestamp
      hide
      upper_limit
    }
  }
`;

interface DataFilteringRange {
  startDate: Date;
  endDate: Date;
  hidden: boolean;
  upperLimit?: number;
  lowerLimit?: number;
  selectSpikes?: boolean;
  compareReadings?: number;
  spikeThresholdPct?: number;
  deletionRequestId?: number;
}

const getDataFilteringRange = (
  deletionRequest: GetExistingDeletionRequests_datapoint_deletion_request
): DataFilteringRange => {
  const {
    id,
    lower_limit,
    range_from,
    select_spikes,
    range_to,
    spike_threshold,
    hide,
    upper_limit,
  } = deletionRequest;
  return {
    startDate: new Date(range_from),
    endDate: new Date(range_to),
    hidden: hide,
    upperLimit: upper_limit,
    lowerLimit: lower_limit,
    selectSpikes: select_spikes,
    compareReadings: spike_threshold,
    deletionRequestId: id,
  };
};

/**
 * Send currently selected data filtering range to the server
 */
const onDataRemovalSubmit = async (
  selectedDataSource: GetMeasurementStation_station_datasources,
  filteringRange: DataFilteringRange,
  stationId: number,
  isDelete: boolean = false,
  reverse: boolean = false
) => {
  const resource_key = `${selectedDataSource?.type}-${selectedDataSource?.source_id}`;
  const action = isDelete ? "delete" : "hide";
  try {
    const response = await apiClient.request(
      `/organization-admin/data-filtering/${action}`,
      {
        method: "POST",
        data: {
          ...filteringRange,
          sourceUUID: selectedDataSource?.source_uuid,
          stationId,
          resource_key,
          reverse,
        },
      }
    );
    if (response.messages) {
      const displayToast = response.success ? toast.success : toast.error;
      for (let messageData of response.messages) {
        const { message, affected, left } = messageData;
        displayToast(gettext(message, affected, left));
      }
    } else if (response.success) {
      const successText = reverse
        ? "Data restored successfully"
        : "Data removed successfully";
      toast.success(gettext(successText));
    } else {
      toast.error(gettext("Action failed"));
    }
    return true;
  } catch (e) {
    console.error(e);
    toast.error(gettext("Error filtering data"));
  }
  return false;
};

interface AffectedMetadata {
  affectedDatapoints: number;
  rangeDatapoints: number;
}
interface PreviewAffectedProps {
  selectedDataSource: GetMeasurementStation_station_datasources;
  filteringRange: DataFilteringRange;
  reverse?: boolean;
}
const PreviewAffected: React.FC<PreviewAffectedProps> = ({
  selectedDataSource,
  filteringRange,
  reverse = false,
}: PreviewAffectedProps) => {
  const [metadata, setMetadata] = React.useState<
    AffectedMetadata | undefined
  >();

  React.useEffect(() => {
    const fetchMetadata = async () => {
      const resource_key = `${selectedDataSource?.type}-${selectedDataSource?.source_id}`;
      let response;
      try {
        response = await apiClient.request(
          `/organization-admin/data-filtering/preview-affected`,
          {
            method: "POST",
            data: {
              ...filteringRange,
              resource_key,
              reverse,
            },
          }
        );
      } catch (e) {
        console.error(e);
        return false;
      }
      if (!response.metadata) {
        return false;
      }
      const { metadata } = response;
      return metadata;
    };

    const updateMetadata = async (attempt = 1) => {
      const metadata = await fetchMetadata();
      if (metadata === false && attempt < 5) {
        setTimeout(() => updateMetadata((attempt += 1)), 2000);
        return;
      }
      if (metadata === false) {
        toast.error(gettext("Error identifying affected datapoints"));
        setMetadata({
          affectedDatapoints: 0,
          rangeDatapoints: 0,
        });
        return;
      }

      setMetadata({
        affectedDatapoints: metadata.num_affected,
        rangeDatapoints: metadata.range_datapoints,
      });
    };
    if (metadata === undefined) {
      updateMetadata();
    }
  }, [selectedDataSource, filteringRange, metadata, setMetadata, reverse]);

  if (metadata === undefined) {
    return (
      <div className="preview-affected">
        <Spinner animation="border" role="status" />
        <br />
        <span>Loading affected datapoints...</span>
      </div>
    );
  }

  return (
    <span>
      Affected datapoints:
      <br />
      {metadata.affectedDatapoints === 0 ? (
        <span className="no-datapoints">NONE</span>
      ) : (
        <>
          <b>{metadata.affectedDatapoints}</b>
          {!reverse && <b> / {metadata.rangeDatapoints}</b>}
        </>
      )}
    </span>
  );
};

interface DataRemovalSettingFormProps {
  stationId: number;
}

const DataRemovalSettingForm: React.FC<DataRemovalSettingFormProps> = ({
  stationId,
}: DataRemovalSettingFormProps) => {
  const { setActiveModal, selectedDataSource } = useModalContext();
  const onHide = () => setActiveModal(null);
  const [selectedRanges, setSelectedRanges] = React.useState<
    DataFilteringRange[]
  >([]);
  const [validated, setValidated] = React.useState<boolean>(false);
  const { data: deletionRequestData, refetch: refetchDeletionRequests } =
    useQuery<GetExistingDeletionRequests, GetExistingDeletionRequestsVariables>(
      GET_EXISTING_DELETION_REQUESTS,
      {
        variables: {
          sourceUUID: selectedDataSource?.source_uuid,
        },
      }
    );
  const setValue = (key: string, value: any) => {
    setSelectedRange({
      ...selectedRange,
      [key]: value,
    });
  };

  const deletionRequests: GetExistingDeletionRequests_datapoint_deletion_request[] =
    deletionRequestData?.datapoint_deletion_request ?? [];

  const [dateRange, setDateRange] = usePersistentDateTimeRange();
  const [startDate, endDate] = dateRange;

  const defaultRange = {
    startDate,
    endDate,
    hidden: false,
    upperLimit: undefined,
    lowerLimit: undefined,
    selectSpikes: false,
    compareReadings: undefined,
    spikeThresholdPct: undefined,
  };

  const [selectedRange, setSelectedRange] = React.useState<DataFilteringRange>({
    ...defaultRange,
  });

  const resetForm = () => {
    setValidated(false);
    setSelectedRange({ ...defaultRange });
  };
  const addSelectedRange = () => {
    const newRanges = [...selectedRanges, selectedRange];
    setSelectedRanges(newRanges);
    resetForm();
  };
  const formattedDate = (date: Date) =>
    dateFns.format(date, "dd.MM.yyyy HH:mm", {
      locale: fi,
    });
  const handleSubmit = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    // Show any potential input errors
    setValidated(true);
    const form = event.currentTarget;

    if (form.checkValidity()) {
      addSelectedRange();
    }
  };
  if (!selectedDataSource) {
    return <Spinner animation="border" role="status" />;
  }
  return (
    <Modal
      animation
      show
      onHide={() => {
        setValidated(false);
        setSelectedRanges([]);
        onHide();
      }}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Filter Data: {selectedDataSource.name}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="config-title data-title">
          Select date range
          <DefaultTooltip
            popHeader="Data range"
            popBody={
              <>
                <p>Select the date range to hide or delete</p>
              </>
            }
          />
        </div>
        <DateTimeRangeSelector
          range={dateRange}
          setRange={(newRange: [Date, Date]) => {
            const [startDate, endDate] = newRange;
            setSelectedRange({
              ...selectedRange,
              startDate,
              endDate,
            });
            setDateRange(newRange);
            setValidated(false);
          }}
          quickChoices={[
            ["6 h", 6 * 60 * 60 * 1000],
            ["24 h", 24 * 60 * 60 * 1000],
            ["7 d", 7 * 24 * 60 * 60 * 1000],
            ["30 d", 30 * 24 * 60 * 60 * 1000],
          ]}
        />
        <div className="selected-data-filters">
          <div className="config-title filters-title">
            Filter data
            <DefaultTooltip
              popHeader="Filter data"
              popBody={
                <>
                  <p>
                    Enter the upper/lower limits or select spike detection
                    parameters to filter data. If no "Readings in median
                    calculation" value is provided all readings from the
                    selected date range are used for median calculation.
                  </p>
                </>
              }
            />
          </div>
          <Form
            className="filter-data-form"
            noValidate
            validated={validated}
            onSubmit={handleSubmit}
          >
            <div className="filters-inputs">
              <Form.Control
                type="number"
                value={selectedRange.lowerLimit ?? ""}
                placeholder={gettext("Select values below value (optional)")}
                onChange={(e: any) => {
                  setValue("lowerLimit", e.target.value);
                }}
              />

              <Form.Control
                type="number"
                value={selectedRange.upperLimit ?? ""}
                placeholder={gettext("Select values above value (optional)")}
                onChange={(e: any) => {
                  setValue("upperLimit", e.target.value);
                }}
              />
            </div>
            <Form.Check
              checked={selectedRange.selectSpikes ?? false}
              type="switch"
              id="custom-switch-lower_limit_active"
              label={gettext("Select spikes")}
              onChange={(e: any) => {
                setValue("selectSpikes", e.target.checked);
              }}
              className="select-spikes"
            />
            {selectedRange.selectSpikes && (
              <div className="filters-inputs">
                <Form.Control
                  required
                  type="number"
                  value={selectedRange.spikeThresholdPct ?? ""}
                  placeholder={gettext("Spike threshold % (above median)")}
                  onChange={(e: any) => {
                    setValue("spikeThresholdPct", e.target.value);
                  }}
                />

                <Form.Control
                  type="number"
                  value={selectedRange.compareReadings ?? ""}
                  placeholder={gettext(
                    "Readings in median calculation (optional)"
                  )}
                  onChange={(e: any) => {
                    setValue("compareReadings", e.target.value);
                  }}
                />
              </div>
            )}
            <Button
              className="filters-submit-button"
              type="submit"
              variant="primary"
            >
              Select
            </Button>
          </Form>
        </div>

        <div className="selected-data-row">
          {!!selectedRanges.length && (
            <div className="selected-data-title">Selected data</div>
          )}
          {selectedRanges.map((range, idx) => (
            <div key={`selected_${idx}`} className="selected-data">
              <div className="selected-date">
                <FontAwesomeIcon
                  icon="remove"
                  onClick={() => {
                    setValidated(false);
                    setSelectedRanges([
                      ...selectedRanges.filter(
                        (r: DataFilteringRange) => r !== range
                      ),
                    ]);
                  }}
                />
                {formattedDate(range.startDate)} -{" "}
                {formattedDate(range.endDate)}
              </div>
              <div className="selected-filters">
                {!!range.upperLimit && (
                  <span>Upper limit: {range.upperLimit}</span>
                )}
                {!!range.lowerLimit && (
                  <span>Lower limit: {range.lowerLimit}</span>
                )}
                {range.selectSpikes && (
                  <span>
                    {gettext(
                      range.compareReadings
                        ? "Spikes: more than %1% over %2 readings"
                        : "Spikes: more than %1% over all readings",
                      range.spikeThresholdPct,
                      range.compareReadings
                    )}
                  </span>
                )}
                <PreviewAffected
                  selectedDataSource={selectedDataSource}
                  filteringRange={range}
                />
              </div>
              <div className="selected-buttons">
                <>
                  <Button
                    type="button"
                    onClick={async () => {
                      if (
                        window.confirm(
                          gettext(
                            "Are you sure you want to hide the selected data points?"
                          )
                        )
                      ) {
                        setValue("hidden", true);
                        setValidated(false);
                        if (
                          await onDataRemovalSubmit(
                            selectedDataSource,
                            range,
                            stationId,
                            false,
                            false
                          )
                        ) {
                          setSelectedRanges([
                            ...selectedRanges.filter(
                              (r: DataFilteringRange) => r !== range
                            ),
                          ]);
                          refetchDeletionRequests();
                        }
                      }
                    }}
                  >
                    Hide
                  </Button>
                  <Button
                    type="button"
                    variant="danger"
                    onClick={async () => {
                      if (
                        window.confirm(
                          gettext(
                            "Are you sure you want to delete the selected data points?"
                          )
                        )
                      ) {
                        if (
                          await onDataRemovalSubmit(
                            selectedDataSource,
                            range,
                            stationId,
                            true,
                            false
                          )
                        ) {
                          setSelectedRanges([
                            ...selectedRanges.filter(
                              (r: DataFilteringRange) => r !== range
                            ),
                          ]);
                          refetchDeletionRequests();
                        }
                      }
                    }}
                  >
                    Delete
                  </Button>
                </>
              </div>
            </div>
          ))}
        </div>

        {!!deletionRequests.length && (
          <div className="hidden-data-row">
            <div className="hidden-data-title">Filtered data</div>
            {deletionRequests.map((deletionRequest, idx) => {
              const range = getDataFilteringRange(deletionRequest);
              return (
                <div key={`hidden_${idx}`} className="hidden-data">
                  {formattedDate(range.startDate)} -{" "}
                  {formattedDate(range.endDate)}
                  <div className="hidden-filters">
                    {!!range.upperLimit && (
                      <span>Upper limit: {range.upperLimit}</span>
                    )}
                    {!!range.lowerLimit && (
                      <span>Lower limit: {range.lowerLimit}</span>
                    )}
                    {range.selectSpikes && <span>Spikes</span>}
                    <PreviewAffected
                      reverse
                      selectedDataSource={selectedDataSource}
                      filteringRange={range}
                    />
                  </div>
                  <Form.Check
                    defaultChecked={true}
                    type="switch"
                    label={gettext("Hidden")}
                    onChange={async () => {
                      if (
                        window.confirm(
                          gettext(
                            "Are you sure you want to unhide the selected data points?"
                          )
                        )
                      ) {
                        await onDataRemovalSubmit(
                          selectedDataSource,
                          range,
                          stationId,
                          false,
                          true
                        );
                        refetchDeletionRequests();
                      }
                    }}
                  />
                </div>
              );
            })}
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => {
            setSelectedRanges([]);
            onHide();
          }}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DataRemovalSettingForm;
