import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Form } from "react-bootstrap";
import {
  usePasswordRecoveryRequest,
  usePasswordRecoverySubmit,
} from "../../state/auth";

const PasswordRecoveryForm = () => {
  const navigate = useNavigate();
  const requestRecovery = usePasswordRecoveryRequest();
  const submitRecovery = usePasswordRecoverySubmit();

  // State
  const [formData, setFormData] = useState({
    email: "",
    tokenRequested: false,
    recoveryToken: "",
    password: "",
    confirmPassword: "",
  });

  // Handler
  const doRequestRecovery = async () => {
    const action = formData.tokenRequested ? submitRecovery : requestRecovery;
    const successMessage = formData.tokenRequested
      ? "Password changed successfully"
      : "Password recovery token requested, please check your inbox";
    try {
      const { success } = await action({
        ...formData,
      });

      if (success) {
        toast.success(successMessage, {
          theme: "colored",
        });

        setFormData({
          ...formData,
          email: formData.tokenRequested ? "" : formData.email,
          tokenRequested: !formData.tokenRequested,
          password: "",
          confirmPassword: "",
        });

        if (formData.tokenRequested) {
          navigate("/login");
        }
      }
    } catch (e: any) {
      toast.error(e.message.toString(), {
        theme: "colored",
      });
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    doRequestRecovery();
  };

  const setFormValue = (key: string, value: any) => {
    setFormData({
      ...formData,
      [key]: value,
    });
  };
  const handleFieldChange = (e: any) => {
    setFormValue(e.target.name, e.target.value);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group className="mb-3">
        <Form.Label>Email address</Form.Label>
        <Form.Control
          placeholder="Email address"
          value={formData.email}
          name="email"
          onChange={(e: any) => {
            // Reset all entered values if a new email is provided
            setFormData({
              ...formData,
              email: e.target.value,
              tokenRequested: false,
              recoveryToken: "",
              password: "",
              confirmPassword: "",
            });
          }}
          type="email"
        />
      </Form.Group>

      {formData.tokenRequested && (
        <>
          <Form.Group className="mb-3">
            <Form.Label>Recovery token</Form.Label>
            <Form.Control
              placeholder="Recovery token"
              value={formData.recoveryToken}
              name="recoveryToken"
              onChange={handleFieldChange}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>New password</Form.Label>
            <Form.Control
              placeholder="New password"
              value={formData.password}
              name="password"
              onChange={handleFieldChange}
              type="password"
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Control
              placeholder="Confirm password"
              value={formData.confirmPassword}
              name="confirmPassword"
              onChange={handleFieldChange}
              type="password"
            />
          </Form.Group>
        </>
      )}

      <Form.Group>
        <Button
          type="submit"
          color="primary"
          className="mt-3 w-100"
          disabled={!formData.email}
        >
          {formData.tokenRequested ? (
            <>Update password</>
          ) : (
            <>Request recovery token</>
          )}
        </Button>
      </Form.Group>
    </Form>
  );
};

PasswordRecoveryForm.propTypes = {};

PasswordRecoveryForm.defaultProps = {};

export default PasswordRecoveryForm;
