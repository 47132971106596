import { gql } from "@apollo/client";

export const GET_WORLDSENSING_NETWORKS_LIST = gql`
  query GetWorldsensingNetworksQuery {
    worldsensing_network(order_by: { network_id: asc }) {
      network_id
      active
      username
    }
  }
`;
