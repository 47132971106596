import * as React from "react";
import classNames from "classnames";
import Flex from "theme/components/common/Flex";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { toast } from "react-toastify";
import Button from "react-bootstrap/Button";
import { useQuery } from "@apollo/client";
import { Form } from "react-bootstrap";
import {
  faTrash,
  faEye,
  faEyeSlash,
  faPencil,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useParams } from "react-router-dom";
import { gettext } from "i18n";
import { useAuth, isOrgAdmin } from "state/auth";
import StationDashboard from "dashboards/dashboardComponents/StationDashboard";
import PageHeader from "../../theme/components/common/PageHeader";
import AddWidgetForm from "../../components/modals/AddWidgetForm";
import { getFormValueHandler } from "utils/forms";
import AddDashboardStationForm from "../../components/modals/AddDashboardStationForm";
import {
  GetOrganizations,
  GetOrganizations_organization,
} from "utils/__generated__/GetOrganizations";
import DateTimeRangeSelector, {
  usePersistentDateTimeRange,
  usePersistentIntervalUnit,
  // IntervalUnitSelector,
} from "components/dates/DateTimeRangeSelector";
import { useModalContext } from "state/modal";
import { IntervalUnit } from "components/dates/DateTimeRangeSelector";
import {
  GET_MEASUREMENT_STATION_META,
  GET_DASHBOARD_STRUCTURE,
} from "utils/stations";
import { GET_ORGANIZATIONS } from "utils/users";
import apiClient from "../../api";
import { useIsAdmin } from "../../state/auth";
import { GetMeasurementStation_station } from "../../utils/__generated__/GetMeasurementStation";
import {
  GetDashboardStructure,
  GetDashboardStructureVariables,
} from "utils/__generated__/GetDashboardStructure";
import {
  GetMeasurementStationMetaQuery,
  GetMeasurementStationMetaQueryVariables,
} from "utils/__generated__/GetMeasurementStationMetaQuery";
import "./Dashboard.scss";

const intervalChoices: [string, number][] = [
  ["3 h", 3 * 60 * 60 * 1000],
  ["24 h", 24 * 60 * 60 * 1000],
  ["7 d", 7 * 24 * 60 * 60 * 1000],
  ["30 d", 30 * 24 * 60 * 60 * 1000],
  [gettext("All"), 100 * 365 * 24 * 60 * 60 * 1000],
];

interface DashboardStationStructure {
  identifier: string;
  widget_rows: any[];
}
interface DashboardStationProps {
  station: DashboardStationStructure;
  startDate: Date;
  endDate: Date;
  intervalUnit: IntervalUnit;
  setChangesMade: React.Dispatch<React.SetStateAction<boolean>>;
  editedStructure: any[] | null;
  setEditedStructure: React.Dispatch<React.SetStateAction<any[] | null>>;
  editWidgets?: boolean;
}

const DashboardStation: React.FC<DashboardStationProps> = ({
  station,
  startDate,
  endDate,
  intervalUnit,
  setChangesMade,
  editedStructure,
  setEditedStructure,
  editWidgets = false,
}: DashboardStationProps) => {
  const { identifier } = station;
  const { data } = useQuery<
    GetMeasurementStationMetaQuery,
    GetMeasurementStationMetaQueryVariables
  >(GET_MEASUREMENT_STATION_META, {
    variables: {
      identifier,
    },
  });
  const stationMeta = data?.station?.[0];
  const isAdmin = useIsAdmin(stationMeta as GetMeasurementStation_station);
  const canEditWidgets = editWidgets && isAdmin;

  if (!stationMeta) {
    return <></>;
  }

  const removeStationFromStructure = () => {
    const newStructure =
      editedStructure?.filter((s) => s.station.identifier !== identifier) ?? [];
    setEditedStructure(newStructure);
    setChangesMade(true);
  };

  const toggleStationTitleHidden = (hide: boolean) => {
    const newStructure =
      editedStructure?.map((s) => {
        if (s.station.identifier === identifier) {
          return {
            ...s,
            hiddenTitle: hide,
          };
        }
        return s;
      }) ?? [];
    setEditedStructure(newStructure);
    setChangesMade(true);
  };

  const stationStructure = editedStructure?.find(
    (s) => s.station.identifier === identifier
  );

  const hiddenTitle = !!stationStructure?.hiddenTitle;
  return (
    <React.Fragment key={identifier}>
      {(!hiddenTitle || canEditWidgets) && (
        <Row
          className={classNames("g-3 mb-3", {
            "hidden-title": canEditWidgets && hiddenTitle,
          })}
        >
          <Col>
            <Card>
              <Card.Body>
                <Row>
                  <Col xs={12} sm={12}>
                    <div className="dashboard-station-title">
                      <strong>{stationMeta.name}</strong>
                      {canEditWidgets && (
                        <div className="station-title-action-buttons">
                          <div
                            className="dashboard-widget-button"
                            onClick={() =>
                              toggleStationTitleHidden(!hiddenTitle)
                            }
                          >
                            <FontAwesomeIcon
                              icon={hiddenTitle ? faEyeSlash : faEye}
                            />
                          </div>
                          <div
                            className="dashboard-widget-button"
                            onClick={removeStationFromStructure}
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </div>
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}
      <Row className={"g-3 mb-3"}>
        <Col>
          <StationDashboard
            stationIdentifier={identifier}
            stationId={stationMeta.id}
            editedStructure={editedStructure}
            setEditedStructure={setEditedStructure}
            sourceId={stationMeta.primary_datasource?.source_id!}
            sourceType={stationMeta.primary_datasource?.type!}
            startDate={startDate}
            endDate={endDate}
            interval={intervalUnit}
            intervalChoices={intervalChoices}
            setChangesMade={setChangesMade}
            canEditWidgets={canEditWidgets}
          />
        </Col>
      </Row>
    </React.Fragment>
  );
};

interface DashboardDetailsPageProps {
  editWidgets?: boolean;
}
const DashboardDetailsPage: React.FC<DashboardDetailsPageProps> = ({
  editWidgets = false,
}: DashboardDetailsPageProps) => {
  const { id } = useParams();
  const { user } = useAuth();
  const { activeModal, modalPayload, setActiveModal } = useModalContext();
  const [dateRange, setDateRange] = usePersistentDateTimeRange();
  const [submitDisabled, setSubmitDisabled] = React.useState<boolean>(false);
  const [changesMade, setChangesMade] = React.useState<boolean>(false);
  const [renamingDashboard, setRenamingDashboard] =
    React.useState<boolean>(false);
  const [editedStructure, setEditedStructure] = React.useState<any[] | null>(
    null
  );
  const [dashboardEditedValues, setDashboardEditedValues] = React.useState<
    Record<string, any>
  >({});
  const setValueHandler = getFormValueHandler(
    dashboardEditedValues,
    setDashboardEditedValues
  );
  const showAddStation = React.useMemo(
    () => (e: any) => {
      setActiveModal("add-dashboard-station");
    },
    [setActiveModal]
  );
  const [intervalUnit, setIntervalUnit] = usePersistentIntervalUnit({
    index: 0,
    value: intervalChoices[0][1],
  });
  const [startDate, endDate] = dateRange;
  const { data, refetch: refetchDashboardStructure } = useQuery<
    GetDashboardStructure,
    GetDashboardStructureVariables
  >(GET_DASHBOARD_STRUCTURE, {
    variables: {
      id: parseInt(id ?? "", 10),
    },
  });
  const { data: orgData } = useQuery<GetOrganizations>(GET_ORGANIZATIONS, {
    skip: !user?.admin,
  });

  const thisMonthMicroseconds = endDate.getTime() - new Date(endDate.getFullYear(), endDate.getMonth(), 1).getTime();
  const thisYearMicroseconds = endDate.getTime() - new Date(endDate.getFullYear(), 0, 1).getTime();

  const dateRangeQuickChoices: [string, number | Date][] = [
    ["24 h", 24 * 60 * 60 * 1000],
    ["7 d", 7 * 24 * 60 * 60 * 1000],
    ["30 d", 30 * 24 * 60 * 60 * 1000],
    ["This month", thisMonthMicroseconds],
    ["This year", thisYearMicroseconds],
    [gettext("All"), new Date(2022, 0, 1)],
  ];

  const dashboard = data?.dashboard?.[0];
  const structure: any[] = dashboard?.structure;
  React.useEffect(() => {
    if (editedStructure === null || editedStructure === undefined) {
      setEditedStructure(dashboard?.structure);
    }
  }, [editedStructure, setEditedStructure, dashboard]);

  if (!dashboard) {
    return (
      <div>
        <PageHeader title="Dashboard not found" className="mb-3" />
      </div>
    );
  }
  const canEditWidgets = !!(
    editWidgets &&
    (user?.admin ||
      (dashboard.organization_id &&
        isOrgAdmin(user, dashboard.organization_id)))
  );

  if (!structure) {
    return (
      <div>
        <PageHeader title={dashboard.name} className="mb-3" />
        <div className="alert alert-info">
          Dashboard has no structure defined.
        </div>
      </div>
    );
  }

  const toggleDashboardRename = () => {
    setRenamingDashboard(!renamingDashboard);
  };

  const onCancelEditingStructure = (e: any) => {
    e.preventDefault();
    if (submitDisabled) {
      return;
    }
    setEditedStructure(null);
    setChangesMade(false);
    setDashboardEditedValues({});
    setRenamingDashboard(false);
  };

  const cleanEditedStructure = (structure: any[] | null) => {
    if (structure === null) {
      return [];
    }
    // Remove empty "widgets" arrays from "widget_rows" in the structure:
    const newStructure = structure.map((dashboardStructure) => {
      const newWidgetRows = dashboardStructure.station.widget_rows.filter(
        (widgetRow: any) => widgetRow.widgets?.length > 0
      );
      return {
        ...dashboardStructure,
        station: {
          ...dashboardStructure.station,
          widget_rows: newWidgetRows,
        },
      };
    });
    return newStructure;
  };

  const onSaveEditedStructure = async (e: any, newStructure?: any[] | null) => {
    e.preventDefault();
    if (submitDisabled) {
      return;
    }
    setSubmitDisabled(true);
    const saveStructure = !!newStructure ? newStructure : editedStructure;
    const cleanStructure = cleanEditedStructure(saveStructure);

    apiClient
      .request("/organization-admin/update-dashboard-structure", {
        method: "POST",
        data: {
          dashboardId: dashboard.id,
          newStructure: [...(cleanStructure ?? [])],
          dashboardMeta: {
            ...dashboardEditedValues,
          },
        },
      })
      .then(async () => {
        toast.success(gettext("Dashboard widgets updated successfully"));
        setChangesMade(false);
        await refetchDashboardStructure();
        setEditedStructure(null);
        setDashboardEditedValues({});
        setRenamingDashboard(false);
      })
      .catch((e: any) => {
        console.error(e);
        toast.error(
          `${gettext("Failed to update dashboard widgets")} ${gettext(
            e.toString()
          )}`
        );
      });
    setSubmitDisabled(false);
  };

  const stationsExist = (structure?.length ?? 0) > 0;
  const organizationList: GetOrganizations_organization[] | undefined =
    orgData?.organization ?? [];
  const dashboardOrg = organizationList?.find(
    (org) => org.id === dashboard.organization_id
  );
  return (
    <>
      {activeModal === "add-dashboard-widget" && (
        <AddWidgetForm
          stationIdentifier={modalPayload.stationIdentifier}
          editedStructure={editedStructure}
          setEditedStructure={setEditedStructure}
          setChangesMade={setChangesMade}
        />
      )}
      {activeModal === "add-dashboard-station" && (
        <AddDashboardStationForm
          editedStructure={editedStructure}
          setEditedStructure={setEditedStructure}
          onSaveEditedStructure={onSaveEditedStructure}
        />
      )}
      <div
        className={classNames({
          "editable-dashboard-container": canEditWidgets,
        })}
      >
        <PageHeader
          title={
            <div className="dashboard-header-title">
              <div>
                {renamingDashboard ? (
                  <Form.Control
                    type="text"
                    value={dashboardEditedValues.name ?? dashboard.name}
                    onChange={(e) => {
                      if (!changesMade) {
                        setChangesMade(true);
                      }
                      setValueHandler("name")(e);
                    }}
                  />
                ) : (
                  dashboard.name
                )}
                {canEditWidgets && user?.admin && (
                  <div
                    className="dashboard-widget-button dashboard-rename-button"
                    onClick={toggleDashboardRename}
                  >
                    <FontAwesomeIcon icon={faPencil} />
                  </div>
                )}
              </div>
              {canEditWidgets && user?.admin && (
                <div className="dashboard-organization-picker">
                  <span>Visible to:</span>
                  <Form.Select
                    aria-label="Organization of the new dashboard"
                    onChange={(e: any) => {
                      if (!changesMade) {
                        setChangesMade(true);
                      }
                      setValueHandler("organization_id")(e);
                    }}
                    value={
                      dashboardEditedValues.organization_id ??
                      dashboardOrg?.id ??
                      ""
                    }
                  >
                    <option value={0}>
                      {gettext("Global administrators")}
                    </option>
                    {organizationList?.map(
                      (organization: GetOrganizations_organization) => (
                        <option
                          key={`org_${organization.id}`}
                          value={organization.id}
                        >
                          {organization.name}
                        </option>
                      )
                    )}
                  </Form.Select>
                </div>
              )}
            </div>
          }
          className="mb-3"
        ></PageHeader>
        <Row className={"g-3 mb-3"}>
          <Col>
            <Card>
              <Card.Body>
                <Row>
                  <Col xs={12} sm={6}>
                    <div>
                      <strong>Show measurements between</strong>
                    </div>
                    <DateTimeRangeSelector
                      range={dateRange}
                      setRange={setDateRange}
                      quickChoices={dateRangeQuickChoices}
                    />
                    {/* TODO: finish me */
                    /* <div>
                      <strong>Measurement interval</strong>
                    </div>
                    <IntervalUnitSelector
                      selectedUnit={intervalUnit}
                      setSelectedUnit={setIntervalUnit}
                      quickChoices={intervalChoices}
                    /> */}
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        {canEditWidgets && stationsExist && (
          <div className="alert alert-info">
            <strong>Editing dashboard structure</strong>
            <br />
            <small>
              Drag and drop widgets to change their position. To scroll the page
              up or down while dragging a widget, move the cursor close to the
              dashed border.
            </small>
          </div>
        )}
        {canEditWidgets && !stationsExist && (
          <div className="alert alert-info">
            <strong>Editing dashboard structure</strong>
            <br />
            <small>
              No stations exist on this dashboard. Add a station to continue
              creating new widgets.
            </small>
          </div>
        )}
        {editedStructure?.map((dashboardStructure) => (
          <DashboardStation
            key={`dashboard_station_${dashboardStructure.station?.identifier}`}
            station={dashboardStructure.station}
            startDate={startDate}
            endDate={endDate}
            intervalUnit={intervalUnit}
            editWidgets={canEditWidgets}
            editedStructure={editedStructure}
            setEditedStructure={setEditedStructure}
            setChangesMade={setChangesMade}
          />
        ))}
        {canEditWidgets && (
          <Button
            variant="primary"
            size="sm"
            onClick={showAddStation}
            className="add-station-button"
          >
            Add station
          </Button>
        )}
        {canEditWidgets && (
          <div className={`floating-footer ${changesMade ? "visible" : ""}`}>
            <Flex justifyContent="md-center">
              <Button
                variant="outline-primary"
                size="sm"
                onClick={onCancelEditingStructure}
                disabled={submitDisabled}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                size="sm"
                onClick={onSaveEditedStructure}
                disabled={submitDisabled}
              >
                Save changes
              </Button>
            </Flex>
          </div>
        )}
      </div>
    </>
  );
};

export default DashboardDetailsPage;
