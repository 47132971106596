import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Spinner from "react-bootstrap/Spinner";
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";
import { toast } from "react-toastify";
import DefaultTooltip from "../common/DefaultTooltip";
import apiClient from "../../api";
import { GetDatasourcesByUUID_source } from "utils/__generated__/GetDatasourcesByUUID";
import { GetMeasurementStation_station } from "utils/__generated__/GetMeasurementStation";
import { useModalContext } from "state/modal";
import { gettext } from "../../i18n";
import "./ChartAxisSettingsForm.scss";

const SCALING_TYPE_AUTO = 0;
const SCALING_TYPE_MANUAL = 1;

const DEFAULT_SETTING_VALUES = {};

interface ChartAxisSettingsFormProps {
  source: GetDatasourcesByUUID_source;
  station: GetMeasurementStation_station;
}

const ChartAxisSettingsForm = ({
  source,
  station,
}: ChartAxisSettingsFormProps) => {
  const data = {};
  const loading = false;

  const sourceUUID = source.source_uuid;
  const { setActiveModal } = useModalContext();
  const onHide = () => setActiveModal(null);
  const [axisSettings, setAxisSettings] = React.useState<Record<string, any>>(
    DEFAULT_SETTING_VALUES
  );
  const [modalDisableSave, setModalDisableSave] =
    React.useState<boolean>(false);

  const stationWidget = station.station_widgets.find(
    (widget) => widget.base_source_uuid === sourceUUID
  );

  React.useEffect(() => {
    if (stationWidget?.axis_scale_manual === undefined) {
      return;
    }
    if (axisSettings.scalingType === undefined) {
      setAxisSettings({
        ...axisSettings,
        rangeMin: stationWidget.axis_scale_min,
        rangeMax: stationWidget.axis_scale_max,
        scalingType: stationWidget.axis_scale_manual
          ? SCALING_TYPE_MANUAL
          : SCALING_TYPE_AUTO,
      });
    }
  }, [stationWidget, axisSettings]);

  const closeModal = () => {
    setAxisSettings(DEFAULT_SETTING_VALUES);
    setModalDisableSave(true);
    onHide();
  };

  const saveChartSettings = () => {
    if (!axisSettings) {
      return;
    }
    if (
      axisSettings.scalingType === SCALING_TYPE_MANUAL &&
      (axisSettings.rangeMin === undefined ||
        axisSettings.rangeMax === undefined ||
        !String(axisSettings.rangeMin).length ||
        !String(axisSettings.rangeMax).length)
    ) {
      toast.error(gettext("Please enter min and max values"));
      return;
    }
    setModalDisableSave(true);
    const values = { ...axisSettings };
    const requestPath = "/organization-admin/station-widget-toggle-scaling";

    apiClient
      .request(requestPath, {
        method: "POST",
        data: {
          stationId: station.id,
          source_uuid: sourceUUID,
          ...values,
        },
      })
      .then(() => {
        toast.success(gettext("Success"));
        setAxisSettings(DEFAULT_SETTING_VALUES);
        setModalDisableSave(false);
        closeModal();
      })
      .catch((e) => {
        toast.error(gettext(e.message.toString()));
        setModalDisableSave(false);
      });
  };

  const setAnnotationValue = (key: string, value: any) => {
    setAxisSettings({
      ...axisSettings,
      [key]: value,
    });
  };

  if (loading) {
    return <Spinner animation="border" size="sm" />;
  }

  if (!data) {
    return <div>No data available</div>;
  }

  return (
    <Modal
      show
      onHide={closeModal}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Axis settings
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table responsive>
          <tbody>
            <tr>
              <th>
                <Row>
                  <Col>Vertical axis type</Col>
                  <Col>
                    <DefaultTooltip
                      popHeader={gettext("Vertical axis type")}
                      popBody={
                        <>
                          <p>
                            Automatic type will scale to include all visible
                            datapoints and annotations. Manual type allows you
                            to set the axis range manually.
                          </p>
                        </>
                      }
                    />
                  </Col>
                </Row>
              </th>
              <td>
                <ToggleButtonGroup
                  id="measurement-group"
                  name="measurement-group"
                  type="radio"
                  value={axisSettings?.scalingType ?? SCALING_TYPE_AUTO}
                  onChange={(value) => {
                    setAnnotationValue("scalingType", value);
                  }}
                >
                  <ToggleButton
                    size="sm"
                    id={`vertical-axis-type-value`}
                    variant="outline-primary"
                    value={1}
                  >
                    Manual
                  </ToggleButton>
                  <ToggleButton
                    size="sm"
                    id={`vertical-axis-type-timestamp`}
                    variant="outline-primary"
                    value={0}
                  >
                    Automatic
                  </ToggleButton>
                </ToggleButtonGroup>
              </td>
            </tr>
            {axisSettings?.scalingType === 1 && (
              <>
                <tr>
                  <th>
                    <Row>
                      <Col>Min</Col>
                    </Row>
                  </th>
                  <td>
                    <Form.Group>
                      <Form.Control
                        value={axisSettings?.rangeMin ?? ""}
                        placeholder={gettext("Enter minimum value")}
                        onChange={(e) =>
                          setAnnotationValue("rangeMin", e.target.value)
                        }
                      />
                    </Form.Group>
                  </td>
                </tr>
                <tr>
                  <th>
                    <Row>
                      <Col>Max</Col>
                    </Row>
                  </th>
                  <td>
                    <Form.Group>
                      <Form.Control
                        value={axisSettings?.rangeMax ?? ""}
                        placeholder={gettext("Enter maximum value")}
                        onChange={(e) =>
                          setAnnotationValue("rangeMax", e.target.value)
                        }
                      />
                    </Form.Group>
                  </td>
                </tr>
              </>
            )}
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => {
            setAxisSettings(DEFAULT_SETTING_VALUES);
            onHide();
          }}
        >
          Close
        </Button>

        {/* <Button type="button" variant="danger">
          Delete
        </Button> */}

        <Button
          type="button"
          onClick={saveChartSettings}
          disabled={modalDisableSave}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ChartAxisSettingsForm;
