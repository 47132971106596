/***
 *  Nose reduction filter functionality for the charts
 **/

const getMedian = (inputValues: number[], windowSize: number): number[] => {
  function median(inputValues: number[]) {
    const s = inputValues.slice().sort(function (a, b) {
      return a - b;
    });
    return s[Math.floor((s.length - 1) / 2)];
  }

  windowSize = windowSize || 3;

  if (inputValues.length < windowSize) {
    return inputValues;
  }

  const f = [];
  const w = [];

  w.push(inputValues[0]);
  for (let i = 0; i < inputValues.length; i++) {
    if (inputValues.length - 1 >= i + Math.floor(windowSize / 2)) {
      w.push(inputValues[i + Math.floor(windowSize / 2)]);
    }
    f.push(median(w));
    if (i >= Math.floor(windowSize / 2)) {
      w.shift();
    }
  }

  return f;
};

export default getMedian;
