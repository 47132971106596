import dayjs from "dayjs";
import { useQuery } from "@apollo/client";
import { useNavigate, useParams } from "react-router-dom";
import PageHeader from "../theme/components/common/PageHeader";
import * as React from "react";
import Table from "react-bootstrap/Table";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import DatePicker from "react-datepicker";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { toast } from "react-toastify";
import FormattedFormControl from "components/form/FormattedFormControl";
import apiClient from "../api";
import { GetUnits_unit } from "../utils/__generated__/GetUnits";
import { useSortedUnits } from "../hooks/units";
import {
  GET_MANUAL_POINT_SETS,
  GET_MANUAL_POINT_SETS_COMBINED,
} from "../utils/stations";
import { getFormValueHandler } from "../utils/forms";
import { gettext } from "../i18n";
import {
  GetManualPointSetsQuery,
  GetManualPointSetsQueryVariables,
  GetManualPointSetsQuery_manual_point_set as ManualPointSet,
  GetManualPointSetsQuery_manual_point_set_datapoints as ManualPointSetDatapoint,
} from "../utils/__generated__/GetManualPointSetsQuery";
import BackToLink from "components/common/BackToLink";
import PermissionContextCheck from "components/auth/PermissionContextCheck";
import DefaultTooltip from "components/common/DefaultTooltip";
import {
  parseNumericFormValues,
  formatNumberLocale,
  parseNumberInput,
} from "utils/formatting";
import { UploadedDatapoint, onInputFileChange } from "utils/xlsx";
import "./MeasurementStationManualDatapointsPage.scss";

type EditableDatapoint = ManualPointSetDatapoint;

interface MeasurementStationManualDatapointsPageProps {
  combined?: boolean;
  editDatapoints?: boolean;
}
const MeasurementStationManualDatapointsPage = ({
  combined = false,
  editDatapoints = false,
}: MeasurementStationManualDatapointsPageProps) => {
  const { identifier, datapointSetId } = useParams();

  const navigate = useNavigate();
  const {
    data,
    error,
    refetch: datapointSetRefetch,
  } = useQuery<GetManualPointSetsQuery, GetManualPointSetsQueryVariables>(
    combined ? GET_MANUAL_POINT_SETS_COMBINED : GET_MANUAL_POINT_SETS,
    {
      variables: {
        identifier,
      },
    }
  );

  const [savingUploadedValues, setSavingUploadedValues] =
    React.useState<boolean>(false);
  const [uploadedValues, setUploadedValues] = React.useState<
    UploadedDatapoint[]
  >([]);

  const uploadDatapoints = () => {
    if (!uploadedValues.length || savingUploadedValues) {
      return;
    }

    setSavingUploadedValues(true);
    const values = [...uploadedValues];
    const requestPath = "/organization-admin/upload-manual-datapoints";
    apiClient
      .request(requestPath, {
        method: "POST",
        data: {
          datapoints: values,
        },
      })
      .then(() => {
        toast.success(gettext("Success"));
        setUploadedValues([]);
        datapointSetRefetch();
        setSavingUploadedValues(false);
      })
      .catch((e) => {
        toast.error(e.message.toString());
        setSavingUploadedValues(false);
        datapointSetRefetch();
      });
  };

  if (!data || (!combined && !identifier)) {
    return <div />;
  }
  if (error) {
    return <pre>{JSON.stringify(error, null, 2)}</pre>;
  }

  const pointSets = data?.manual_point_set || [];

  const createDatapointSet = datapointSetId === "new";
  const editDatapointSet = !createDatapointSet && !!datapointSetId;
  const datapointSetToEdit = editDatapointSet
    ? pointSets.find((set) => set.id.toString() === datapointSetId)
    : undefined;
  if (editDatapointSet && !datapointSetToEdit) {
    return <div>Datapoint set not found</div>;
  }

  const onFileLoaded = (data: UploadedDatapoint[]) => {
    if (!data) {
      return;
    }
    const validData = data
      .filter(
        (row: string[]) => row.length === 5 && !isNaN(parseNumberInput(row[3]))
      )
      .map((row: string[]) => [
        row[0],
        row[1],
        row[2],
        parseNumberInput(row[3]),
      ]);
    setUploadedValues([...validData] as UploadedDatapoint[]);
  };

  const datapointSetsPath = identifier
    ? `/stations/${identifier}/manual-datapoints`
    : "/stations/manual-datapoints";

  return (
    <div className="MeasurementStationManualDatapointsPage">
      {!!identifier && (
        <BackToLink to={`/stations/${identifier}`}>
          Back to measurement station.
        </BackToLink>
      )}
      <PageHeader
        title={gettext("Manual/lab data")}
        className="mb-3"
      ></PageHeader>
      <Card className="mb-3">
        <Card.Body>
          {editDatapoints && datapointSetToEdit && (
            <div>
              <BackToLink to={datapointSetsPath}>
                Back to datapoint sets table
              </BackToLink>
              <h4>Edit datapoints of: {datapointSetToEdit.name}</h4>
              <DatapointsEditTable
                datapointSet={datapointSetToEdit}
                datapointSetRefetch={datapointSetRefetch}
              />
            </div>
          )}
          {!editDatapoints && (datapointSetToEdit || createDatapointSet) && (
            <div>
              <BackToLink to={datapointSetsPath}>
                Back to datapoint sets table
              </BackToLink>
              {datapointSetToEdit ? (
                <h4>Edit datapoint set: {datapointSetToEdit.name}</h4>
              ) : (
                <h4>Create manual/lab data</h4>
              )}
              <DatapointSetUpdateForm
                identifier={identifier}
                datapointSet={datapointSetToEdit}
                onSuccess={() => {
                  datapointSetRefetch()
                    .then(() => {
                      navigate(datapointSetsPath);
                    })
                    .catch(console.error);
                }}
              />
            </div>
          )}
          {!datapointSetToEdit &&
            !createDatapointSet &&
            !editDatapoints &&
            !savingUploadedValues && (
              <>
                {/* Only allow XLSX uploads from a generic station-agnostic page */}
                {!identifier && (
                  <Table responsive>
                    <thead>
                      <tr>
                        <th colSpan={4}>
                          <div className="uploadHeader">
                            Upload datapoints from CSV or XLSX file
                            <div className="templateButton">
                              <a href="/xlsx/example_file_for_import.xlsx">
                                <Button>Download Template</Button>
                              </a>
                              <DefaultTooltip
                                popHeader={gettext("Template File")}
                                popBody={
                                  <p>
                                    You can use the attached Excel file as a
                                    template for manual data points. Fill in the
                                    correct dates, times and values.
                                  </p>
                                }
                              />
                            </div>
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <input
                            type="file"
                            name="file-upload-input"
                            accept=".csv, .xlsx"
                            onChange={onInputFileChange(onFileLoaded)}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <Button
                            type="button"
                            variant="primary"
                            disabled={savingUploadedValues}
                            onClick={(e) => {
                              e.preventDefault();
                              uploadDatapoints();
                            }}
                          >
                            Upload
                          </Button>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                )}
                <DatapointSetTable
                  identifier={identifier}
                  combined={combined}
                  datapointSets={pointSets}
                  onSuccess={() => {
                    datapointSetRefetch().catch(console.error);
                  }}
                />
              </>
            )}
        </Card.Body>
      </Card>
    </div>
  );
};

interface DatapointSetTableProps {
  datapointSets: ManualPointSet[];
  onSuccess?: () => void;
  combined?: boolean;
  identifier?: string;
}
const DatapointSetTable = ({
  identifier,
  datapointSets,
  onSuccess,
  combined = false,
}: DatapointSetTableProps) => {
  const deleteHandler = (sourceUUID: string) => (e: any) => {
    e.preventDefault();
    const answer = window.confirm(
      gettext(
        "Are you sure you want to delete this datapoint set? This cannot be undone."
      )
    );
    if (answer) {
      apiClient
        .request("/organization-member/delete-manual-point-set", {
          method: "DELETE",
          data: {
            source_uuid: sourceUUID,
          },
        })
        .then(() => {
          toast.success(gettext("Datapoint set deleted successfully"));
          if (onSuccess) {
            onSuccess();
          }
        })
        .catch((e) => {
          toast.error("Error deleting datapoint set: " + e.toString());
        });
    }
  };
  return (
    <div>
      <Table>
        <thead>
          <tr>
            <th colSpan={5}>Existing data sets</th>
          </tr>
          <tr>
            <th>Station</th>
            <th>Identifier</th>
            <th>Name</th>
            <th>Unit</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {datapointSets.map((datapointSet) => {
            let formulaUnit = datapointSet.unit?.symbol;
            if (formulaUnit === undefined) {
              formulaUnit = gettext("(unassigned)");
            } else if (formulaUnit === "") {
              formulaUnit = gettext("(no unit)");
            }
            return (
              <tr key={datapointSet.id}>
                <td>{datapointSet.station.identifier}</td>
                <td>{datapointSet.identifier}</td>
                <td>{datapointSet.name}</td>
                <td>{formulaUnit}</td>
                <td>
                  <PermissionContextCheck
                    category="source_edit"
                    requiredPermission="add_manual_datapoint"
                    target={datapointSet.source_uuid}
                  >
                    <>
                      <Button
                        as={Link as any}
                        to={`/stations/${
                          combined ? "" : `${datapointSet.station.identifier}/`
                        }manual-datapoints/${datapointSet.id}`}
                        variant="outline-primary"
                        size="sm"
                        className="me-2"
                      >
                        Edit
                      </Button>
                      <Button
                        as={Link as any}
                        to={`/stations/${
                          combined ? "" : `${datapointSet.station.identifier}/`
                        }manual-datapoints/${datapointSet.id}/edit-datapoints`}
                        variant="outline-primary"
                        size="sm"
                        className="me-2"
                      >
                        Add Results
                      </Button>
                    </>
                  </PermissionContextCheck>
                  <PermissionContextCheck
                    category="source_edit"
                    requiredPermission="delete_manual_point_set"
                    target={datapointSet.source_uuid}
                  >
                    <Button
                      type="button"
                      variant="outline-danger"
                      size="sm"
                      onClick={deleteHandler(datapointSet.source_uuid)}
                    >
                      Delete
                    </Button>
                  </PermissionContextCheck>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      {!!identifier && (
        <div className="my-2">
          <Button
            as={Link as any}
            to={`/stations/${identifier}/manual-datapoints/new`}
            variant="outline-primary"
            size="sm"
          >
            Create manual/lab data set
          </Button>
        </div>
      )}
    </div>
  );
};

interface DatapointsEditTableProps {
  datapointSet: ManualPointSet | undefined;
  onSuccess?: () => void;
  datapointSetRefetch: () => Promise<any>;
}
const DatapointsEditTable = ({
  datapointSet,
  datapointSetRefetch,
}: DatapointsEditTableProps) => {
  const [editedValues, setEditedValues] = React.useState<EditableDatapoint>();
  const [submittingForm, setSubmittingForm] = React.useState<boolean>(false);
  const [datapointValues, setDatapointValues] = React.useState<Record<
    string,
    any
  > | null>(null);
  const stationIdentifier = datapointSet?.station.identifier;

  const datapoints = React.useMemo(
    () => datapointSet?.datapoints,
    [datapointSet]
  );

  const setValueHandler = getFormValueHandler(
    editedValues as Record<string, any>,
    setEditedValues as (
      value: React.SetStateAction<Record<string, any>>
    ) => void
  );

  const setDatapointValue = (key: string, value: any) => {
    setDatapointValues({
      ...datapointValues,
      [key]: value,
    });
  };

  const onSaveEdited = () => {
    if (!editedValues) {
      return;
    }
    const values = editedValues;
    const requestPath = `/organization-member/manual-datapoint/${values.id}`;

    apiClient
      .request(requestPath, {
        method: "PUT",
        data: {
          stationIdentifier,
          source_uuid: datapointSet?.source_uuid,
          ...values,
        },
      })
      .then(() => {
        toast.success("Success");
        setDatapointValues({});
        datapointSetRefetch();
        setEditedValues(undefined);
      })
      .catch((e) => {
        toast.error(e.message.toString());
      });
  };

  const saveNewDatapoint = () => {
    if (!datapointValues) {
      return;
    }
    setSubmittingForm(true);
    const values = parseNumericFormValues({ ...datapointValues });
    const requestPath = "/organization-member/create-manual-datapoint";
    apiClient
      .request(requestPath, {
        method: "POST",
        data: {
          stationIdentifier,
          source_uuid: datapointSet?.source_uuid,
          ...values,
        },
      })
      .then(() => {
        toast.success("Success");
        setDatapointValues({});
        setSubmittingForm(false);
        datapointSetRefetch();
      })
      .catch((e) => {
        toast.error(e.message.toString());
        setSubmittingForm(false);
      });
  };

  const onSave = () => {
    saveNewDatapoint();
  };

  const onDeleteDatapoint = (id: number) => {
    if (
      !window.confirm(
        gettext("Are you sure you want to delete this datapoint?")
      )
    ) {
      return;
    }
    const requestPath = `/organization-member/manual-datapoint/${id}`;
    apiClient
      .request(requestPath, {
        method: "DELETE",
        data: {
          stationIdentifier,
        },
      })
      .then(() => {
        toast.success("Success");
        datapointSetRefetch();
      })
      .catch((e) => {
        toast.error(e.message.toString());
      });
  };

  return (
    <>
      <Table responsive>
        <thead>
          <tr>
            <th colSpan={4}>Add Datapoint</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>
              <Row>
                <Col>Timestamp</Col>
              </Row>
            </th>
            <td>
              <Form.Group>
                <DatePicker
                  selected={
                    datapointValues?.timestamp
                      ? new Date(datapointValues?.timestamp)
                      : null
                  }
                  showTimeSelect
                  onChange={(d) => {
                    if (!d) {
                      return;
                    }
                    setDatapointValue("timestamp", d);
                  }}
                  dateFormat="dd.MM.yyyy HH:mm"
                  className="form-control my-1"
                  locale="fi"
                />
              </Form.Group>
            </td>
          </tr>
          <tr>
            <th>
              <Row>
                <Col>Value</Col>
              </Row>
            </th>
            <td>
              <Form.Group>
                <FormattedFormControl
                  type="number"
                  value={datapointValues?.value ?? ""}
                  onChange={(e) => setDatapointValue("value", e.target.value)}
                />
              </Form.Group>
            </td>
          </tr>
        </tbody>
      </Table>
      <div className="sectionTables">
        <Table responsive>
          <tbody>
            <tr>
              <td colSpan={2}>
                <Button
                  type="button"
                  variant="primary"
                  disabled={submittingForm}
                  onClick={(e) => {
                    e.preventDefault();
                    onSave();
                  }}
                >
                  Add Datapoint
                </Button>
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
      {!!datapoints && (
        <div className="sectionTables">
          <Table responsive>
            <thead>
              <tr>
                <th colSpan={4}>Existing Datapoints</th>
              </tr>
              <tr>
                <th>Timestamp</th>
                <th>Value</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {datapoints.map((datapoint: EditableDatapoint) => (
                <tr key={`datapoint_${datapoint.id}`}>
                  {!!editedValues && editedValues?.id === datapoint.id ? (
                    <>
                      <td>
                        <Form.Group>
                          <DatePicker
                            selected={
                              editedValues?.timestamp
                                ? new Date(editedValues?.timestamp)
                                : null
                            }
                            showTimeSelect
                            onChange={(d) => {
                              if (!d) {
                                return;
                              }

                              setEditedValues({
                                ...datapoint,
                                timestamp: d,
                              });
                            }}
                            dateFormat="dd.MM.yyyy HH:mm"
                            className="form-control my-1"
                            locale="fi"
                          />
                        </Form.Group>
                      </td>
                      <td>
                        <FormattedFormControl
                          type="number"
                          value={editedValues.value}
                          onChange={setValueHandler("value")}
                        />
                      </td>
                    </>
                  ) : (
                    <>
                      <td>
                        {dayjs(datapoint.timestamp).format("DD.MM.YYYY HH:mm")}
                      </td>
                      <td>{formatNumberLocale(datapoint.value)}</td>
                    </>
                  )}

                  <td></td>
                  <td className="alignRight">
                    {editedValues?.id === datapoint.id ? (
                      <>
                        <Button size="sm" onClick={onSaveEdited}>
                          Save
                        </Button>
                        <Button
                          size="sm"
                          onClick={() => {
                            setEditedValues(undefined);
                          }}
                        >
                          Cancel
                        </Button>
                      </>
                    ) : (
                      <Button
                        size="sm"
                        onClick={() => {
                          setEditedValues({
                            ...datapoint,
                          });
                        }}
                      >
                        Edit
                      </Button>
                    )}
                    <Button
                      variant="danger"
                      size="sm"
                      onClick={() => {
                        onDeleteDatapoint(datapoint.id);
                      }}
                    >
                      Delete
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )}
    </>
  );
};

interface DatapointSetUpdateFormProps {
  datapointSet: ManualPointSet | undefined;
  onSuccess?: () => void;
  identifier?: string;
}

const DatapointSetUpdateForm = ({
  datapointSet,
  onSuccess,
  identifier,
}: DatapointSetUpdateFormProps) => {
  const { data: sortedUnits } = useSortedUnits();
  const [formState, setFormState] = React.useState({
    name: datapointSet?.name ?? "",
    systemName: datapointSet?.system_name ?? "",
    sourceIdentifier: datapointSet?.identifier ?? "",
    unitId: datapointSet?.unit?.id,
  });
  const [submittingForm, setSubmittingForm] = React.useState<boolean>(false);

  const stationIdentifier = datapointSet?.station.identifier ?? identifier;
  const creating = !datapointSet;
  let submitDisabled =
    !formState.name || !formState.sourceIdentifier || submittingForm;

  const onSave = () => {
    submitForm();
  };

  const submitForm = () => {
    if (submitDisabled) {
      return;
    }
    setSubmittingForm(true);
    const data = {
      stationIdentifier,
      source_uuid: datapointSet?.source_uuid,
      ...formState,
    };

    const path = creating
      ? "/organization-member/create-manual-point-set"
      : "/organization-member/edit-manual-point-set";
    apiClient
      .request(path, {
        method: "POST",
        data,
      })
      .then(() => {
        toast.success(gettext(`Datapoint set updated successfully`));
        if (onSuccess) {
          onSuccess();
        }
        setSubmittingForm(false);
      })
      .catch((e) => {
        console.error(e);
        const message = e.toString().replace(/^Error: /, "");
        toast.error(
          `Error ${creating ? "creating" : "editing"} formula: ${message}`
        );
        setSubmittingForm(false);
      });
  };

  return (
    <Form>
      <Form.Group className="mb-2">
        <Form.Label>Identifier</Form.Label>
        <Form.Control
          type="text"
          value={formState.sourceIdentifier}
          placeholder={gettext("Enter an identifier for the datapoints")}
          onChange={(e) => {
            setFormState({
              ...formState,
              sourceIdentifier: e.target.value,
            });
          }}
        />
      </Form.Group>
      <Form.Group className="mb-2">
        <Form.Label>Name</Form.Label>
        <Form.Control
          type="text"
          value={formState.name}
          placeholder={gettext("Enter a name for the datapoints")}
          onChange={(e) => {
            setFormState({
              ...formState,
              name: e.target.value,
            });
          }}
        />
      </Form.Group>
      <Form.Group className="mb-2">
        <Form.Label>System Name</Form.Label>
        <Form.Control
          type="text"
          value={formState.systemName}
          placeholder={gettext(
            "Enter a machine-readable name for the datapoints"
          )}
          onChange={(e) => {
            setFormState({
              ...formState,
              systemName: e.target.value,
            });
          }}
        />
      </Form.Group>
      <Form.Group className="mb-2">
        <Form.Label>Unit</Form.Label>
        <div>
          <Form.Select
            required
            aria-label="Select a unit for the formula"
            onChange={(e) => {
              const unitId: number | undefined = e.target.value
                ? Number(e.target.value)
                : undefined;
              setFormState({
                ...formState,
                unitId,
              });
            }}
            value={formState.unitId}
          >
            <option value="">(unassigned)</option>
            {sortedUnits.map((unit: GetUnits_unit) => (
              <option key={`unit_${unit.id}`} value={unit.id}>
                {unit.symbol || gettext("(no unit)")}
              </option>
            ))}
          </Form.Select>
        </div>
      </Form.Group>
      <div className="pt-4">
        <Button
          type="button"
          variant="primary"
          disabled={submitDisabled}
          onClick={(e) => {
            e.preventDefault();
            onSave();
          }}
        >
          {creating
            ? gettext("Create manual/lab data")
            : gettext("Save changes")}
        </Button>
      </div>
    </Form>
  );
};

export default MeasurementStationManualDatapointsPage;
