import 'echarts/i18n/langFI';
import * as echarts from 'echarts/core';
import {GridComponent, TitleComponent, TooltipComponent} from 'echarts/components';
import {LineChart} from 'echarts/charts';
import {CanvasRenderer} from 'echarts/renderers';
echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LineChart,
  CanvasRenderer
]);
