import React from "react";
import { Nav } from "react-bootstrap";
import ProfileDropdown from "./ProfileDropdown";
import SubscribeButton from "theme/components/navbar/top/SubscribeButton";

const TopNavRightSideNavItem = () => {
  return (
    <Nav
      navbar
      className="navbar-nav-icons ms-auto flex-row align-items-center"
      as="ul"
    >
      {/*<Nav.Item as={'li'}>*/}
      {/*  <Nav.Link*/}
      {/*    className="px-2 theme-control-toggle"*/}
      {/*    onClick={() => setConfig('isDark', !isDark)}*/}
      {/*  >*/}
      {/*    <OverlayTrigger*/}
      {/*      key="left"*/}
      {/*      placement={isRTL ? 'bottom' : 'left'}*/}
      {/*      overlay={*/}
      {/*        <Tooltip id="ThemeColor">*/}
      {/*          {isDark ? 'Switch to light theme' : 'Switch to dark theme'}*/}
      {/*        </Tooltip>*/}
      {/*      }*/}
      {/*    >*/}
      {/*      <div className="theme-control-toggle-label">*/}
      {/*        <FontAwesomeIcon*/}
      {/*          icon={isDark ? 'sun' : 'moon'}*/}
      {/*          className="fs-0"*/}
      {/*        />*/}
      {/*      </div>*/}
      {/*    </OverlayTrigger>*/}
      {/*  </Nav.Link>*/}
      {/*</Nav.Item>*/}

      {/*<CartNotification />*/}
      {/*<NotificationDropdown />*/}
      {/*<NineDotMenu />*/}
      <SubscribeButton />
      <ProfileDropdown />
    </Nav>
  );
};
export default TopNavRightSideNavItem;
