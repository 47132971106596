import apiClient from "../api";
import { gql } from "@apollo/client";

/**
 * User management related constants
 */

export const USER_ROLE_MEMBER = "member";
export const USER_ROLE_CONTROLLER = "quality_controller";
export const USER_ROLE_ADMIN = "admin";

export const ORG_ACTION_ADD = "add";
export const ORG_ACTION_EDIT = "edit";
export const ORG_ACTION_REMOVE = "remove";

export const USER_ROLE_OPTIONS: Record<string, string> = {
  [USER_ROLE_MEMBER]: "Member",
  [USER_ROLE_CONTROLLER]: "Quality Controller",
  [USER_ROLE_ADMIN]: "Admin",
};

/**
 * User management utilities
 */

export interface ManagedOrganizationOption {
  id: number;
  name: string;
  role: string;
}

export const loadManagedOrganizations = async ({
  setManagedOrganizations,
}: {
  setManagedOrganizations: React.Dispatch<
    React.SetStateAction<ManagedOrganizationOption[]>
  >;
}) => {
  try {
    const result = await apiClient.request("/auth/managed-organizations", {
      method: "GET",
    });
    setManagedOrganizations(result as ManagedOrganizationOption[]);
  } catch (e) {
    console.error(e);
  }
};

export interface EditableUserOption {
  id: number;
  email: string;
  display_name: string;
  is_admin: boolean;
  can_add_organizations: number[];
  locale: string;
}

export const loadEditableUsers = async ({
  setEditableUsers,
}: {
  setEditableUsers?: React.Dispatch<React.SetStateAction<EditableUserOption[]>>;
}) => {
  let result;
  try {
    result = await apiClient.request("/auth/editable-users", {
      method: "GET",
    });
    setEditableUsers?.(result as EditableUserOption[]);
  } catch (e) {
    console.error(e);
  }
  return result;
};

export const loadEditableUser = async ({ userId }: { userId: number }) => {
  const users = await loadEditableUsers({});
  return users?.find((user: EditableUserOption) => user.id === userId);
};

export const loadContextPermissions = async ({
  category,
  target,
  requiredPermission,
}: {
  category: string;
  target?: string;
  requiredPermission?: string;
}): Promise<string[]> => {
  let result;
  try {
    result = await apiClient.request("/auth/context-permissions", {
      method: "POST",
      data: {
        category,
        target,
        requiredPermission,
      },
    });
  } catch (e) {
    console.error(e);
  }
  return result?.permissions;
};

/**
 * User Organizations management
 */

export const GET_ORGANIZATIONS = gql`
  query GetOrganizations {
    organization(order_by: { id: asc }) {
      id
      name
      slug
    }
  }
`;

export const GET_ORGANIZATION = gql`
  query GetOrganization($organizationId: Int) {
    organization(where: { id: { _eq: $organizationId } }) {
      id
      name
      slug
    }
  }
`;

export const GET_USER_ORGANIZATIONS = gql`
  query GetUserOrganizations($userId: Int) {
    organization_user(where: { user_id: { _eq: $userId } }) {
      role
      organization {
        id
        name
        slug
      }
    }
  }
`;
