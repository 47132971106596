import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import team3 from "theme/assets/img/team/avatar.png";
import Avatar from "theme/components/common/Avatar";
import { useAuth, useLogout } from "../../../state/auth";

const ProfileDropdown = () => {
  const logout = useLogout();
  const { user } = useAuth();

  return (
    <Dropdown navbar={true} as="li">
      <Dropdown.Toggle
        bsPrefix="toggle"
        as={Link}
        to="#!"
        className="pe-0 ps-2 nav-link"
      >
        <Avatar src={team3} />
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-menu-card  dropdown-menu-end">
        <div className="bg-white rounded-2 py-2 dark__bg-1000">
          <Dropdown.Item
            as={Link}
            className="fw-bold text-warning"
            to="/profile"
          >
            <FontAwesomeIcon icon="user" className="me-1" />
            <span>{user?.displayName ?? user?.email}</span>
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item as={Link} to="/profile">
            Profile
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item
            as={Link}
            to="#!"
            onClick={(e) => {
              e.preventDefault();
              logout();
            }}
          >
            Logout
          </Dropdown.Item>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ProfileDropdown;
