import { useQuery } from "@apollo/client";
import PageHeader from "theme/components/common/PageHeader";
import * as React from "react";
import Card from "react-bootstrap/Card";
import { toast } from "react-toastify";
import { Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import { GetWorldsensingNetworksQuery } from "../../utils/__generated__/GetWorldsensingNetworksQuery";
import apiClient from "../../api";
import { getFormValueHandler } from "../../utils/forms";
import { gettext } from "../../i18n";
import FormControl from "react-bootstrap/FormControl";
import { GET_WORLDSENSING_NETWORKS_LIST } from "../../utils/worldsensing";
import "./WorldsensingNetworkPage.scss";

interface WSNetwork {
  network_id: number;
  username: string;
  active: boolean;
  secretKey?: string;
}
const WorldsensingNetworkPage = () => {
  const [editedValues, setEditedValues] = React.useState<WSNetwork>();
  const [addingNetwork, setAddingNetwork] = React.useState<WSNetwork>();
  const { data: networkQuery, refetch: refetchUnits } =
    useQuery<GetWorldsensingNetworksQuery>(GET_WORLDSENSING_NETWORKS_LIST);

  const setValueHandler = getFormValueHandler(
    editedValues as Record<string, any>,
    setEditedValues as (
      value: React.SetStateAction<Record<string, any>>
    ) => void
  );

  const onUpdateNetwork = async (
    { network_id, username, active, secretKey }: WSNetwork,
    action: string = "edit"
  ) => {
    setEditedValues(undefined);

    let requestPath = "";
    switch (action) {
      case "edit":
        requestPath = `/admin/edit-ws-network`;
        break;
      case "delete":
        requestPath = `/admin/delete-ws-network`;
        break;
      case "add":
        requestPath = `/admin/add-ws-network`;
        break;
      default:
        throw new Error("Invalid action");
    }

    try {
      await apiClient.request(requestPath, {
        method: "POST",
        data: { network_id, username, active, key: secretKey },
      });
      toast.success("Success");
      refetchUnits();
      return true;
    } catch (e) {
      toast.error(gettext((e as any).message.toString()));
    }
    return false;
  };

  const onSaveEdited = () => onUpdateNetwork(editedValues as WSNetwork, "edit");

  const onSaveNewNetwork = async () => {
    const result = await onUpdateNetwork(addingNetwork as WSNetwork, "add");
    if (result) {
      setAddingNetwork(undefined);
    }
  };

  const onDeleteNetwork = (network_id: number) => {
    if (
      !window.confirm(gettext("Are you sure you want to delete this network?"))
    ) {
      return;
    }
    onUpdateNetwork({ network_id } as WSNetwork, "delete");
  };

  const networks = networkQuery?.worldsensing_network
    ? [...networkQuery?.worldsensing_network]
    : [];
  return (
    <div className="WorldsensingNetworkPage">
      <PageHeader title="Worldsensing Networks" className="mb-3"></PageHeader>
      <Card className="mb-3">
        <Card.Body>
          <Table responsive>
            <thead>
              <tr>
                <th>Network ID</th>
                <th>Username</th>
                <th>Secret Key</th>
                <th>Active</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {networks.map((network) => (
                <tr key={network.network_id}>
                  {editedValues?.network_id === network.network_id ? (
                    <>
                      <td>{editedValues.network_id}</td>
                      <td>
                        <FormControl
                          type="text"
                          placeholder="Enter access username"
                          value={editedValues.username}
                          onChange={setValueHandler("username")}
                        />
                      </td>
                      <td>
                        <FormControl
                          type="text"
                          placeholder="New secret key (optional)"
                          value={editedValues.secretKey}
                          onChange={setValueHandler("secretKey")}
                        />
                      </td>
                      <td>
                        <Form.Check
                          type="checkbox"
                          checked={editedValues.active ?? false}
                          onChange={(e: any) => {
                            setEditedValues({
                              ...editedValues,
                              active: e.target.checked,
                            });
                          }}
                        />
                      </td>
                    </>
                  ) : (
                    <>
                      <td>{network.network_id}</td>
                      <td>{network.username}</td>
                      <td>****</td>
                      <td>{network.active ? gettext("Yes") : gettext("No")}</td>
                    </>
                  )}

                  <td className="actionButtons">
                    {editedValues?.network_id === network.network_id ? (
                      <>
                        <Button size="sm" onClick={onSaveEdited}>
                          Save
                        </Button>
                        <Button
                          size="sm"
                          onClick={() => {
                            setEditedValues(undefined);
                          }}
                        >
                          Cancel
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button
                          size="sm"
                          onClick={() => {
                            setEditedValues({
                              ...network,
                              secretKey: "",
                            });
                          }}
                        >
                          Edit
                        </Button>
                        <Button
                          variant="danger"
                          size="sm"
                          onClick={() => onDeleteNetwork(network.network_id)}
                        >
                          Delete
                        </Button>
                      </>
                    )}
                  </td>
                </tr>
              ))}
              <tr>
                {addingNetwork && (
                  <>
                    <td>
                      <FormControl
                        type="text"
                        placeholder="Enter network ID"
                        value={addingNetwork.network_id}
                        onChange={(e) => {
                          setAddingNetwork({
                            ...addingNetwork,
                            network_id: parseInt(e.target.value, 10),
                          });
                        }}
                      />
                    </td>
                    <td>
                      <FormControl
                        type="text"
                        placeholder="Enter access username"
                        value={addingNetwork.username}
                        onChange={(e) => {
                          setAddingNetwork({
                            ...addingNetwork,
                            username: e.target.value,
                          });
                        }}
                      />
                    </td>
                    <td>
                      <FormControl
                        type="text"
                        placeholder="Enter secret key"
                        value={addingNetwork.secretKey}
                        onChange={(e) => {
                          setAddingNetwork({
                            ...addingNetwork,
                            secretKey: e.target.value,
                          });
                        }}
                      />
                    </td>
                    <td>
                      <Form.Check
                        type="checkbox"
                        checked={addingNetwork.active ?? false}
                        onChange={(e: any) =>
                          setAddingNetwork({
                            ...addingNetwork,
                            active: e.target.checked,
                          })
                        }
                      />
                    </td>
                    <td className="actionButtons">
                      <Button size="sm" onClick={() => onSaveNewNetwork()}>
                        Save
                      </Button>
                      <Button
                        size="sm"
                        onClick={() => setAddingNetwork(undefined)}
                      >
                        Cancel
                      </Button>
                    </td>
                  </>
                )}

                {!addingNetwork && (
                  <td colSpan={3}>
                    <Button
                      size="sm"
                      onClick={() => {
                        setAddingNetwork({
                          network_id: 0,
                          username: "",
                          secretKey: "",
                          active: false,
                        });
                      }}
                    >
                      Add Network
                    </Button>
                  </td>
                )}
              </tr>
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </div>
  );
};

export default WorldsensingNetworkPage;
