import { gql, useQuery, useLazyQuery } from "@apollo/client";
import PageHeader from "../theme/components/common/PageHeader";
import * as React from "react";
import { Form } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { toast } from "react-toastify";
import apiClient from "../api";
import { gettext } from "../i18n";
import { onInputFileChange } from "utils/xlsx";
import { timestampToDatetimeString } from "utils/formatting";
import {
  ListUploadedFilesQuery,
  ListUploadedFilesQuery_lab_data_upload,
} from "./__generated__/ListUploadedFilesQuery";
import { RetrieveFileDataQuery_lab_data_upload } from "./__generated__/RetrieveFileDataQuery";
import "./LabDataUploadPage.scss";

const LIST_UPLOADED_FILES = gql`
  query ListUploadedFilesQuery {
    lab_data_upload {
      id
      timestamp
      user {
        display_name
      }
    }
  }
`;

const RETRIEVE_FILE_DATA = gql`
  query RetrieveFileDataQuery($id: Int!) {
    lab_data_upload(where: { id: { _eq: $id } }) {
      timestamp
      data
    }
  }
`;

interface UploadedFilesTableProps {
  uploadedFiles: ListUploadedFilesQuery | undefined;
}
const UploadedFilesTable: React.FC<UploadedFilesTableProps> = ({
  uploadedFiles,
}: UploadedFilesTableProps) => {
  const uploadedFileList = uploadedFiles?.lab_data_upload || [];
  const [getData, { data: fileData }] = useLazyQuery(RETRIEVE_FILE_DATA);

  React.useEffect(() => {
    if (fileData) {
      onFileDataReceived(fileData.lab_data_upload[0]);

      // Reset the data
      getData({ variables: { id: 0 } });
    }
  }, [fileData, getData]);

  const onFileDataReceived = (
    file: RetrieveFileDataQuery_lab_data_upload | undefined
  ) => {
    if (!file) {
      return;
    }
    const fileData = file.data;
    const blob = new Blob([fileData], {
      type: "application/xml",
    });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `lab_data_${timestampToDatetimeString(file.timestamp)}.xml`;
    a.click();
  };

  return (
    <div>
      <Table responsive>
        <thead>
          <tr>
            <th>Uploaded by</th>
            <th>Timestamp</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {!uploadedFileList.length && (
            <tr>
              <td colSpan={3}>{gettext("No uploaded files")}</td>
            </tr>
          )}
          {uploadedFileList.map(
            (file: ListUploadedFilesQuery_lab_data_upload) => (
              <tr key={file.id}>
                <td>{file.user.display_name}</td>
                <td>{timestampToDatetimeString(file.timestamp)}</td>
                <td>
                  <Button
                    type="button"
                    onClick={() => {
                      getData({ variables: { id: file.id } });
                    }}
                  >
                    {gettext("Download")}
                  </Button>
                </td>
              </tr>
            )
          )}
        </tbody>
      </Table>
    </div>
  );
};

const LabDataUploadPage = () => {
  const { data: uploadedFiles, refetch: refetchUploadedFiles } =
    useQuery<ListUploadedFilesQuery>(LIST_UPLOADED_FILES);
  const [savinguploadedData, setSavinguploadedData] =
    React.useState<boolean>(false);
  const [uploadedData, setUploadedData] = React.useState<string | null>(null);
  const [uploadAsManual, setUploadAsManual] = React.useState<boolean>(true);

  const uploadData = () => {
    if (!uploadedData || savinguploadedData) {
      return;
    }

    setSavinguploadedData(true);
    const requestPath = "/admin/upload-lab-xml";
    apiClient
      .request(requestPath, {
        method: "POST",
        data: {
          rawXML: uploadedData,
          uploadAsManual,
        },
      })
      .then(() => {
        toast.success(gettext("Success"));
        setUploadedData(null);
        setSavinguploadedData(false);
        refetchUploadedFiles();
      })
      .catch((e) => {
        toast.error(e.message.toString());
        setSavinguploadedData(false);
      });
  };

  const onFileLoaded = (data: any) => {
    if (!data) {
      return;
    }
    setUploadedData(data);
  };

  return (
    <div className="LabDataUploadPage">
      <PageHeader
        title={gettext("Import lab data")}
        className="mb-3"
      ></PageHeader>
      <Card className="mb-3">
        <Card.Body>
          {!savinguploadedData && (
            <>
              <Table responsive>
                <thead>
                  <tr>
                    <th colSpan={4}>
                      <div className="uploadHeader">
                        Upload data from an XML file
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <input
                        type="file"
                        name="file-upload-input"
                        accept=".xml"
                        onChange={onInputFileChange(null, onFileLoaded)}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="uploadActions">
                        <Form.Select
                          required
                          value={uploadAsManual ? "true" : "false"}
                          onChange={(e: any) => {
                            setUploadAsManual(e.target.value === "true");
                          }}
                        >
                          {Object.entries({
                            true: gettext("Manual/lab data"),
                            false: gettext("Channels"),
                          }).map(([key, label]) => (
                            <option key={`role_${key}`} value={key}>
                              {gettext(label)}
                            </option>
                          ))}
                        </Form.Select>
                        <Button
                          type="button"
                          variant="primary"
                          disabled={savinguploadedData}
                          onClick={(e) => {
                            e.preventDefault();
                            uploadData();
                          }}
                        >
                          Upload
                        </Button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </>
          )}
        </Card.Body>
        <Card.Body>
          <UploadedFilesTable uploadedFiles={uploadedFiles} />
        </Card.Body>
      </Card>
    </div>
  );
};

export default LabDataUploadPage;
