import { gql, useQuery, useApolloClient } from "@apollo/client";
import { useParams } from "react-router-dom";
import PageHeader from "../theme/components/common/PageHeader";
import * as React from "react";
import Table from "react-bootstrap/Table";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import ToggleButton from "react-bootstrap/ToggleButton";
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import {
  GetMeasurementStationEditDetails,
  GetMeasurementStationEditDetails_station,
  GetMeasurementStationEditDetailsVariables,
} from "./__generated__/GetMeasurementStationEditDetails";
import { GetMapLayersQuery } from "utils/__generated__/GetMapLayersQuery";
import { GET_MAP_LAYERS } from "utils/stations";
import {
  GetStationSources,
  GetStationSources_datasource,
  GetStationSourcesVariables,
} from "./__generated__/GetStationSources";
import {
  GetOrganizations,
  GetOrganizations_organization,
} from "../utils/__generated__/GetOrganizations";
import { gettext } from "../i18n";
import { GET_ORGANIZATIONS } from "../utils/users";
import {
  GET_MEASUREMENT_STATIONS,
  GET_DASHBOARD_STATIONS,
} from "utils/stations";
import apiClient from "../api";
import BackToLink from "../components/common/BackToLink";
import FormattedFormControl from "components/form/FormattedFormControl";
import { useAuth } from "../state/auth";

const GET_STATION_SOURCES = gql`
  query GetStationSources($stationId: Int) {
    datasource(where: { station_id: { _eq: $stationId } }) {
      name
      source_uuid
      source_id
      type
      type
      unit {
        id
        symbol
      }
    }
  }
`;

const GET_EDIT_DETAILS = gql`
  query GetMeasurementStationEditDetails($identifier: String) {
    station(where: { identifier: { _eq: $identifier } }) {
      id
      identifier
      name
      lat
      lon
      organization_id
      organization {
        slug
      }
      map_layer
      status
      primary_source_uuid
      config {
        key
        value
      }
    }
  }
`;

const ALLOWED_CONFIG_KEYS = [
  "hbdelay",
  "v24v_pup",
  "v12v_pup",
  "v5v_pup",
  "pnum1",
  "pnum2",
];
const MeasurementStationEditPage = () => {
  const { identifier } = useParams();
  const { user } = useAuth();
  const { data, error, refetch } = useQuery<
    GetMeasurementStationEditDetails,
    GetMeasurementStationEditDetailsVariables
  >(GET_EDIT_DETAILS, {
    variables: {
      identifier,
    },
  });
  const [editedValues, setEditedValues] = React.useState<Record<string, any>>(
    {}
  );
  const setValueHandler =
    (key: string, parser?: (raw: string) => any) => (e: any) => {
      let value = parser ? parser(e.target.value) : e.target.value;
      setValue(key, value);
    };
  const setValue = (key: string, value: any) => {
    setEditedValues({
      ...editedValues,
      [key]: value,
    });
  };
  const apolloClient = useApolloClient();
  const defaultInterval = 900;

  if (!data) {
    return <div />;
  }
  if (error) {
    return <pre>{JSON.stringify(error, null, 2)}</pre>;
  }
  if (data?.station.length === 0) {
    return <div>Not found.</div>;
  }

  const station = data.station[0];
  const config: Record<string, any> = {};
  for (const keyValuePair of station.config) {
    config[keyValuePair.key] = keyValuePair.value;
  }

  const submitDisabled = Object.keys(editedValues).length === 0;

  const onSave = (e: any) => {
    e.preventDefault();
    if (submitDisabled) {
      return;
    }
    const config: Record<string, any> = {};
    const values = { ...editedValues };
    if (values.measurementInterval) {
      config.measurement_interval = values.measurementInterval;
      delete values.measurementInterval;
    }
    if (values.transmissionInterval) {
      config.transmission_interval = values.transmissionInterval;
      delete values.transmissionInterval;
    }

    for (const [key, value] of Object.entries(values)) {
      if (ALLOWED_CONFIG_KEYS.includes(key)) {
        config[key] = value;
        delete values[key];
      }
    }
    const apiClientPath = user?.admin
      ? "/admin/station-config"
      : "/organization-member/station-config";
    apiClient
      .request(apiClientPath, {
        method: "POST",
        data: {
          stationId: station.id,
          ...values,
          config,
        },
      })
      .then(async () => {
        toast.success("Data saved successfully");
        await apolloClient.query({
          query: GET_MEASUREMENT_STATIONS,
          fetchPolicy: "network-only",
        });
        await apolloClient.query({
          query: GET_DASHBOARD_STATIONS,
          variables: {
            slug: station.organization?.slug ?? "",
          },
          fetchPolicy: "network-only",
        });
        refetch()
          .then(() => {
            setEditedValues({});
          })
          .catch(console.error);
      })
      .catch((e) => {
        console.error(e);
        toast.error("Error saving data: " + e.toString());
      });
  };

  const bodyContent = user?.admin ? (
    <EditPageAdminContent
      station={station}
      editedValues={editedValues}
      defaultInterval={defaultInterval}
      config={config}
      onSave={onSave}
      setValueHandler={setValueHandler}
      submitDisabled={submitDisabled}
      setValue={setValue}
    />
  ) : (
    <EditPageOrganizationMemberContent
      editedValues={editedValues}
      config={config}
      onSave={onSave}
      submitDisabled={submitDisabled}
      setValue={setValue}
    />
  );

  return (
    <div className="MeasurementStationEditPage">
      <BackToLink to={`/stations/${identifier}`}>
        Back to measurement station.
      </BackToLink>
      <PageHeader
        title={gettext(`Edit measurement station`)}
        className="mb-3"
      ></PageHeader>
      <Card className="mb-3">
        <Card.Body>{bodyContent}</Card.Body>
      </Card>
      {!!user?.admin && (
        <Card className="mb-3">
          <Card.Body>
            <CloneStationForm station={station} />
          </Card.Body>
        </Card>
      )}
    </div>
  );
};

interface DefaultEditPageContentProps {
  editedValues: Record<string, any>;
  config: Record<string, any>;
  onSave: (e: any) => void;
  submitDisabled: boolean;
  setValue: (key: string, value: any) => void;
}

interface EditPageAdminContentProps extends DefaultEditPageContentProps {
  station: GetMeasurementStationEditDetails_station;
  defaultInterval: number;
  setValueHandler: (
    key: string,
    parser?: (raw: string) => any
  ) => (e: any) => void;
}

interface EditPageOrganizationMemberContentProps
  extends DefaultEditPageContentProps {}

const EditPageAdminContent = ({
  station,
  editedValues,
  defaultInterval,
  config,
  onSave,
  setValueHandler,
  submitDisabled,
  setValue,
}: EditPageAdminContentProps) => {
  const measurementInterval =
    editedValues.measurementInterval ?? config.measurement_interval;
  const transmissionInterval =
    editedValues.transmissionInterval ?? config.transmission_interval;
  const { data: organizationList } = useQuery<GetOrganizations>(
    GET_ORGANIZATIONS,
    { variables: {} }
  );
  const { data: sourcesList } = useQuery<
    GetStationSources,
    GetStationSourcesVariables
  >(GET_STATION_SOURCES, {
    variables: {
      stationId: station.id,
    },
  });
  const { data: layerQuery } = useQuery<GetMapLayersQuery>(GET_MAP_LAYERS);
  const sources: GetStationSources_datasource[] = sourcesList?.datasource || [];
  const organizations: GetOrganizations_organization[] =
    organizationList?.organization || [];
  const createIntegerInputField = (
    label: string,
    keyName: string
  ): JSX.Element => {
    const defaultValue = editedValues[keyName] ?? config[keyName];
    const placeholder = keyName !== "pnum2" ? "0" : "";
    return (
      <tr>
        <th>{label}</th>
        <td>
          <FormControl
            value={defaultValue ? defaultValue : ""}
            placeholder={placeholder}
            onChange={(e) => {
              let val = parseInt(e.target.value);
              if (!val && !isNaN(val)) {
                val = 0;
              }
              setValue(keyName, val);
            }}
          />
        </td>
      </tr>
    );
  };

  const mapLayers = layerQuery?.map_layer ? [...layerQuery?.map_layer] : [];
  mapLayers.sort((a, b) => (a.id > b.id ? 1 : -1));

  return (
    <Table>
      <tbody>
        <tr>
          <th>Identifier</th>
          <td>
            <code>{station.identifier}</code>
          </td>
        </tr>
        <tr>
          <th>Name</th>
          <td>
            <FormControl
              value={editedValues.name ?? station.name ?? ""}
              placeholder={defaultInterval.toString()}
              onChange={setValueHandler("name")}
            />
          </td>
        </tr>
        <tr>
          <th>Organization</th>
          <td>
            <Form.Select
              required
              aria-label="Select an organization"
              onChange={setValueHandler("organization_id")}
              value={
                editedValues?.organization_id ?? station.organization_id ?? ""
              }
            >
              <option value={""}>No organization selected</option>
              {organizations.map(
                (organization: GetOrganizations_organization) => (
                  <option
                    key={`org_${organization.id}`}
                    value={organization.id}
                  >
                    {organization.name}
                  </option>
                )
              )}
            </Form.Select>
          </td>
        </tr>
        <tr>
          <th>Coordinates (lat/lon)</th>
          <td>
            <Row>
              <Col>
                <FormattedFormControl
                  type="number"
                  value={editedValues.lat ?? station.lat ?? ""}
                  placeholder="Latitude"
                  onChange={setValueHandler("lat")}
                />
              </Col>
              <Col>
                <FormattedFormControl
                  type="number"
                  value={editedValues.lon ?? station.lon ?? ""}
                  placeholder="Longitude"
                  onChange={setValueHandler("lon")}
                />
              </Col>
            </Row>
          </td>
        </tr>

        <tr>
          <th>Map layer</th>
          <td>
            <Form.Select
              required
              aria-label="Select the default map layer"
              onChange={setValueHandler("map_layer")}
              value={editedValues?.map_layer ?? station.map_layer ?? ""}
            >
              {mapLayers.map((layer) => (
                <option key={layer.system_name} value={layer.system_name}>
                  {layer.display_name}
                </option>
              ))}
            </Form.Select>
          </td>
        </tr>

        <tr>
          <th>Primary parameter</th>
          <td>
            <Form.Select
              required
              aria-label="Select the primary parameter for this station"
              onChange={setValueHandler("primary_source_uuid")}
              value={
                editedValues?.primary_source_uuid ??
                station.primary_source_uuid ??
                ""
              }
            >
              <option value={""}>
                Select the primary parameter for this station
              </option>
              {sources.map((source: GetStationSources_datasource) => (
                <option
                  key={`source_${source.source_uuid}`}
                  value={source.source_uuid}
                >
                  {source.name}
                </option>
              ))}
            </Form.Select>
          </td>
        </tr>

        <tr>
          <th>Measurement interval (min)</th>
          <td>
            <FormControl
              value={measurementInterval ? measurementInterval / 60 : ""}
              placeholder={defaultInterval.toString()}
              onChange={(e) => {
                let val = parseFloat(e.target.value);
                if (!val) {
                  // also checks NaN
                  val = 0;
                }
                setValue("measurementInterval", val * 60);
              }}
            />
          </td>
        </tr>
        <tr>
          <th>Transmission interval (min)</th>
          <td>
            <FormControl
              value={transmissionInterval ? transmissionInterval / 60 : ""}
              placeholder={defaultInterval.toString()}
              onChange={(e) => {
                let val = parseFloat(e.target.value);
                if (!val && !isNaN(val)) {
                  // also checks NaN
                  val = 0;
                }
                setValue("transmissionInterval", val * 60);
              }}
            />
          </td>
        </tr>
        {createIntegerInputField("Hbdelay", "hbdelay")}
        {createIntegerInputField("v24vPup", "v24v_pup")}
        {createIntegerInputField("v12vPup", "v12v_pup")}
        {createIntegerInputField("v5vPup", "v5v_pup")}
        {createIntegerInputField("pnum1", "pnum1")}
        {createIntegerInputField("pnum2", "pnum2")}
        <tr>
          <th>Status</th>
          <td>
            <Form.Select
              required
              aria-label="Select station status"
              onChange={setValueHandler("status")}
              value={editedValues?.status ?? station.status ?? ""}
            >
              <option value="active">Active</option>
              <option value="hold">On hold</option>
              <option value="inactive">Inactive</option>
            </Form.Select>
          </td>
        </tr>
        <tr>
          <td colSpan={2}>
            <Button type="button" onClick={onSave} disabled={submitDisabled}>
              Save
            </Button>
          </td>
        </tr>
      </tbody>
    </Table>
  );
};

const EditPageOrganizationMemberContent = ({
  editedValues,
  config,
  setValue,
  onSave,
  submitDisabled,
}: EditPageOrganizationMemberContentProps) => {
  const timeConvert = (minute: number) => {
    let convertedTime = `${minute} min`;
    const numberOfDays = Math.floor(minute / 24 / 60);
    const numberOfHours = Math.floor(minute / 60);
    if ((minute / 60) % 24 === 0 && numberOfDays > 1) {
      convertedTime = `${numberOfDays} d`;
    } else if (minute % 60 === 0) {
      convertedTime = `${numberOfHours} h`;
    }
    return convertedTime;
  };

  const measurementInterval =
    editedValues.measurementInterval ?? config.measurement_interval;
  const transmissionInterval =
    editedValues.transmissionInterval ?? config.transmission_interval;
  const defaultMeasurementIntervalMinutes = [
    1, 5, 10, 15, 30, 60, 120, 360, 720, 1440,
  ];
  const defaultTransmissionIntervalMinutes = [
    5, 15, 30, 60, 180, 360, 720, 1440, 2880, 4320, 10080,
  ];
  return (
    <Table>
      <tbody>
        <tr>
          <th>Measurement interval</th>
          <td>
            <ToggleButtonGroup
              id="measurement-group"
              name="measurement-group"
              type="radio"
              defaultValue={measurementInterval ? measurementInterval / 60 : ""}
              onChange={(value) => {
                let val = parseFloat(value);
                if (!val && !isNaN(val)) {
                  val = 0;
                }
                setValue("measurementInterval", val * 60);
              }}
            >
              {defaultMeasurementIntervalMinutes.map((minute, index) => {
                return (
                  <ToggleButton
                    size="sm"
                    key={`MeasurementKey-${index}`}
                    id={`MeasurementInterval-${index}`}
                    variant={"outline-primary"}
                    value={minute}
                  >
                    {timeConvert(minute)}
                  </ToggleButton>
                );
              })}
            </ToggleButtonGroup>
          </td>
        </tr>

        <tr>
          <th>Transmission interval</th>
          <td>
            <ToggleButtonGroup
              id="transmission-group"
              name="transmission-group"
              type="radio"
              defaultValue={
                transmissionInterval ? transmissionInterval / 60 : ""
              }
              onChange={(value) => {
                let val = parseFloat(value);
                if (!val && !isNaN(val)) {
                  val = 0;
                }
                setValue("transmissionInterval", val * 60);
              }}
            >
              {defaultTransmissionIntervalMinutes.map((minute, index) => {
                return (
                  <ToggleButton
                    size="sm"
                    key={`TransmissionKey-${index}`}
                    id={`Transmission-Interval-${index}`}
                    variant={"outline-primary"}
                    value={minute}
                  >
                    {timeConvert(minute)}
                  </ToggleButton>
                );
              })}
            </ToggleButtonGroup>
          </td>
        </tr>

        <tr>
          <td colSpan={2}>
            <Button type="button" onClick={onSave} disabled={submitDisabled}>
              Save
            </Button>
          </td>
        </tr>
      </tbody>
    </Table>
  );
};

interface CloneStationFormProps {
  station: GetMeasurementStationEditDetails_station;
}
const CloneStationForm = (props: CloneStationFormProps) => {
  const { station } = props;
  const [newName, setNewName] = React.useState("");
  const [newIdentifier, setNewIdentifier] = React.useState("");
  const [cloneFormulas, setCloneFormulas] = React.useState(false);
  const [cloneAnnotations, setCloneAnnotations] = React.useState(false);
  const [cloneData, setCloneData] = React.useState(false);
  const [cloneManualData, setCloneManualData] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);
  const navigate = useNavigate();
  const submitDisabled = !newName || !newIdentifier || submitting;

  const onSave = (e: any) => {
    e.preventDefault();
    if (submitDisabled || !station.identifier) {
      return;
    }
    // eslint-disable-next-line no-restricted-globals
    const answer = confirm(
      `Clone station ${station.name} [${station.identifier}] as ${newName} [${newIdentifier}]?`
    );
    if (!answer) {
      return;
    }
    setSubmitting(true);
    const apiClientPath = "/admin/clone-station";
    apiClient
      .request(apiClientPath, {
        method: "POST",
        data: {
          identifier: station.identifier,
          newIdentifier,
          newName,
          cloneFormulas,
          cloneAnnotations,
          cloneData,
          cloneManualData,
        },
      })
      .then((data) => {
        setSubmitting(false);
        toast.success("Station cloned successfully");
        const newIdentifier = data?.newStation?.identifier;
        if (newIdentifier) {
          navigate(`/stations/${newIdentifier}`);
        }
      })
      .catch((e) => {
        setSubmitting(false);
        console.error(e);
        toast.error("Error cloning station data: " + e.toString());
      });
  };

  return (
    <div>
      <h2>Clone station</h2>
      <Table>
        <tbody>
          <tr>
            <th>New identifier</th>
            <td>
              <FormControl
                value={newIdentifier}
                onChange={(e) => {
                  setNewIdentifier(e.target.value);
                }}
              />
            </td>
          </tr>
          <tr>
            <th>New name</th>
            <td>
              <FormControl
                value={newName}
                onChange={(e) => {
                  setNewName(e.target.value);
                }}
              />
            </td>
          </tr>
          <tr>
            <th>Clone formulas?</th>
            <td>
              <Form.Check
                checked={cloneFormulas}
                onChange={(e) => {
                  setCloneFormulas(e.target.checked);
                }}
              />
            </td>
          </tr>
          <tr>
            <th>Clone annotations?</th>
            <td>
              <Form.Check
                checked={cloneAnnotations}
                onChange={(e) => {
                  setCloneAnnotations(e.target.checked);
                }}
              />
            </td>
          </tr>
          <tr>
            <th>Clone data?</th>
            <td>
              <Form.Check
                checked={cloneData}
                onChange={(e) => {
                  setCloneData(e.target.checked);
                }}
              />
            </td>
          </tr>
          <tr>
            <th>Clone manual/lab data?</th>
            <td>
              <Form.Check
                checked={cloneManualData}
                onChange={(e) => {
                  setCloneManualData(e.target.checked);
                }}
              />
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              <Button type="button" onClick={onSave} disabled={submitDisabled}>
                Clone
              </Button>
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

export default MeasurementStationEditPage;
