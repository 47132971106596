import React from "react";
import { useQuery } from "@apollo/client";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import { toast } from "react-toastify";
import Table from "react-bootstrap/Table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Form from "react-bootstrap/Form";
import apiClient from "api";
import { gettext } from "i18n";
import { GetMeasurementStation_station } from "utils/__generated__/GetMeasurementStation";
import {
  GetMeasurementStations,
  GetMeasurementStations_station,
} from "utils/__generated__/GetMeasurementStations";
import {
  GET_MEASUREMENT_STATIONS,
  GET_MEASUREMENT_STATION_QUERY,
  GET_DATASOURCE_NAMES,
  getSourceUnitSymbol,
  sortStationList,
} from "utils/stations";
import {
  GetMeasurementStationQuery,
  GetMeasurementStationQuery_station_datasources,
} from "utils/__generated__/GetMeasurementStationQuery";
import { GetDatasourceNames } from "utils/__generated__/GetDatasourceNames";
import { useModalContext } from "state/modal";

import "./AdditionalChartsSelectorForm.scss";

interface ExistingDatasourceRowProps {
  sourceUUID: string;
  onRemoveAdditionalSource: (sourceUUID: string) => void;
}
const ExistingDatasourceRow: React.FC<ExistingDatasourceRowProps> = ({
  sourceUUID,
  onRemoveAdditionalSource,
}: ExistingDatasourceRowProps) => {
  const { data: sourceData } = useQuery<GetDatasourceNames>(
    GET_DATASOURCE_NAMES,
    {
      variables: {
        sourceUUID,
      },
    }
  );

  if (!sourceData?.datasource?.[0]) {
    return <></>;
  }

  const s = sourceData.datasource[0];
  return (
    <tr>
      <td>{s.station?.name}</td>
      <td>
        {s.name} {getSourceUnitSymbol(s)}
      </td>
      <td
        className="actionButtons"
        onClick={() => onRemoveAdditionalSource(sourceUUID)}
      >
        <FontAwesomeIcon icon="xmark" />
      </td>
    </tr>
  );
};

interface AdditionalChartsSelectorFormProps {
  station: GetMeasurementStation_station;
}

const AdditionalChartsSelectorForm: React.FC<
  AdditionalChartsSelectorFormProps
> = ({ station }: AdditionalChartsSelectorFormProps) => {
  const { setActiveModal, selectedDataSource: currentSelectedDataSource } =
    useModalContext();
  const onHide = () => setActiveModal(null);
  const [submittingForm, setSubmittingForm] = React.useState<boolean>(false);
  const [validated, setValidated] = React.useState<boolean>(false);
  const [selectedStation, setSelectedStation] =
    React.useState<GetMeasurementStations_station>();
  const [selectedDatasourceUUID, setSelectedDatasourceUUID] =
    React.useState<string>();
  const { data: stationListData } = useQuery<GetMeasurementStations>(
    GET_MEASUREMENT_STATIONS
  );
  const { data: stationData, refetch: refetchStationData } =
    useQuery<GetMeasurementStationQuery>(GET_MEASUREMENT_STATION_QUERY, {
      variables: {
        identifier: selectedStation?.identifier,
        visible: [true, false],
      },
    });

  const dataSources = stationData?.station[0].datasources.filter(
    (datasource) => {
      return !station.station_widgets.find(
        (existingWidget) =>
          existingWidget.base_source_uuid ===
            currentSelectedDataSource!.source_uuid &&
          existingWidget.base_source_uuid === datasource.source_uuid
      );
    }
  );

  const onSaveAdditionalSource = async () => {
    if (submittingForm) {
      return;
    }
    if (selectedDatasourceUUID === undefined) {
      toast.error("No datasource selected");
      return;
    }
    setSubmittingForm(true);
    const data = {
      stationId: station.id,
      base_source_uuid: currentSelectedDataSource?.source_uuid,
      additional_source_uuid: selectedDatasourceUUID,
    };

    const path = "/organization-admin/station-widget-add-source";
    try {
      await apiClient.request(path, {
        method: "POST",
        data,
      });
      toast.success(gettext(`Success`));
      setSubmittingForm(false);
      refetchStationData();
    } catch (e: any) {
      console.error(e);
      const message = e.toString().replace(/^Error: /, "");
      toast.error(gettext(`An error occured:`) + ` ${message}`);
      setSubmittingForm(false);
      return;
    }
  };

  const onRemoveAdditionalSource = async (sourceUUID: string) => {
    if (submittingForm) {
      return;
    }
    if (sourceUUID === undefined) {
      toast.error("No datasource selected");
      return;
    }
    setSubmittingForm(true);
    const data = {
      stationId: station.id,
      base_source_uuid: currentSelectedDataSource?.source_uuid,
      delete_uuid: sourceUUID,
    };

    const path = "/organization-admin/station-widget-delete-source";
    try {
      await apiClient.request(path, {
        method: "POST",
        data,
      });
      toast.success(gettext(`Success`));
      setSubmittingForm(false);
      refetchStationData();
    } catch (e: any) {
      console.error(e);
      const message = e.toString().replace(/^Error: /, "");
      toast.error(gettext(`An error occured:`) + ` ${message}`);
      setSubmittingForm(false);
      return;
    }
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    // Show any potential input errors
    setValidated(true);

    // If the form is valid, submit
    const form = event.currentTarget;

    if (form.checkValidity()) {
      await onSaveAdditionalSource();
      setSelectedStation(undefined);
      setSelectedDatasourceUUID(undefined);
      setValidated(false);
    }
  };

  const visibleDataSources = station.station_widgets
    .filter(
      (widget) =>
        widget.base_source_uuid === currentSelectedDataSource?.source_uuid
    )
    .map((widget) =>
      widget.station_widget_sources.map(
        (widgetSource) => widgetSource.source_uuid
      )
    )
    .flat();

  const currentStation = stationListData?.station.find(
    (listedStation: GetMeasurementStations_station) =>
      listedStation.id === station.id
  );

  const newSelectedDatasource = dataSources?.find(
    (s) => selectedDatasourceUUID === s.source_uuid
  );
  return (
    <Modal
      animation
      show
      onHide={() => {
        setValidated(false);
        onHide();
      }}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {currentSelectedDataSource!.name}: show additional charts
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {stationListData === undefined && (
          <div className="text-center">
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
        )}
        {stationListData !== undefined && (
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Table>
              <tbody>
                <tr>
                  <th>From station</th>
                  <td>
                    <Form.Select
                      required
                      aria-label="Select station"
                      onChange={(e) => {
                        const selectedId = e.target.value;
                        const station = stationListData.station.find(
                          (station) => station.id === parseInt(selectedId)
                        );
                        setSelectedStation(station);
                      }}
                      value={selectedStation?.id ?? ""}
                    >
                      <option value="">Select station</option>
                      {[
                        currentStation,
                        ...sortStationList(stationListData.station).filter(
                          (listedStation: GetMeasurementStations_station) =>
                            listedStation.id !== station.id &&
                            station.identifier
                        ),
                      ].map(
                        (
                          station: GetMeasurementStations_station | undefined
                        ) => (
                          <option
                            key={`station_${station!.id}`}
                            value={station!.id}
                          >
                            {station!.name}
                          </option>
                        )
                      )}
                    </Form.Select>
                  </td>
                </tr>
                {dataSources !== undefined && !dataSources.length && (
                  <tr>
                    <th>Data source</th>
                    <td>
                      <p className="text-muted">
                        No data sources available for this station
                      </p>
                    </td>
                  </tr>
                )}
                {dataSources !== undefined && !!dataSources.length && (
                  <tr>
                    <th>Data source</th>
                    <td>
                      <Form.Select
                        required
                        aria-label="Select data source"
                        onChange={(e) => {
                          const selectedSourceKey = e.target.value;
                          const source = dataSources.find(
                            (s) => selectedSourceKey === s.source_uuid
                          );
                          if (!source) {
                            return;
                          }
                          setSelectedDatasourceUUID(source.source_uuid);
                        }}
                        value={selectedDatasourceUUID ?? ""}
                      >
                        <option value="">Select data source</option>
                        {dataSources
                          .filter(
                            (
                              source: GetMeasurementStationQuery_station_datasources
                            ) =>
                              !station.station_widgets.find(
                                (widget) =>
                                  widget.base_source_uuid ===
                                    currentSelectedDataSource?.source_uuid &&
                                  widget.station_widget_sources.find(
                                    (widgetSource) =>
                                      widgetSource.source_uuid ===
                                      source.source_uuid
                                  )
                              )
                          )
                          .map(
                            (
                              source: GetMeasurementStationQuery_station_datasources
                            ) => (
                              <option
                                key={`source_${source.source_uuid}`}
                                value={source.source_uuid}
                              >
                                {source.name} {getSourceUnitSymbol(source)}
                              </option>
                            )
                          )}
                      </Form.Select>
                    </td>
                  </tr>
                )}
                {!!selectedDatasourceUUID && !!newSelectedDatasource && (
                  <tr>
                    <td>
                      {newSelectedDatasource.name}{" "}
                      {getSourceUnitSymbol(newSelectedDatasource)}
                    </td>
                    <td className="actionButtons">
                      <Button type="submit">Add</Button>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>

            {!!visibleDataSources.length && (
              <Table>
                <thead>
                  <tr>
                    <th colSpan={3}>Selected sources</th>
                  </tr>
                  <tr>
                    <th>Station</th>
                    <th>Data Source</th>
                    <th></th>
                  </tr>
                  {visibleDataSources.map((sourceUUID) => (
                    <ExistingDatasourceRow
                      key={`additional_source_${sourceUUID}`}
                      sourceUUID={sourceUUID}
                      onRemoveAdditionalSource={onRemoveAdditionalSource}
                    />
                  ))}
                </thead>
              </Table>
            )}
          </Form>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => {
            onHide();
          }}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AdditionalChartsSelectorForm;
