import * as React from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import {
  GetOrganization,
  GetOrganization_organization,
} from "../utils/__generated__/GetOrganization";
import FormControl from "react-bootstrap/FormControl";
import Table from "react-bootstrap/Table";
import { useQuery } from "@apollo/client";
import PageHeader from "theme/components/common/PageHeader";
import { Button, Card, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import apiClient from "../api";
import { GET_ORGANIZATION } from "../utils/users";
import { getFormValueHandler } from "../utils/forms";
import { gettext } from "../i18n";

import "./OrganizationEditPage.scss";

interface OrganizationEditPageProps {
  permissions?: string[];
}
const OrganizationEditPage: React.FC<OrganizationEditPageProps> = ({
  permissions = [],
}: OrganizationEditPageProps) => {
  const { identifier } = useParams();
  const navigate = useNavigate();
  const organizationId = parseInt(identifier || "0", 10);

  const [validated, setValidated] = React.useState<boolean>(false);
  const [editedValues, setEditedValues] = React.useState<Record<string, any>>(
    {}
  );

  const { data: organizationData, refetch: refetchOrganizationData } =
    useQuery<GetOrganization>(GET_ORGANIZATION, {
      variables: { organizationId },
    });

  const setValueHandler = getFormValueHandler(editedValues, setEditedValues);

  const onSave = async (e: any) => {
    e.preventDefault();

    const values = { ...editedValues };
    if (!Object.keys(values).length) {
      toast.success(gettext("No changes made"));
    }
    apiClient
      .request("/auth/update-organization-record", {
        method: "POST",
        data: {
          id: organizationId,
          ...values,
        },
      })
      .then(async () => {
        toast.success("Organization updated successfully");
        await refetchOrganizationData();
        setValidated(false);

        setEditedValues({});
        onRedirectBack();
      })
      .catch((e) => {
        console.error(e);
        toast.error("Failed to update organization! " + e.toString());
      });
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    // Show any potential input errors
    setValidated(true);
    const form = event.currentTarget;

    if (form.checkValidity()) {
      onSave(event);
    }
  };
  const onRedirectBack = () => navigate(`/organization-management`);

  const organizationRecord: GetOrganization_organization | null =
    organizationData?.organization[0] || null;
  return (
    <div className="OrganizationEditPage">
      <PageHeader title="Editing Organization" className="mb-3"></PageHeader>
      <Card>
        <Card.Body>
          {!organizationRecord && <div>No organization record available</div>}
          {!!organizationRecord && (
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <Table>
                <tbody>
                  <tr>
                    <th>Name</th>
                    <td>
                      <FormControl
                        type="name"
                        value={
                          editedValues?.name ?? organizationRecord?.name ?? ""
                        }
                        placeholder={gettext("Organization name")}
                        onChange={setValueHandler("name")}
                        required
                        disabled={!permissions.includes("edit_organization")}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>Slug</th>
                    <td>
                      <FormControl
                        value={
                          editedValues?.slug ?? organizationRecord?.slug ?? ""
                        }
                        placeholder={gettext("organization_slug")}
                        onChange={setValueHandler("slug")}
                        required
                        disabled={!permissions.includes("edit_organization")}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2}>
                      <div className="actionButtons">
                        <Button variant="light" onClick={onRedirectBack}>
                          Back
                        </Button>
                        {permissions.includes("edit_organization") && (
                          <Button type="submit">Save</Button>
                        )}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Form>
          )}
        </Card.Body>
      </Card>
    </div>
  );
};

export default OrganizationEditPage;
