import React, { useContext, useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import classNames from "classnames";
import NavbarTop from "../components/navbar/top/NavbarTop";
import NavbarVertical from "../components/navbar/vertical/NavbarVertical";
import PermissionContextCheck from "../components/auth/PermissionContextCheck";
import AppContext from "theme/context/Context";
import ProductProvider from "theme/components/app/e-commerce/ProductProvider";
import ErrorBoundary from "../components/errors/ErrorBoundary";

const MainLayout = () => {
  const { hash } = useLocation();

  const {
    config: { isFluid, navbarPosition },
  } = useContext(AppContext);

  useEffect(() => {
    setTimeout(() => {
      if (hash) {
        const id = hash.replace("#", "");
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ block: "start", behavior: "smooth" });
        }
      }
    }, 0);
  }, [hash]);

  return (
    <div className={isFluid ? "container-fluid" : "container"}>
      {(navbarPosition === "vertical" || navbarPosition === "combo") && (
        <PermissionContextCheck category="navbar">
          <NavbarVertical />
        </PermissionContextCheck>
      )}
      <ProductProvider>
        <div className={classNames("content")}>
          <NavbarTop />
          {/*------ Main Routes ------*/}
          <ErrorBoundary>
            <Outlet />
          </ErrorBoundary>
        </div>
      </ProductProvider>
    </div>
  );
};

export default MainLayout;
