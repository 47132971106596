import React from "react";
import { BrowserRouter } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

import Routes from './routes';
import FalconThemeMain from './theme/Main';
import { CloseButton } from './theme/components/common/Toast';
import {AppApolloProvider} from './apollo';
import AppStateProvider from './state/main';
import RememberLogin from './components/auth/RememberLogin';
import {ReactComponentRefresher} from './utils/refresh';

function App() {
  return (
    <div className="App">
      <FalconThemeMain>
        <AppStateProvider>
          <AppApolloProvider>
            <RememberLogin>
              <BrowserRouter>
                <ReactComponentRefresher>
                  <Routes />
                </ReactComponentRefresher>
              </BrowserRouter>
            </RememberLogin>
            <ToastContainer
              closeButton={CloseButton}
              icon={false}
              position={toast.POSITION.BOTTOM_LEFT}
              theme="colored"
            />
          </AppApolloProvider>
        </AppStateProvider>
      </FalconThemeMain>
    </div>
  );
}

export default App;
