import PageHeader from "../theme/components/common/PageHeader";
import * as React from "react";
import Table from "react-bootstrap/Table";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { toast } from "react-toastify";
import apiClient from "../api";
import { gettext } from "../i18n";
import DefaultTooltip from "components/common/DefaultTooltip";
import { parseNumberInput } from "utils/formatting";
import { UploadedDatapoint, onInputFileChange } from "utils/xlsx";
import "./MeasurementStationDataUploadPage.scss";

const MeasurementStationDataUploadPage = () => {
  const [savingUploadedValues, setSavingUploadedValues] =
    React.useState<boolean>(false);
  const [uploadedValues, setUploadedValues] = React.useState<
    UploadedDatapoint[]
  >([]);

  const uploadDatapoints = () => {
    if (!uploadedValues.length || savingUploadedValues) {
      return;
    }

    setSavingUploadedValues(true);
    const values = [...uploadedValues];
    const requestPath = "/admin/upload-channel-datapoints";
    apiClient
      .request(requestPath, {
        method: "POST",
        data: {
          datapoints: values,
        },
      })
      .then(() => {
        toast.success(gettext("Success"));
        setUploadedValues([]);
        setSavingUploadedValues(false);
      })
      .catch((e) => {
        toast.error(e.message.toString());
        setSavingUploadedValues(false);
      });
  };

  const onFileLoaded = (data: UploadedDatapoint[]) => {
    if (!data) {
      return;
    }
    const validData = data
      .filter(
        (row: string[]) => row.length === 5 && !isNaN(parseNumberInput(row[3]))
      )
      .map((row: string[]) => [
        row[0],
        row[1],
        row[2],
        parseNumberInput(row[3]),
        row[4],
      ]);

    setUploadedValues([...validData] as UploadedDatapoint[]);
  };

  return (
    <div className="MeasurementStationDataUploadPage">
      <PageHeader
        title={gettext("Import channel data")}
        className="mb-3"
      ></PageHeader>
      <Card className="mb-3">
        <Card.Body>
          {!savingUploadedValues && (
            <>
              <Table responsive>
                <thead>
                  <tr>
                    <th colSpan={4}>
                      <div className="uploadHeader">
                        Upload datapoints from CSV or XLSX file
                        <div className="templateButton">
                          <a href="/xlsx/example_file_for_channel_import.xlsx">
                            <Button>Download Template</Button>
                          </a>
                          <DefaultTooltip
                            popHeader={gettext("Template File")}
                            popBody={
                              <p>
                                You can use the attached Excel file as a
                                template for importing data into existing
                                channels or to create new channels. Fill in the
                                correct dates, times and values.
                              </p>
                            }
                          />
                        </div>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <input
                        type="file"
                        name="file-upload-input"
                        accept=".csv, .xlsx"
                        onChange={onInputFileChange(onFileLoaded)}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2}>
                      <Button
                        type="button"
                        variant="primary"
                        disabled={savingUploadedValues}
                        onClick={(e) => {
                          e.preventDefault();
                          uploadDatapoints();
                        }}
                      >
                        Upload
                      </Button>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </>
          )}
        </Card.Body>
      </Card>
    </div>
  );
};

export default MeasurementStationDataUploadPage;
