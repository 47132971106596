import { gql, useQuery } from "@apollo/client";
import { useNavigate, useParams } from "react-router-dom";
import { useSortedUnits } from "../hooks/units";
import PageHeader from "../theme/components/common/PageHeader";
import * as React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import apiClient from "../api";
import { GetUnits_unit } from "../utils/__generated__/GetUnits";
import { gettext } from "../i18n";
import {
  GetMeasurementStationFormulas,
  GetMeasurementStationFormulas_station_formulas as Formula,
  GetMeasurementStationFormulas_station_channels as Channel,
  GetMeasurementStationFormulasVariables,
} from "./__generated__/GetMeasurementStationFormulas";
import BackToLink from "../components/common/BackToLink";
import { MATH_FUNCTIONS } from "./mathFunctions";
import "./MeasurementStationFormulasPage.scss";

const FunctionHelp = () => {
  const [visibleHelp, setVisibleHelp] = React.useState<string | null>(null);
  return (
    <div>
      <Form.Text>
        Available functions:{" "}
        {MATH_FUNCTIONS.map(([name, help]) => {
          return (
            <React.Fragment key={name}>
              <strong
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setVisibleHelp(visibleHelp === help ? null : help);
                }}
              >
                {name}
              </strong>{" "}
            </React.Fragment>
          );
        })}
        {visibleHelp && <p dangerouslySetInnerHTML={{ __html: visibleHelp }} />}
      </Form.Text>
    </div>
  );
};

const GET_FORMULAS = gql`
  query GetMeasurementStationFormulas($identifier: String) {
    station(where: { identifier: { _eq: $identifier } }) {
      id
      identifier
      name
      formulas {
        id
        name
        system_name
        expression
        unit {
          id
          name
          symbol
        }
        data_sources {
          id
          variable
          formula_id
          channel_id
          formula {
            id
            name
          }
          channel {
            id
            identifier
            name
          }
        }
      }
      channels {
        id
        identifier
        name
      }
    }
  }
`;

const MeasurementStationFormulasPage = () => {
  const { identifier, formulaId } = useParams();

  const navigate = useNavigate();
  const { data, error, refetch } = useQuery<
    GetMeasurementStationFormulas,
    GetMeasurementStationFormulasVariables
  >(GET_FORMULAS, {
    variables: {
      identifier,
    },
  });
  if (!data) {
    return <div />;
  }
  if (error) {
    return <pre>{JSON.stringify(error, null, 2)}</pre>;
  }

  const station = data?.station[0];
  if (!station) {
    return <div>Not found.</div>;
  }

  const createFormula = formulaId === "new";
  const editFormula = !createFormula && !!formulaId;
  const formulaToEdit = editFormula
    ? station.formulas.find((f) => f.id.toString() === formulaId)
    : undefined;
  if (editFormula && !formulaToEdit) {
    return <div>Formula not found.</div>;
  }

  const stationFormulasPath = `/stations/${identifier}/formulas`;

  return (
    <div className="MeasurementStationFormulasPage">
      <BackToLink to={`/stations/${identifier}`}>
        Back to measurement station.
      </BackToLink>
      <PageHeader title={gettext("Formulas")} className="mb-3"></PageHeader>
      <Card className="mb-3">
        <Card.Body>
          {formulaToEdit || createFormula ? (
            <div>
              <BackToLink to={stationFormulasPath}>
                Back to formula table
              </BackToLink>
              {formulaToEdit ? (
                <h4>Edit formula: {formulaToEdit.name}</h4>
              ) : (
                <h4>Create a formula</h4>
              )}
              <FormulaForm
                station={station}
                formula={formulaToEdit}
                availableFormulas={station.formulas}
                availableChannels={station.channels}
                onSuccess={() => {
                  refetch()
                    .then(() => {
                      navigate(stationFormulasPath);
                    })
                    .catch(console.error);
                }}
              />
            </div>
          ) : (
            <FormulaTable
              stationIdentifier={station.identifier}
              formulas={station.formulas}
              onSuccess={() => {
                refetch().catch(console.error);
              }}
            />
          )}
        </Card.Body>
      </Card>
    </div>
  );
};

interface FormulaTableProps {
  stationIdentifier: string;
  formulas: Formula[];
  onSuccess?: () => void;
}
const FormulaTable = ({
  stationIdentifier,
  formulas,
  onSuccess,
}: FormulaTableProps) => {
  const deleteHandler = (formulaId: number) => (e: any) => {
    e.preventDefault();
    // eslint-disable-next-line no-restricted-globals
    const answer = confirm(
      gettext("Really delete the formula? This cannot be undone.")
    );
    if (answer) {
      apiClient
        .request("/admin/delete-formula", {
          method: "POST",
          data: {
            formulaId,
          },
        })
        .then(() => {
          toast.success("Formula deleted successfully");
          if (onSuccess) {
            onSuccess();
          }
        })
        .catch((e) => {
          toast.error("Error deleting formula: " + e.toString());
        });
    }
  };
  return (
    <div>
      <Table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Unit</th>
            <th>Expression</th>
            <th>Variables</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {formulas.map((formula) => {
            let formulaUnit = formula.unit?.symbol;
            if (formulaUnit === undefined) {
              formulaUnit = gettext("(unassigned)");
            } else if (formulaUnit === "") {
              formulaUnit = gettext("(no unit)");
            }
            return (
              <tr key={formula.id}>
                <td>{formula.name}</td>
                <td>{formulaUnit}</td>
                <td>
                  <code>{formula.expression}</code>
                </td>
                <td>
                  {formula.data_sources.map((source) => (
                    <div key={source.variable}>
                      {source.channel ? (
                        <code>
                          {source.variable} = Channel {source.channel.name} (
                          {source.channel.identifier})
                        </code>
                      ) : source.formula ? (
                        <code>
                          {source.variable} = Formula {source.formula.name}
                        </code>
                      ) : (
                        <code>{source.variable} = ???</code>
                      )}
                    </div>
                  ))}
                </td>
                <td>
                  <Button
                    as={Link as any}
                    to={`/stations/${stationIdentifier}/formulas/${formula.id}`}
                    variant="outline-primary"
                    size="sm"
                    className="me-2"
                  >
                    Edit
                  </Button>
                  <Button
                    type="button"
                    variant="outline-danger"
                    size="sm"
                    //className="me-2"
                    onClick={deleteHandler(formula.id)}
                  >
                    Delete
                  </Button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <div className="my-2">
        <Button
          as={Link as any}
          to={`/stations/${stationIdentifier}/formulas/new`}
          variant="outline-primary"
          size="sm"
        >
          New formula
        </Button>
      </div>
    </div>
  );
};

interface FormulaFormProps {
  station: {
    id: number;
  };
  formula: Formula | undefined;
  availableFormulas: Formula[];
  availableChannels: Channel[];
  onSuccess?: () => void;
}

const FormulaForm = ({
  station,
  formula,
  availableFormulas,
  availableChannels,
  onSuccess,
}: FormulaFormProps) => {
  const { data: sortedUnits, refetch: refetchUnits } = useSortedUnits();
  const [formState, setFormState] = React.useState({
    name: formula?.name ?? "",
    systemName: formula?.system_name ?? "",
    sourceIdentifier: "",
    unitId: formula?.unit?.id,
    newUnit: "",
    expression: formula?.expression ?? "",
  });
  const [sourceState, setSourceState] = React.useState(
    (formula?.data_sources ?? []).map((source) => ({
      variable: source.variable,
      sourceId: source.id,
      type: source.formula_id ? "formula" : "channel",
      channelOrFormulaId: source.channel_id || source.formula_id,
    }))
  );
  const [addingUnit, setAddingUnit] = React.useState<boolean>(false);

  availableFormulas = availableFormulas.filter((f) => f.id !== formula?.id);
  const usedSourceKeys = sourceState.map(
    (s) => `${s.type}-${s.channelOrFormulaId}`
  );
  const creating = !formula;
  let submitDisabled = !formState.name || !formState.expression;
  if (!submitDisabled) {
    for (let source of sourceState) {
      if (
        !source.variable ||
        ["channel", "formula"].indexOf(source.type) === -1 ||
        !source.channelOrFormulaId
      ) {
        submitDisabled = true;
        break;
      }
    }
  }

  const submitForm = () => {
    if (submitDisabled) {
      return;
    }
    const data = {
      stationId: station.id,
      formulaId: formula?.id,
      ...formState,
      sources: [...sourceState],
    };

    const path = creating ? "/admin/create-formula" : "/admin/edit-formula";
    apiClient
      .request(path, {
        method: "POST",
        data,
      })
      .then(() => {
        toast.success(
          gettext(
            creating
              ? "Formula created successfully"
              : "Formula edited successfully"
          )
        );
        if (onSuccess) {
          onSuccess();
        }
        refetchUnits();
      })
      .catch((e) => {
        console.error(e);
        const message = e.toString().replace(/^Error: /, "");
        toast.error(
          `Error ${creating ? "creating" : "editing"} formula: ${message}`
        );
      });
  };
  return (
    <Form>
      <Form.Group className="mb-2">
        <Form.Label>Name</Form.Label>
        <Form.Control
          type="text"
          value={formState.name}
          placeholder={gettext("Enter a name for the formula")}
          onChange={(e) => {
            setFormState({
              ...formState,
              name: e.target.value,
            });
          }}
        />
      </Form.Group>
      <Form.Group className="mb-2">
        <Form.Label>System Name</Form.Label>
        <Form.Control
          type="text"
          value={formState.systemName}
          placeholder={gettext("Enter a machine-readable name for the formula")}
          onChange={(e) => {
            setFormState({
              ...formState,
              systemName: e.target.value,
            });
          }}
        />
      </Form.Group>
      {!addingUnit && (
        <Form.Group className="mb-2">
          <Form.Label>Unit</Form.Label>
          <div className="unit-select-row">
            <Form.Select
              required
              aria-label="Select a unit for the formula"
              onChange={(e) => {
                const unitId: number | undefined = e.target.value
                  ? Number(e.target.value)
                  : undefined;
                setFormState({
                  ...formState,
                  unitId,
                });
              }}
              value={formState.unitId}
            >
              <option value="">(unassigned)</option>
              {sortedUnits.map((unit: GetUnits_unit) => (
                <option key={`unit_${unit.id}`} value={unit.id}>
                  {unit.symbol || gettext("(no unit)")}
                </option>
              ))}
            </Form.Select>
            <div className="unit-action" onClick={() => setAddingUnit(true)}>
              <FontAwesomeIcon icon="plus" />
            </div>
          </div>
        </Form.Group>
      )}
      {addingUnit && (
        <Form.Group className="mb-2">
          <Form.Label>Add unit</Form.Label>
          <div className="unit-select-row">
            <Form.Control
              type="text"
              value={formState.newUnit}
              placeholder="New unit name"
              onChange={(e) => {
                setFormState({
                  ...formState,
                  newUnit: e.target.value,
                });
              }}
            />
            <div
              className="unit-action"
              onClick={() => {
                setAddingUnit(false);
                setFormState({
                  ...formState,
                  newUnit: "",
                });
              }}
            >
              <FontAwesomeIcon icon="list" />
            </div>
          </div>
        </Form.Group>
      )}
      <>
        <Form.Group>
          <Form.Label>Expression</Form.Label>
          <Form.Control
            type="text"
            value={formState.expression}
            placeholder="Enter a mathematical expression, e.g. log(x / y) + 1.0"
            onChange={(e) => {
              setFormState({
                ...formState,
                expression: e.target.value,
              });
            }}
          />
          <Form.Text>Select variables for the expression below.</Form.Text>
          <FunctionHelp />
        </Form.Group>
        <h5 className="mt-4">Variables</h5>
        {sourceState.map((source, sourceIndex) => {
          const sourceKey = `${source.type}-${source.channelOrFormulaId}`;
          return (
            <Row key={sourceIndex} className="mb-2">
              <Col>
                <Form.Group>
                  <Form.Label>Variable name</Form.Label>
                  <Form.Control
                    type="text"
                    value={source.variable}
                    placeholder="Enter variable name, e.g. x"
                    onChange={(e) => {
                      const newSourceState = [...sourceState];
                      newSourceState[sourceIndex] = {
                        ...source,
                        variable: e.target.value,
                      };
                      setSourceState(newSourceState);
                    }}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Source</Form.Label>
                  <Form.Select
                    value={sourceKey}
                    onChange={(e) => {
                      const [type, id] = e.target.value.split("-");
                      const newSourceState = [...sourceState];
                      newSourceState[sourceIndex] = {
                        ...source,
                        type,
                        channelOrFormulaId: parseInt(id),
                      };
                      setSourceState(newSourceState);
                    }}
                  >
                    <option disabled value="unset-0">
                      Select channel or formula
                    </option>
                    {availableChannels.length > 0 && (
                      <optgroup label={gettext("Channels")}>
                        {availableChannels.map((item) => {
                          const optionKey = `channel-${item.id}`;
                          return (
                            <option
                              key={optionKey}
                              value={optionKey}
                              disabled={
                                optionKey !== sourceKey &&
                                usedSourceKeys.indexOf(optionKey) !== -1
                              }
                            >
                              {item.name}
                            </option>
                          );
                        })}
                      </optgroup>
                    )}
                    {availableFormulas.length > 0 && (
                      <optgroup label={gettext("Formulas")}>
                        {availableFormulas.map((item) => {
                          const optionKey = `formula-${item.id}`;
                          return (
                            <option
                              key={optionKey}
                              value={optionKey}
                              disabled={
                                optionKey !== sourceKey &&
                                usedSourceKeys.indexOf(optionKey) !== -1
                              }
                            >
                              {item.name}
                            </option>
                          );
                        })}
                      </optgroup>
                    )}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col>
                <Form.Label>Actions</Form.Label>
                <div>
                  <Button
                    type="button"
                    variant="danger"
                    size="sm"
                    onClick={(e) => {
                      e.preventDefault();
                      const newSourceState = [...sourceState];
                      newSourceState.splice(sourceIndex, 1);
                      setSourceState(newSourceState);
                    }}
                  >
                    Remove
                  </Button>
                </div>
              </Col>
            </Row>
          );
        })}
        <div>
          <Button
            type="button"
            variant="outline-primary"
            size="sm"
            onClick={(e) => {
              e.preventDefault();
              const newSourceState = [
                ...sourceState,
                {
                  variable: "",
                  sourceId: 0,
                  type: "unset",
                  channelOrFormulaId: 0,
                },
              ];
              setSourceState(newSourceState);
            }}
          >
            Add a new variable
          </Button>
        </div>
      </>
      <div className="pt-4">
        <Button
          type="button"
          variant="primary"
          disabled={submitDisabled}
          onClick={(e) => {
            e.preventDefault();
            submitForm();
          }}
        >
          {creating ? gettext("Create a new formula") : gettext("Save changes")}
        </Button>
      </div>
    </Form>
  );
};

export default MeasurementStationFormulasPage;
